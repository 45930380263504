// @flow
import React, { useState } from 'react';

import 'rc-time-picker/assets/index.css';
import { HABIT_CATEGORIES } from '../../../Constants';

import plusIconMain from '../../../assets/svgs/plus-blue.svg';

import type { ComponentType } from 'react';
import type { Habit } from '../../../flowTypes';
import _ from 'lodash';


type HabitFormProps = {
    saveHabit: (habit: Habit, oldHabitName?: string) => void;
    onDone: () => void;
    habit: Habit;
    level: number
};

export const HabitForm: ComponentType<HabitFormProps> = ({ onDone = _.noop, saveHabit = _.noop, habit, level = 0 }) => {
    const [displayName, setDisplayName] = useState(habit?.displayName || "");
    const [context, setContext] = useState(habit?.context || "");
    const [type, setType] = useState(habit?.type || "");
    const [description, setDescription] = useState(habit?.description || "");
    const [activeDays, setActiveDays] = useState(habit?.daysToExcludeInPrompting || []);
    const [reminder, setReminder] = useState(habit?.reminder);

    const oldHabitName = habit?.name;

    const onSaveHabit = (): any => {
        const days = [1, 2, 3, 4, 5, 6, 7]; // Sun -> Sat
        const daysToExcludeInPrompting = activeDays.length ? days.filter((day) => !activeDays.includes(day)) : []
        let habit: Habit = {
            displayName: displayName,
            name: `action ${displayName} ${level}`.toLocaleLowerCase().split(" ").join('-'),
            type,
            description,
            context,
            daysToExcludeInPrompting,
        };

        habit = { ...habit, ...(_.isEmpty(reminder) ? {} : { reminder }) };

        saveHabit(habit, oldHabitName);
    }

    const setReminderTime = (value: any): any => {
        const [hour, minute] = value ? value.split(":") : [0, 0]
        setReminder(value ? { hour, minute } : {});
    }

    const setDaysActive = (day: null | number): any => {
        if (day === null) {
            setActiveDays([]);
            return
        }
        const activeDaysCopy = [...activeDays];
        const dayIndex = activeDaysCopy.indexOf(day);
        if (dayIndex > -1) {
            activeDaysCopy.splice(dayIndex, 1);
            setActiveDays(activeDaysCopy);

        }
        else {
            activeDaysCopy.push(day);
            setActiveDays(activeDaysCopy.length === 7 ? [] : activeDaysCopy);
        }
    }

    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={plusIconMain} className="modal-icon" alt="modal icon" />
                </div>
                <p testid="modal-title" className="modal-title">
                    {'Add Action'}
                </p>
            </div>
            <div className="">
                <>
                    <p className="modal-description">
                        Actions are the daily tasks, habits, and routines members apply to improve qualities.
                    </p>
                    <div className="modal-bd modal-bd-short">
                        <div className="d-flex flex-column justify-content-center">

                            <div className="form-group">
                                <label>What action to do*</label>
                                <input
                                    placeholder="run, meditate, drink water, etc."
                                    testid="name-input"
                                    className="form-control"
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    style={{ color: displayName ? 'black' : '#94A3B8' }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Context (optional)</label>
                                <input
                                    placeholder="1 mile, 10 minutes, 2 liters, etc."
                                    testid="context-input"
                                    className="form-control"
                                    value={context}
                                    onChange={(e) => setContext(e.target.value)}
                                    style={{ color: context ? 'black' : '#94A3B8' }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Category*</label>
                                <select
                                    placeholder="Select category"
                                    testid="category-input"
                                    className="form-control"
                                    value={_.lowerCase(type)}
                                    onChange={(e) => setType(e.target.value)}
                                    style={{ color: type ? 'black' : '#94A3B8' }}
                                >
                                    <option value="">Select Category</option>
                                    {HABIT_CATEGORIES.map((category, i) => (
                                        <option value={category} key={`category-key-${i}`}>
                                            {_.capitalize(category)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Description (optional)</label>
                                <textarea
                                    rows={2}
                                    placeholder=""
                                    testid="description-input"
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={{ color: description ? 'black' : '#94A3B8' }}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>Days active</label>
                                <div className="form-control d-flex p-0">
                                    <div
                                        className={`form-control option ${!activeDays || !activeDays.length ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(null)}
                                        testid="all-day"
                                        style={{ paddingLeft: 0, paddingRight: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
                                    >
                                        All
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(1) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(1)}
                                        testid="sunday"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        Sun
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(2) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(2)}
                                        testid="monday"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        Mon
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(3) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(3)}
                                        testid="tuesday"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        Tue
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(4) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(4)}
                                        testid="wednesday"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        Wed
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(5) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(5)}
                                        testid="thursday"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        Thur
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(6) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(6)}
                                        testid="friday"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        Fri
                                    </div>
                                    <div
                                        className={`form-control option ${activeDays.length !== 7 && activeDays.includes(7) ? 'active' : ''
                                            }`}
                                        onClick={() => setDaysActive(7)}
                                        testid="saturday"
                                        style={{ paddingLeft: 0, paddingRight: 0, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
                                    >
                                        Sat
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="timepicker">Set Reminder</label>
                                <input testid="reminder-input" type="time" style={{ color: !_.isEmpty(reminder) ? 'black' : '#94A3B8' }} defaultValue={'12:00'} onChange={(e) => setReminderTime(e.target.value)} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center mt-2">
                        <button
                            testid="submit-button"
                            className="btn btn-block btn-lg app-btn-secondary mt-3"
                            onClick={onSaveHabit}
                            disabled={!!!displayName || !!!type}
                        >
                            Save
                        </button>
                        <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                            Cancel
                        </button>
                    </div>
                </>
            </div>
        </div>
    );
};
