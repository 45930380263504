// @flow

import 'bootstrap/dist/css/bootstrap.css';
import '../../../custom.scss';
import { connect } from 'react-redux';
import { login, setNetworkDeactivated, setProtocols } from '../../../actions';
import { updatePassword } from '../../../api/firebase';
import { GENERIC_ERROR } from '../../../Constants';
import type { ComponentType } from 'react';
import lockIcon from '../../../assets/svgs/lock-blue.svg';



import React from 'react';

import { getLoadingIndicator } from '../../UI Resources';

import type { AuthObject, LoginInfo } from '../../../flowTypes';

type Props = {
    auth: AuthObject,
    login: (payload: LoginInfo) => void,
    onDone: () => void;
};

type State = {
    replacingPassword: boolean,
    newPassword: string,
    confirmNewPassword: string,
    replacePasswordError: string,
    passwordSuccessfullyReset: boolean,
};

export class ReplacePassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            activating: false,
            activationError: '',
            replacePasswordError: '',
            cardInformationIsComplete: false,
            newPassword: '',
            confirmNewPassword: '',
            onCardActivationPage: true,
            replacingPassword: false,
            passwordSuccessfullyReset: false,
        };
    }

    _onNewPasswordChanged: (e: { target: { value: any } }) => void = (e) => {
        this.setState({ newPassword: e.target.value });
    }

    _onConfirmNewPasswordChanged: (e: { target: { value: any } }) => void = (e) => {
        this.setState({ confirmNewPassword: e.target.value });
    }

    _replacePassword: () => Promise<void> = async () => {

        const { auth, login } = this.props;
        const {
            networkKey,
            username,
            name,
            deactivated,
            isModerator,
            uid
        } = auth || {};

        if (!networkKey) {
            this.setState({ replacingPassword: false });
            return; // something is wrong, no network key
        }

        const {
            replacingPassword,
            newPassword,
        } = this.state;

        if (replacingPassword) return;

        this.setState({ replacingPassword: true });

        try {
            await updatePassword(newPassword);
        } catch (e) {
            this.setState({ replacePasswordError: GENERIC_ERROR, replacingPassword: false });
            return;
        }

        this.setState({ replacingPassword: false, newPassword: '', confirmNewPassword: '', passwordSuccessfullyReset: true });
        login({ networkKey, name, username, deactivated, uid, password: newPassword, isModerator });
    }



    render(): any {

        const {
            replacePasswordError,
            newPassword,
            confirmNewPassword,
            replacingPassword,
            passwordSuccessfullyReset
        } = this.state;

        const { onDone } = this.props;

        const ready = confirmNewPassword && newPassword && (confirmNewPassword === newPassword);
        return (
            <div className="modal-container modal-md">
                <div className="modal-head">
                    <div className="modal-icon-container">
                        <img src={lockIcon} className="modal-icon" alt="modal icon" />
                    </div>
                    {passwordSuccessfullyReset ? (
                        <p testid="modal-title" className="modal-title">
                            {'Password Changed'}
                        </p>
                    ) : (
                        <p testid="modal-title" className="modal-title">
                            Change Password
                        </p>
                    )}
                </div>
                {passwordSuccessfullyReset ? (
                    <div className="modal-bd d-flex flex-column justify-content-center">
                        <button testid="done-remove" className="btn btn-block btn-lg app-btn-success mt-3" onClick={onDone}>
                            Okay
                        </button>
                    </div>
                ) : (
                    <>
                        <p className="modal-description">{'You are changing admin password for this network'}</p>
                        <div>
                            {replacePasswordError ? (
                                <p testid="notification-error" className="form-error text-center">{replacePasswordError}</p>
                            ) : null}
                            <div className="form-group">
                                <label>New Password</label>
                                <input
                                    type="password"
                                    testid="new-password"
                                    className="form-control"
                                    value={newPassword}
                                    onChange={(e) => this._onNewPasswordChanged(e)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Confirm New Password</label>
                                <input
                                    type="password"
                                    testid="confirm-new-password"
                                    className="form-control"
                                    value={confirmNewPassword}
                                    onChange={(e) => this._onConfirmNewPasswordChanged(e)}
                                />
                            </div>
                        </div>
                        <div className="modal-bd d-flex flex-column justify-content-center">
                            <button
                                disabled={!ready}
                                className={`btn btn-block btn-lg app-btn-success mt-3`}
                                testid="new-password-button"
                                onClick={this._replacePassword}
                            >
                                {replacingPassword ? (
                                    getLoadingIndicator()
                                ) : (
                                    <>
                                        {'Confirm'}
                                    </>
                                )}

                            </button>
                            <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                Cancel
                            </button>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        auth
    } = state;

    return {
        auth
    };
}


const connectedComponent: ComponentType<{}> = connect(mapStateToProps, { login, setNetworkDeactivated, setProtocols })(ReplacePassword)
export default connectedComponent;