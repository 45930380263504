// @flow
import 'bootstrap/dist/css/bootstrap.css';
import '../../../general.scss';
import 'react-circular-progressbar/dist/styles.css';
import React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { fetchMetricLogsForYear, fetchHabitLogsForYear, fetchUsers } from '../../../actions';
import {
    getLoadingIndicator,
    getShortCalendarFormatForDate,
    periodToPriorAndCurentTimestamp,
    getTimeAdjustedTrend,
    matchFilter,
} from '../../UI Resources';
import _ from 'lodash';
import exportIcon from '../../../assets/svgs/export.svg';

import searchIcon from '../../../assets/svgs/search.svg';
import caretDownIcon from '../../../assets/svgs/caret-down.svg';
import caretUpIcon from '../../../assets/svgs/caret-up.svg';
import checkboxCheck from '../../../assets/svgs/checkbox-check.svg';
import checkbox from '../../../assets/svgs/checkbox.svg';
import arrowUp from '../../../assets/svgs/up-arrow.svg';
import arrowDown from '../../../assets/svgs/down-arrow.svg';
import { VictoryChart, VictoryBar, VictoryLabel, VictoryLine, VictoryAxis, VictoryLegend } from 'victory';
import {
    CircularProgressbarWithChildren as CircularProgressbar,
    CircularProgressbar as CircleProgress,
} from 'react-circular-progressbar';

import type { AuthObject, MetricScoreInCollection, HabitScoreInCollection, CurrentUser } from '../../../flowTypes';
import type { ComponentType } from 'react';
import { Dropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const font = 'Inter';
type Props = {
    location: any,
    auth: AuthObject,
    metricLogs: Array<MetricScoreInCollection>,
    habitLogs: Array<HabitScoreInCollection>,
    path: any,
    fetchMetricLogsForYear: (networkKey: string) => void,
    fetchHabitLogsForYear: (networkKey: string) => void,
    fetchUsers: () => void,
    chosenProtocolsMapping: { [key: string]: string },
    showNotificationModal: boolean,
    currentUsers: any,
};
type sortOrder = '' | 'asc' | 'desc';
type ChartType = 'scores' | 'trends';
type Period = { number: number, period: string };
type State = {
    chartType: ChartType,
    priorSortOrder: sortOrder,
    currentSortOrder: sortOrder,
    changeSortOrder: sortOrder,
    logSortOrder: sortOrder,
    selectedUserId: string,
    adherenceSortOrder: sortOrder,
    currentPeriod: Period,
    dispositionFilter: string,
    memberFilter: string[],
    habitFilter: string[],
    selectedUser: CurrentUser,
    adherenceFilter: string,
    showNotificationModal: boolean,
    showBroadcastModal: boolean,
    searchString: string,
    memberSearch: string,
};

export class Adherence extends React.Component<Props, State> {
    dateFilter: Period[] = [
        { number: 1, period: 'y' },
        { number: 6, period: 'M' },
        { number: 3, period: 'M' },
        { number: 1, period: 'M' },
        { number: 2, period: 'w' },
        { number: 1, period: 'w' },
    ];
    constructor(props: Props) {
        super(props);
        this.state = {
            priorSortOrder: '',
            currentSortOrder: '',
            changeSortOrder: '',
            logSortOrder: '',
            memberFilter: [],
            habitFilter: [],
            adherenceSortOrder: '',
            currentPeriod: { number: 2, period: 'w' },
            selectedUser: {},
            selectedUserId: '',
            dispositionFilter: '',
            adherenceFilter: '',
            showNotificationModal: false,
            memberSearch: '',
            showBroadcastModal: false,
            chartType: 'scores',
            searchString: '',
        };
    }

    componentDidMount() {
        const {
            metricLogs,
            habitLogs,
            auth = {},
            fetchMetricLogsForYear,
            fetchHabitLogsForYear,
            currentUsers = {},
            fetchUsers,
        } = this.props;
        const { networkKey } = auth;
        if (!metricLogs?.length) {
            fetchMetricLogsForYear(networkKey);
        }
        if (!habitLogs?.length) {
            fetchHabitLogsForYear(networkKey);
        }
        if (!Object.keys(currentUsers).length) {
            fetchUsers();
        }
    }

    _toggleMemberFilter(userid: string): any {
        const { memberFilter } = this.state;
        const index = memberFilter.indexOf(userid);
        index > -1 ? memberFilter.splice(index, 1) : memberFilter.push(userid);
        this.setState({ memberFilter });
    }
    _toggleQualityFilter(metricName: string): any {
        const { habitFilter } = this.state;
        const index = habitFilter.indexOf(metricName);
        index > -1 ? habitFilter.splice(index, 1) : habitFilter.push(metricName);
        this.setState({ habitFilter });
    }

    _getUserScoreCard(): any {
        const { currentPeriod, memberFilter } = this.state;
        const { habitLogs = [], currentUsers = {} } = this.props;
        const { current, prior } = periodToPriorAndCurentTimestamp(currentPeriod);

        const currentUnfilteredHabitLogs = habitLogs.filter(log => matchFilter(log, currentUsers, current.start, current.end));
        const priorUnfilteredHabitLogs = habitLogs.filter(log => matchFilter(log, currentUsers, prior.start, prior.end));

        const allHabits = _.uniqBy(currentUnfilteredHabitLogs, 'habitName').map(log => ({ habitName: log.habitName, displayName: log.displayName }));
        const allUsers = _.uniqBy(currentUnfilteredHabitLogs, 'uid').filter(({ uid }) => currentUsers[uid]).map(
            ({ uid }) => ({
                name: `${currentUsers[uid]?.loginInfo?.firstName} ${_.take(currentUsers[uid]?.loginInfo?.lastName)}.`,
                uid
            })).sort((a, b) => a.name.localeCompare(b.name));

        const currentLogs = this._applyHabitFilter(memberFilter.length ? currentUnfilteredHabitLogs.filter(log => memberFilter.includes(log.uid)) : currentUnfilteredHabitLogs);
        const priorLogs = this._applyHabitFilter(memberFilter.length ? priorUnfilteredHabitLogs.filter(log => memberFilter.includes(log.uid)) : priorUnfilteredHabitLogs);

        const overall = currentLogs.map(log => log.habitScore);
        const overallPrior = priorLogs.map(log => log.habitScore);

        const habitNames = _.uniqBy([...currentLogs, ...priorLogs], 'habitName').map(log => ({ habitName: log.habitName, displayName: log.displayName }));
        const logsByHabitName: { [uid: string]: HabitScoreInCollection[] } = {};
        for (const log of currentLogs) {
            logsByHabitName[log.habitName] = [...(logsByHabitName[log.habitName] || []), log];
        }

        const priorLogsByHabitName: { [uid: string]: HabitScoreInCollection[] } = {};
        for (const log of priorLogs) {
            priorLogsByHabitName[log.habitName] = [...(priorLogsByHabitName[log.habitName] || []), log];
        }

        const logsByUser: { [uid: string]: HabitScoreInCollection[] } = {};
        for (const log of currentLogs) {
            logsByUser[log.uid] = [...(logsByUser[log.uid] || []), log];
        }

        const habitList = habitNames.map(({ habitName, displayName }) => {

            const completedLogs = (logsByHabitName[habitName] || []).filter(log => log.habitScore);
            const completedPriorLogs = (priorLogsByHabitName[habitName] || []).filter(log => log.habitScore);

            const currentLogCount = (logsByHabitName[habitName] || []).length;
            const priorLogCount = (priorLogsByHabitName[habitName] || []).length;

            const currentPerc = currentLogCount ? ((completedLogs.length / currentLogCount) * 100) : 0;
            const priorPerc = priorLogCount ? ((completedPriorLogs.length / priorLogCount) * 100) : 0;

            const habitInfo = {
                habitName,
                displayName,
                logs: (logsByHabitName[habitName] || []).length,
                currentLogCount,
                currentAdherence: completedLogs.length,
                currentPerc,
                priorLogCount,
                priorAdherence: completedPriorLogs.length,
                priorPerc,
                change: (priorPerc) ? ((currentPerc - priorPerc)) : currentPerc,
            };

            return habitInfo;
        });

        const trend = currentLogs.reduce((trendList, { habitScore, date }) => ({
            ...trendList, [date]: [...(trendList[date] || []), habitScore]
        }), {});

        const trendData = [];
        Object.keys(trend).forEach((key) => {
            const ydata = _.mean(trend[key]) * 100;
            trendData.push({ x: new Date(key), y: ydata })
        });

        let userList = Object.keys(logsByUser).map((uid) => ({
            name: `${currentUsers[uid]?.loginInfo?.firstName} ${_.take(currentUsers[uid]?.loginInfo?.lastName)}`,
            uid,
            score: (logsByUser[uid].reduce((sum, log) => sum + (log.habitScore), 0) / logsByUser[uid].length) * 100,
        }));

        userList = _.sortBy(userList, (user) => user.score);
        const topUsers = _.take(_.reverse(_.sortBy(userList, 'score')), 5);
        const bottomUsers = _.takeRight(_.reverse(_.sortBy(userList, 'score').filter(user => !topUsers.includes(user))), 5);
        habitList.sort((log1, log2) => log1.habitName.localeCompare(log2.habitName));
        const sortedHabits = this._applySorts(habitList);

        return {
            allHabits,
            allUsers,
            habitList: sortedHabits,
            topUsers,
            bottomUsers,
            overallPrior,
            overall,
            userList,
            trend: trendData,
        };
    }

    _getCSVData(): string[][] {
        const { habitList } = this._getUserScoreCard();
        const header = ['Metric', 'Prior Period', 'Current Period', 'Change', 'Adherence', 'Logs'];
        const csvArray = habitList.map(({ displayName, currentPerc, priorPerc, change, logs }) => {
            return [displayName, priorPerc, currentPerc, change, logs];
        });

        return [header].concat(csvArray);
    }

    _toggleSort: (sortOrder: string) => void = (sortOrder) => {
        const currentSortOder = this.state[sortOrder];
        this.setState({
            priorSortOrder: '',
            currentSortOrder: '',
            changeSortOrder: '',
            logSortOrder: '',
        });

        let newSortValue = '';
        switch (currentSortOder) {
            case 'asc':
                newSortValue = 'desc';
                break;
            case 'desc':
                newSortValue = '';
                break;
            default:
                newSortValue = 'asc';
        }
        this.setState({ [sortOrder]: newSortValue });
    };
    _applySorts(usersList: any[]): any {
        const { logSortOrder, currentSortOrder, priorSortOrder, changeSortOrder } = this.state;
        const sortFunctions = [];
        const sortDirections = [];

        usersList = _.orderBy(usersList, 'habitName');

        if (logSortOrder) {
            sortFunctions.push((habit) => habit.logs);
            sortDirections.push(logSortOrder);
        }
        if (currentSortOrder) {
            sortFunctions.push((habit) => habit.currentPerc);
            sortDirections.push(currentSortOrder);
        }
        if (priorSortOrder) {
            sortFunctions.push((habit) => habit.priorPerc);
            sortDirections.push(priorSortOrder);
        }
        if (changeSortOrder) {
            sortFunctions.push((habit) => habit.change);
            sortDirections.push(changeSortOrder);
        }

        if (priorSortOrder || changeSortOrder || currentSortOrder || logSortOrder) {
            usersList = _.orderBy(usersList, sortFunctions, sortDirections);
        }

        return usersList;
    }
    _applyHabitFilter(habitList: any[]): any {
        const { habitFilter } = this.state;
        if (!habitFilter.length) {
            return habitList;
        }
        const filteredHabits = habitList.filter(({ habitName }) => habitFilter.includes(habitName));
        return filteredHabits;
    }

    _getBarColor(score: number): any {
        switch (true) {
            case score < 25:
                return '#FECACA';
            case score < 50:
                return '#BBC9F3';
            case score < 75:
                return '#6181E4';
            default:
                return '#1A3BAD';
        }
    }

    _getProgressColor(score: number): any {
        switch (true) {
            case score < 20:
                return '#DC2626';
            case score < 40:
                return '#FBBF24';
            case score < 60:
                return '#94A3B8';
            case score <= 80:
                return '#3460DC';
            default:
                return '#10B981';
        }
    }

    _renderUserList(habitList: any[]): any {
        const { metricLogs, habitLogs } = this.props;

        if (!metricLogs?.length || !habitLogs?.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        <div className="mx-auto d-inline-block">
                            {getLoadingIndicator()}
                        </div>
                    </td>
                </tr>
            );
        }

        if (!habitList.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        No data found
                    </td>
                </tr>
            );
        }

        return habitList.map(
            (
                {
                    displayName,
                    currentLogCount,
                    priorLogCount,
                    priorAdherence,
                    currentAdherence,
                    currentPerc,
                    priorPerc,
                    change,
                    logs,
                },
                index
            ) => (
                <tr className="outcome-row" testid="scorecard-row" key={`user-${index}`}>
                    <td testid={`displayName-${index}`} className="ps-4">{displayName}</td>
                    {!isMobile && <td className="text-center">
                        <span className="text-large-dull me-3">{`${priorAdherence}/${priorLogCount}`}</span>
                        <span className="text-large">{`${parseInt(priorPerc)}%`}</span>
                    </td>}
                    <td className="text-center">
                        <span className="text-large-dull me-3">{`${currentAdherence}/${currentLogCount}`}</span>
                        <span className="text-large">{`${parseInt(currentPerc)}%`}</span>
                    </td>

                    {!isMobile && <>
                        <td className="text-center">
                            <div className="inline-content">
                                <img
                                    className="image-icon"
                                    src={Math.sign(change) === 1 ? arrowUp : arrowDown}
                                    alt="up or down icon"
                                />
                                <div className={`disposition-value ${Math.sign(change) === 1 ? 'positive' : 'negative'}`}>
                                    {`${parseInt(change)}%`}
                                </div>
                            </div>
                        </td>
                        <td className="text-center"><b>{logs}</b></td>
                    </>}
                </tr>
            )
        );
    }

    render(): any {
        const {
            currentSortOrder,
            priorSortOrder,
            logSortOrder,
            changeSortOrder,
            currentPeriod,
            chartType,
            memberFilter,
            habitFilter,
            memberSearch,
        } = this.state;
        const { overall, trend, topUsers, bottomUsers, overallPrior, habitList, allHabits, allUsers } = this._getUserScoreCard();
        const meanOverall = overall.length ? _.mean(overall) : 0;
        const overallAdherence = overall.filter((score) => score === 1).length;
        const priorAdherence = overallPrior.filter((score) => score === 1).length;
        const priorPercentage = parseInt(overallPrior.length ? (priorAdherence / overallPrior.length) * 100 : 0);
        const overallPercentage = parseInt(overall.length ? (overallAdherence / overall.length) * 100 : 0);
        const adherenceChange = (priorAdherence ? (overallAdherence - priorAdherence) : overallAdherence)
        const meanPrior = overallPrior.length ? _.mean(overallPrior) : 0;
        const meanChange = meanPrior ? (meanOverall - meanPrior) : meanOverall;
        let renderedList = habitList;
        renderedList = _.filter(renderedList, 'logs');
        const filteredAVG = parseInt(_.mean(renderedList.map(({ currentPerc }) => currentPerc)));
        const filterdPriorAVG = parseInt(_.mean(renderedList.map(({ priorPerc }) => priorPerc)));
        const filteredCount = _.sum(renderedList.map(({ currentLogCount }) => currentLogCount));
        const filteredPriorCount = _.sum(renderedList.map(({ priorLogCount }) => priorLogCount));
        const filteredPriorAdherence = _.sum(renderedList.map(({ priorAdherence }) => priorAdherence));
        const filteredAdherence = _.sum(renderedList.map(({ currentAdherence }) => currentAdherence));
        const filterdChange = filterdPriorAVG ? (filteredAVG - filterdPriorAVG) : filteredAVG;
        const summaryLabel = habitFilter.length
            ? habitFilter.length === 1
                ? renderedList.find(({ habitName }) => habitName === habitFilter[0])?.displayName
                : 'Average'
            : 'Overall';
        const searchedMembers = memberSearch
            ? allUsers.filter(
                ({ uid, name }) =>
                    uid.toLowerCase().indexOf(memberSearch.toLowerCase()) > -1 ||
                    name.toLowerCase().indexOf(memberSearch.toLowerCase()) > -1
            )
            : allUsers;
        const minQuality = _.minBy(renderedList, (action) => action.currentPerc);
        const maxQuality = _.maxBy(renderedList, (action) => action.currentPerc);
        const change = parseInt(habitFilter.length ? filterdChange : meanChange * 100);

        return (
            <div className="manual-container">
                <div className={!isMobile ? 'd-flex justify-content-between align-content-end' : ''}>
                    <div className={`align-self-end ${!isMobile ? '' : 'mb-2'}`}>
                        <button
                            testid='see-scores-button'
                            onClick={() => this.setState({ chartType: 'scores' })}
                            className={`btn  btn-sm toggle-button ${chartType === 'scores' ? 'active' : ''}`}
                            style={{ fontWeight: chartType === 'scores' ? 600 : 'normal' }}
                        >
                            Scores
                        </button>
                        <button
                            testid='see-trends-button'
                            onClick={() => this.setState({ chartType: 'trends' })}
                            className={`btn btn-sm ms-2 toggle-button ${chartType === 'trends' ? 'active' : ''}`}
                            style={{ fontWeight: chartType === 'trends' ? 600 : 'normal' }}
                        >
                            Trends
                        </button>
                    </div>
                    <div className={`d-flex ${!isMobile ? '' : 'mb-2'}`}>
                        <Dropdown drop="right" className=" " autoClose="outside" >
                            <Dropdown.Toggle className={`btn btn-sm toggle-button`} style={{ color: '#475569' }}>
                                {`ACTIONS ${habitFilter.length ? '*' : ''}`}
                                <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item testid='all-habits-item' onClick={() => this.setState({ habitFilter: [] })}>
                                    All Actions
                                </Dropdown.Item>
                                {allHabits.sort((a, b) => a.habitName.localeCompare(b.habitName)).map(({ habitName, displayName }, i) => (
                                    <Dropdown.Item
                                        key={`action-items-${habitName}`}
                                        testid={`action-items-${habitName}`}
                                        onClick={() => this._toggleQualityFilter(habitName)}
                                    >
                                        <img
                                            className="image-icon me-4"
                                            src={habitFilter.includes(habitName) ? checkboxCheck : checkbox}
                                            alt="check icon"
                                        />
                                        {displayName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown drop="right" className=" " autoClose="outside">
                            <Dropdown.Toggle className={`btn btn-sm ms-2 toggle-button`} style={{ color: '#475569' }}>
                                {memberFilter.length ? 'MEMBERS *' : 'MEMBERS'}
                                <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Header>
                                    <div className="search-uid">
                                        <img className="image-icon " src={searchIcon} alt="search icon" />

                                        <input
                                            testid="search-member"
                                            onChange={(e) => this.setState({ memberSearch: e.target.value })}
                                            type="text"
                                            placeholder="Search"
                                            className="form-input"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </div>
                                </Dropdown.Header>
                                <Dropdown.Item testid='clear-member-search' onClick={() => this.setState({ memberFilter: [] })}>
                                    All members{' '}
                                </Dropdown.Item>
                                {searchedMembers.map(({ uid, name }, i) => (
                                    <Dropdown.Item key={`member-item-${uid}`} testid={`member-item-${uid}`} onClick={() => this._toggleMemberFilter(uid)}>
                                        <img
                                            className="image-icon me-4"
                                            src={memberFilter.includes(uid) ? checkboxCheck : checkbox}
                                            alt="check icon"
                                        />
                                        {name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="period-filter-container">
                            {this.dateFilter.map(({ number, period }, index) => (
                                <div
                                    testid={`period-filter-${index}`}
                                    key={`period-filter-${index}`}
                                    className={`period-filter ${number === currentPeriod.number && period === currentPeriod.period
                                        ? 'active'
                                        : ''
                                        }`}
                                    onClick={() => this.setState({ currentPeriod: { number, period } })}
                                >
                                    {`${number}${period.toUpperCase()}`}
                                </div>
                            ))}
                        </div>
                        {!isMobile && <CSVLink testid="download-csv" className="btn app-btn-outline btn-lg" data={this._getCSVData()}>
                            <img className="image-icon button-icon" src={exportIcon} alt="export icon" />
                            Export
                        </CSVLink>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6" style={{ marginTop: '1.75rem' }}>
                        {!_.isEmpty(maxQuality) && <div className="outcome-chart-container d-flex flex-column justify-content-center chart-section">
                            {Boolean(renderedList.length) && (
                                chartType === 'trends' ? (
                                    <VictoryChart domainPadding={{ x: 10 }} padding={{ top: 30, bottom: 50, right: 20, left: 60 }}>
                                        <VictoryLegend x={50} y={0}
                                            title="AVERAGE ADHERENCE SCORE BY PERIOD"
                                            orientation="horizontal"
                                            gutter={25}
                                            style={{ title: { fontSize: 12, color: '#475569', fontWeight: 'bold', fontFamily: font } }}
                                            data={[]}
                                        />
                                        <VictoryAxis
                                            testid={'y-axis'}
                                            scale={{ x: 'time' }}
                                            tickFormat={getShortCalendarFormatForDate}
                                            style={{
                                                axis: { stroke: '#dddddd', width: 1 },
                                                tickLabels: {
                                                    stroke: 'transparent',
                                                    fontFamily: font,
                                                    fontSize: 11,
                                                    angle: -45,
                                                    padding: 20,
                                                },
                                                ticks: { stroke: '#dddddd' },

                                            }}
                                        />
                                        <VictoryAxis
                                            dependentAxis
                                            testid={'real-y-axis'}
                                            scale={{ x: 'time' }}
                                            tickFormat={(tick) => `${tick}%`}
                                            domain={[0, 102]}
                                            tickValues={[25, 50, 75, 100]}
                                            style={{
                                                axis: { stroke: '#dddddd', width: 1 },
                                                tickLabels: { stroke: 'transparent', fontFamily: font, fontSize: 11 },
                                                ticks: { stroke: 'transparent' },
                                                grid: { stroke: '#dddddd', strokeDasharray: [4, 4] },
                                            }}
                                        />
                                        <VictoryBar
                                            animate
                                            key={`bar-chart-trend`}
                                            testid={`victory-bar-trend`}
                                            interpolation="basis"
                                            style={{
                                                data: {
                                                    fill: "#6183E4",
                                                    strokeWidth: 2,
                                                },
                                                labels: {
                                                    fontSize: 4,
                                                    fill: '#0F172A',
                                                },
                                            }}
                                            data={trend.map(d => ({ ...d, y0: .5 }))}
                                            scale={{ x: 'time' }}
                                            cornerRadius={{
                                                topLeft: 1,
                                                topRight: 1,
                                                bottomLeft: 1,
                                                bottomRight: 1,
                                            }}
                                        />
                                        <VictoryLine
                                            animate
                                            key={`line-chart-trend`}
                                            testid={`victory-line-trend`}
                                            interpolation="basis"
                                            style={{
                                                data: {
                                                    stroke: "#173EAD",
                                                    strokeWidth: 3,
                                                },
                                            }}
                                            data={getTimeAdjustedTrend(trend, currentPeriod)}
                                            scale={{ x: 'time' }}
                                        />

                                    </VictoryChart>
                                ) : (
                                    <VictoryChart domainPadding={{ x: 10 }} padding={{ top: 60, bottom: 50, right: 20, left: 60 }}>
                                        <VictoryLegend x={50} y={0}
                                            title="AVERAGE ADHERENCE SCORE BY ACTION"
                                            orientation="horizontal"
                                            gutter={25}
                                            style={{ labels: { fontSize: 12 }, title: { fontSize: 12, color: '#475569', fontWeight: 'bold', fontFamily: font } }}
                                            data={[
                                                { name: "going well", symbol: { fill: "#1A3BAD", type: 'square' } },
                                                { name: "keep going", symbol: { fill: "#6181E4", type: 'square' } },
                                                { name: "focus here", symbol: { fill: "#BBC9F3", type: 'square' } },
                                                { name: "needs work", symbol: { fill: "#FECACA", type: 'square' } },
                                            ]}
                                        />
                                        <VictoryAxis
                                            style={{
                                                axis: { stroke: '#756f6a', fontSize: 3 },
                                                axisLabel: { fontSize: 20, padding: 8 },
                                                ticks: { stroke: 'grey', size: 2 },
                                                tickLabels: { fontSize: 11, padding: 10, angle: -45 },
                                            }}
                                        />
                                        <VictoryAxis
                                            dependentAxis
                                            testid={'real-y-axis'}
                                            tickFormat={(tick) => `${tick}%`}
                                            style={{
                                                axis: { stroke: '#dddddd', width: 1 },
                                                axisLabel: { fontSize: 20, padding: 2 },
                                                tickLabels: { stroke: 'transparent', fontFamily: font, fontSize: 12, padding: 7 },
                                                ticks: { stroke: 'grey', size: 2 },
                                                grid: { stroke: '#dddddd', strokeDasharray: [4, 4] },
                                            }}
                                            domain={[0, 102]}
                                            tickValues={[25, 50, 75, 100]}
                                        />
                                        <VictoryBar
                                            horizontal
                                            data={_.take(renderedList, 10).reverse().map(d => ({ ...d, y0: .5 }))}
                                            x="displayName"
                                            y={(data) => data.currentPerc}
                                            style={{
                                                data: {
                                                    fill: ({ datum }) => this._getBarColor(datum.currentPerc),
                                                    strokeWidth: 2,
                                                },
                                                labels: {
                                                    fontSize: 4,
                                                    fill: '#0F172A',
                                                },
                                            }}
                                            cornerRadius={{
                                                topLeft: 1,
                                                topRight: 1,
                                                bottomLeft: 1,
                                                bottomRight: 1,
                                            }}
                                            labelComponent={<VictoryLabel angle={-45} style={{ fontSize: 5 }} />}
                                            width="100%"
                                        />
                                    </VictoryChart>
                                )
                            )}
                        </div>}
                        {!_.isEmpty(maxQuality) && <div className={`row ${!isMobile ? 'py-4' : 'pt-4'}`}>
                            <div className="col-12 col-lg-6">
                                {chartType === 'trends' ? (
                                    <div className="d-flex flex-column align-items-center justify-content-center h-100 chart-section">
                                        <p className="circle-chart-title">
                                            {`${summaryLabel?.toUpperCase()} ADHERENCE RATE`}{' '}
                                        </p>
                                        <div className="change-summary" style={{ color: Math.sign(habitFilter.length ? change : adherenceChange) === 1 ? '#173EAD' : '#8EA7EC' }}>
                                            <img
                                                className="image-icon"
                                                alt="arrow icon"
                                                src={Math.sign(habitFilter.length ? change : adherenceChange) === 1 ? arrowUp : arrowDown}
                                            />
                                            {`${change}%`}
                                        </div>
                                        <div className="change d-flex justify-content-between align-self-stretch">
                                            <div>
                                                <p className="prior-title small">PREVIOUS PERIOD</p>
                                                <p className="prior-title small">{`${habitFilter.length ? filterdPriorAVG : priorPercentage}% ${habitFilter.length ? filteredPriorAdherence : priorAdherence} of ${habitFilter.length ? filteredPriorCount : overallPrior.length}`}</p>
                                            </div>
                                            <div className="ms-auto">
                                                <p className="prior-title small">CURRENT PERIOD</p>
                                                <p className="prior-title small">{`${habitFilter.length ? filteredAVG : overallPercentage}% ${habitFilter.length ? filteredAdherence : overallAdherence} of ${habitFilter.length ? filteredCount : overall.length}`}</p>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column align-items-center justify-content-center h-100 chart-section">
                                        <p className="circle-chart-title">
                                            {`${summaryLabel?.toUpperCase()} ADHERENCE RATE`}{' '}
                                        </p>
                                        <div className="circle-chart-container">
                                            <CircularProgressbar
                                                styles={{
                                                    path: {
                                                        stroke: this._getProgressColor(
                                                            habitFilter.length ? filteredAVG : meanOverall * 100
                                                        ),
                                                        strokeLinecap: 'butt',
                                                    },
                                                }}
                                                strokeWidth={10}
                                                value={habitFilter.length ? filteredAVG : (meanOverall * 100)}
                                            >
                                                <p className="overall-score">
                                                    {habitFilter.length
                                                        ? parseInt(filteredAVG)
                                                        : parseInt(meanOverall * 100)}
                                                    %
                                                </p>
                                                <p className="overall-name text-small">
                                                    {habitFilter.length
                                                        ? `${filteredAdherence} of ${filteredCount}`.toUpperCase()
                                                        : `${overallAdherence} of ${overall.length}`.toUpperCase()
                                                    }
                                                </p>
                                            </CircularProgressbar>
                                        </div>
                                        <p className="adherence-prior-title mt-2">PREVIOUS PERIOD</p>
                                        <p className="adherence-prior-title">
                                            {habitFilter.length
                                                ? `${filterdPriorAVG}%, ${filteredPriorAdherence} of ${filteredPriorCount}`.toUpperCase()
                                                : `${priorPercentage}%, ${priorAdherence} of ${overallPrior.length}`.toUpperCase()
                                            }
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className={`col-12 col-lg-6 ${!isMobile ? '' : 'pt-4'}`} style={!isMobile ? { paddingLeft: 0 } : {}}>
                                <div className="d-flex flex-column align-items-center justify-content-center  h-100 chart-section overflow-scroll user-kpi">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="1" className="rank-score top-rank-score" style={{ border: 'none', paddingBottom: 30 }}>
                                                    {memberFilter.length
                                                        ? 'Most Adhered action'
                                                        : 'Highest Adherence Members'}
                                                </td>
                                                <td colSpan="2" style={{ border: 'none' }}>
                                                    {memberFilter.length ? (
                                                        (<div className="d-flex justify-content-between">
                                                            <div className="">
                                                                <p className="action-name">
                                                                    {maxQuality?.displayName}
                                                                </p>
                                                                <p className="small-text">{`${maxQuality?.currentAdherence} of ${maxQuality?.currentLogCount}`}</p>
                                                            </div>
                                                            {
                                                                chartType === "trends" ?
                                                                    (
                                                                        <div className="d-flex action-change">
                                                                            <img className="image-icon" alt="arrow-icon" src={Math.sign(maxQuality?.change) === 1 ? arrowUp : arrowDown} />
                                                                            <span className="trend-change" style={{ color: Math.sign(maxQuality?.change) === 1 ? '#173EAD' : '#8EA7EC' }}>{maxQuality.change ? Math.abs(maxQuality?.change.toFixed(1)) : 0}%</span>
                                                                        </div>
                                                                    ) :
                                                                    (
                                                                        <div style={{ width: '40px', height: '40px' }}>
                                                                            <CircleProgress
                                                                                styles={{
                                                                                    path: {
                                                                                        stroke: this._getProgressColor(maxQuality?.currentPerc),
                                                                                    },
                                                                                    text: {
                                                                                        fill: 'black'
                                                                                    }
                                                                                }}
                                                                                text={`${parseInt(maxQuality?.currentPerc || 0).toFixed(1)}%`}
                                                                                value={maxQuality?.currentPerc || 0}
                                                                            />
                                                                        </div>
                                                                    )
                                                            }

                                                        </div>)
                                                    ) : (
                                                        topUsers.map(({ name, uid, score }) => (
                                                            <div key={`top-user-${uid}`} testid={`top-user-${uid}`} className="rank-user">
                                                                <span className="uid">{_.take(uid, 5)}</span>
                                                                <span className="name">{name}</span>
                                                                <span className="score">{score.toFixed(1)}%</span>
                                                            </div>
                                                        ))
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="1" className="rank-score bottom-rank-score" style={{ paddingTop: 30 }}>
                                                    {memberFilter.length
                                                        ? 'Least Adhered Action'
                                                        : 'Lowest Adherence Memebers'}
                                                </td>
                                                <td colSpan="2">
                                                    {memberFilter.length ? (
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                <p className="action-name">
                                                                    {minQuality?.displayName.toUpperCase()}
                                                                </p>
                                                                <p className="small-text">{`${minQuality?.currentAdherence} of ${minQuality?.currentLogCount}`}</p>
                                                            </div>
                                                            {
                                                                chartType === "trends" ?
                                                                    (
                                                                        <div className="d-flex action-change">
                                                                            <img alt="arrow-icon" className="image-icon" src={Math.sign(minQuality?.change) === 1 ? arrowUp : arrowDown} />
                                                                            <span className="trend-change" style={{ color: Math.sign(minQuality?.change) === 1 ? '#173EAD' : '#8EA7EC' }}>{minQuality.change ? Math.abs(minQuality?.change.toFixed(1)) : 0}%</span>
                                                                        </div>
                                                                    ) :

                                                                    (
                                                                        <div style={{ width: '40px', height: '40px' }}>
                                                                            <CircleProgress
                                                                                styles={{
                                                                                    path: {
                                                                                        stroke: this._getProgressColor(
                                                                                            minQuality?.currentPerc
                                                                                        ),
                                                                                    },
                                                                                    text: {
                                                                                        fill: 'black'
                                                                                    }
                                                                                }}
                                                                                text={`${parseInt(minQuality?.currentPerc)}%`}
                                                                                value={minQuality?.currentPerc || 0}
                                                                            />
                                                                        </div>

                                                                    )
                                                            }

                                                        </div>
                                                    ) : (
                                                        bottomUsers.map(({ name, uid, score }) => (
                                                            <div key={`bottom-user-${uid}`} testid={`bottom-user-${uid}`} className="rank-user">
                                                                <span className="uid">{_.take(uid, 5)}</span>
                                                                <span className="name">{name}</span>
                                                                <span className="score">{score.toFixed(1)}%</span>
                                                            </div>
                                                        ))
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-center">
                                            Action
                                        </th>
                                        {!isMobile && <th scope="col" className="text-center">
                                            Prior
                                            <div
                                                onClick={() => this._toggleSort('priorSortOrder')}
                                                testid="prior-sort"
                                                className={`sort-container ${priorSortOrder}`}
                                            >
                                                <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                <img className="desc" src={caretDownIcon} alt="down arrow" />
                                            </div>
                                        </th>}
                                        <th scope="col" className="text-center">
                                            {!isMobile ? 'Current' : 'Scores'}
                                            <div
                                                onClick={() => this._toggleSort('currentSortOrder')}
                                                testid="current-sort"
                                                className={`sort-container ${currentSortOrder}`}
                                            >
                                                <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                <img className="desc" src={caretDownIcon} alt="down arrow" />
                                            </div>
                                        </th>
                                        {!isMobile && <>
                                            <th scope="col" className="text-center">
                                                Change
                                                <div
                                                    onClick={() => this._toggleSort('changeSortOrder')}
                                                    testid="change-sort"
                                                    className={`sort-container ${changeSortOrder}`}
                                                >
                                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                                </div>
                                            </th>
                                            <th scope="col" className="text-center">
                                                Logs
                                                <div
                                                    onClick={() => this._toggleSort('logSortOrder')}
                                                    testid="log-sort"
                                                    className={`sort-container ${logSortOrder}`}
                                                >
                                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                                </div>
                                            </th>
                                        </>}
                                    </tr>
                                </thead>
                                <tbody>{this._renderUserList(renderedList)}
                                    {Boolean(renderedList.length) && <tr className="outcome-row-overall" testid="scorecard-row">
                                        <td className="ps-4">Overall</td>
                                        {!isMobile && <td className="text-center">
                                            <span className="text-large-dull me-3">{`${priorAdherence}/${overallPrior.length}`}</span>
                                            <span className="text-large">{`${parseInt(priorPercentage)}%`}</span>
                                        </td>}
                                        <td className="text-center">
                                            <span className="text-large-dull me-3">{`${overallAdherence}/${overall.length}`}</span>
                                            <span className="text-large">{`${parseInt(overallPercentage)}%`}</span>
                                        </td>

                                        {!isMobile && <>
                                            <td className="text-center">
                                                <div className="inline-content">
                                                    <img
                                                        className="image-icon"
                                                        src={Math.sign(change) === 1 ? arrowUp : arrowDown}
                                                        alt="up or down icon"
                                                    />
                                                    <div className={`disposition-value ${Math.sign(change) === 1 ? 'positive' : 'negative'}`}>
                                                        {`${parseInt(change)}%`}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center"><b>{overall.length}</b></td>
                                        </>}
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, content: { currentUsers, metricLogs, habitLogs, chosenProtocolsMapping } }) => ({
    auth,
    metricLogs,
    habitLogs,
    chosenProtocolsMapping,
    currentUsers,
});

const ConnectedAdherence: ComponentType<{}> = connect(mapStateToProps, {
    fetchMetricLogsForYear,
    fetchHabitLogsForYear,
    fetchUsers,
})(Adherence);

export default ConnectedAdherence;
