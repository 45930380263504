// @flow

import axios from 'axios';
import { getInstance } from './instance';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const createSubscriptionWithPaymentAndEmail = (paymentId: string, email: string): Promise<any> => {
  
    const instance = getInstance();
    return instance.post('setupAdminSubscription', {paymentMethod: paymentId, email});
}

export const activateNetworkWithCode = (network: string, code: string): Promise<any> => {
  
    const instance = getInstance();
    return instance.post('activateNetworkWithCode', {network, code});
}

export const sendNotificationToToken = (token: string, title: string, body: string, networkKey: string, user?: string): Promise<any> => {
  
    const instance = getInstance();
    return instance.post('sendPushNotification', {to: token, title, body, networkKey, user});
}

