// @flow

import React, { useState } from 'react';

import removeUserIcon from '../../../assets/svgs/removeUserBlue.svg';
import type { ComponentType } from 'react';
import _ from 'lodash';

type RemoveUserProps = {
    onDone: () => void;
    fullname?: string;
    removeUser: () => void;
    removeIcon?: any,
    positive?: boolean,
    title?: string,
    description?: string
};

export const RemoveUserModal: ComponentType<RemoveUserProps> = ({ title, description, positive, removeIcon, onDone = _.noop, fullname = "", removeUser = _.noop }) => {

    const [success, setSuccess] = useState(false);

    const onRemoveUser: any = () => {
        removeUser();
        setSuccess(true);
    }

    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={removeIcon || removeUserIcon} className="modal-icon" alt="modal icon" />
                </div>
                {success ? (
                    <p testid="modal-title" className="modal-title">{title || "Member Removed"}</p>
                ) : (
                    <p testid="modal-title" className="modal-title">{title || `Remove ${fullname || ""}`}</p>
                )}
            </div>
            {success ? (
                <div className="modal-bd d-flex flex-column justify-content-center">
                    <button testid="done-remove" className="btn btn-block btn-lg app-btn-danger mt-3" onClick={onDone}>
                        Okay
                    </button>
                </div>
            ) : (
                <>
                    <p className="modal-description">
                        {description || "You are about to remove this member from your network. Are you sure?"}
                    </p>
                    <div className="modal-bd d-flex flex-column justify-content-center">
                        <button
                            className={`btn btn-block btn-lg ${positive ? 'app-btn-primary' : 'app-btn-danger'} mt-3`}
                            testid="confirm-remove"
                            onClick={onRemoveUser}
                        >
                            Confirm
                        </button>
                        <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                            Cancel
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
