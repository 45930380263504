// @flow

import { loadStripe } from '@stripe/stripe-js';
import {isAlpha} from '../appConfig';


export const getStripeComponent = (): Promise<any> => {
    const stripePromise = loadStripe(isAlpha()
        ? 'pk_test_51IbsgqDuIS2mPYoRHCwFN19eLic7Su2Sc8joe5B0AADP6oLAZ2R6yvHiJj1U6MWHtZBpRggdGhQ8v7YzbUOG2GXr00MEi1rNWc'
        : 'pk_live_51IbsgqDuIS2mPYoRyicIUEhmfc3doVRB5HCW1Us1TkB3B4eUmtqEjf71VbkJJnuNlrHHRCM9Ae5gmYuDX6o6P9Tg0029bulrYu'
    );
    return stripePromise;
}