// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import 'firebase/storage';
import _ from 'lodash';
import moment from 'moment';
import {
    fetchUsers,
    fetchMetricLogsForYear,
    fetchHabitLogsForYear,
    fetchMetricLogsForPastMonth,
    fetchAdherenceLogsForPastMonth,
    setFilteredTimeFrame,
    setNumericFilters,
    setOtherFilters,
} from '../../../actions';
import searchIcon from '../../../assets/svgs/search.svg';
import caretUpIcon from '../../../assets/svgs/caret-up.svg';
import checkboxCheck from '../../../assets/svgs/checkbox-check.svg';
import checkbox from '../../../assets/svgs/checkbox.svg';

import { METRIC_SCORE_MAPPING, EMPTY_CALENDAR_CELL_COLOR, MAX_QUALITY_CELL_COLOR, MAX_ACTION_CELL_COLOR, MIN_QUALITY_CELL_COLOR, MIN_ACTION_CELL_COLOR } from '../../../Constants';
import { interpolateColor, getCalendarTooltip, getLoadingIndicator, matchFilter } from '../../UI Resources';
import { CalendarMonth } from './CalendarMonth';
import { Dropdown } from 'react-bootstrap';


import type { ComponentType } from 'react';
import type {

    MetricScoreInCollection,
    HabitScoreInCollection,
    CurrentUsersObject,
    AuthObject,
    TableCellProps
} from '../../../flowTypes';
import { isMobile } from 'react-device-detect';

type FCProps = {
    children?: React.Node,
};

export const TableRow = ({ children }: FCProps): React.Node => {
    return <tr>{children}</tr>;
};

export const TableCell: ComponentType<TableCellProps> = ({ children, data, onClick, type = "metric" }) => {
    const maxColor = type === "metric" ? MAX_QUALITY_CELL_COLOR : MAX_ACTION_CELL_COLOR;
    const minColor = type === "metric" ? MIN_QUALITY_CELL_COLOR : MIN_ACTION_CELL_COLOR;

    const tooltipText = data && type === "metric" ? getCalendarTooltip(data) : getCalendarTooltip(data);
    const backgroundColor = data ? interpolateColor(maxColor, minColor, 1 - (data / 5)) : EMPTY_CALENDAR_CELL_COLOR;


    return (
        <td
            onClick={onClick}
            data-tooltip={tooltipText}
            className={data ? 'calendar-cell-button' : 'empty-calendar-cell'}
            style={{ padding: 2 }}
        >
            <div
                className={data ? 'calendar-cell-button' : 'empty-calendar-cell'}
                style={{ backgroundColor, padding: 2, opacity: children ? 1 : 0 }}
            >
                {children}
            </div>
        </td>
    );
};

export const Th = ({ children }: FCProps): React.Node => {
    return <th>{children}</th>;
};
type State = {
    habitFilter: string[],
    qualityFilter: string[],
    memberFilter: string[],
    memberSearch: string,
    cellType: 'metric' | 'action'
};
type CalendarProps = {
    auth: AuthObject,
    metricLogs: Array<MetricScoreInCollection>,
    habitLogs: Array<HabitScoreInCollection>,
    metricLogsInitialized: boolean,
    habitLogsInitialized: boolean,
    fetchMetricLogsForYear?: (networkKey: string) => void,
    fetchHabitLogsForYear?: (networkKey: string) => void,
    fetchUsers: () => void,
    currentUsers: CurrentUsersObject,
};
export class Calendar extends React.Component<CalendarProps, State> {

    constructor(props: CalendarProps) {
        super(props);
        this.state = {
            habitFilter: [],
            qualityFilter: [],
            memberFilter: [],
            memberSearch: "",
            cellType: 'metric'

        };

    }

    componentDidMount(): any {
        const {
            metricLogsInitialized,
            habitLogsInitialized,
            auth,
            fetchMetricLogsForYear = _.noop,
            fetchHabitLogsForYear = _.noop,
            currentUsers = {},
            fetchUsers = _.noop,
        } = this.props;
        const { networkKey } = auth || {};
        if (!metricLogsInitialized) {
            fetchMetricLogsForYear(networkKey);
        }
        if (!habitLogsInitialized) {
            fetchHabitLogsForYear(networkKey);
        }
        if (!Object.keys(currentUsers).length) {
            fetchUsers();
        }
    }

    getCalendarMonths(lastDate: any, dateScoreMap: any): any {
        const today = moment();
        const endDate = moment(lastDate, 'MM/DD/YYYY');
        const { cellType } = this.state
        const monthCards = [];
        while (today > endDate || today.format('M') === endDate.format('M')) {
            const year = endDate.format('YYYY');
            const month = endDate.format('MM');

            monthCards.push(
                <div className="col-12 col-md-6 col-lg-6 col-xl-4" key={endDate.format('YYYY-MM')} style={!isMobile ? { minWidth: 332 } : {}}>
                    <div className={`card px-md-2 py-1 pt-3 h-100`}>
                        <div className="card-title calendar-title">{endDate.format('MMMM YYYY')}</div>
                        <div className="card-body calendar-card">
                            <CalendarMonth
                                key={endDate.format('YYYY-MM')}
                                CellElement={TableCell}
                                RowEelement={TableRow}
                                HeaderCellElement={Th}
                                HeaderRowElement={TableRow}
                                year={year}
                                month={month}
                                datesData={{}}
                                datesScore={dateScoreMap}
                                showHeader={true}
                                onClickDate={_.noop} // disable dates
                                reverseDates={false}
                                type={cellType}
                            />
                        </div>
                    </div>
                </div>
            );
            endDate.add(1, 'month');
        }
        return monthCards;
    }

    _toggleMemberFilter(userid: string): any {
        const { memberFilter } = this.state;
        const index = memberFilter.indexOf(userid);
        index > -1 ? memberFilter.splice(index, 1) : memberFilter.push(userid);
        this.setState({ memberFilter });
    }

    _toggleQualityFilter(metricName: string): any {
        const { qualityFilter } = this.state;
        const index = qualityFilter.indexOf(metricName);
        index > -1 ? qualityFilter.splice(index, 1) : qualityFilter.push(metricName);
        this.setState({ qualityFilter, cellType: 'metric', habitFilter: [] });
    }

    _togglehabitFilter(habitName: string): any {
        const { habitFilter } = this.state;
        const index = habitFilter.indexOf(habitName);
        index > -1 ? habitFilter.splice(index, 1) : habitFilter.push(habitName);
        this.setState({ habitFilter, cellType: 'action', qualityFilter: [] });
    }

    _habitPercentageToScore(habitPercent: number): any {
        if (habitPercent > .8) return 5;
        if (habitPercent >= .6) return 4;
        if (habitPercent >= .4) return 3;
        if (habitPercent >= .2) return 2;
        return 1;
    }

    _getCalendarGrouping(): any {
        const { habitFilter, memberFilter, qualityFilter, cellType } = this.state;
        const { habitLogs = [], metricLogs = [], currentUsers } = this.props;

        const sortedMetricLogs = metricLogs.filter(log => !log.isNumericMetric && matchFilter(log, currentUsers, 0, Infinity));
        const sortedHabitLogs = habitLogs.filter(log => matchFilter(log, currentUsers, 0, Infinity));

        const allUsers = _.uniqBy([...sortedMetricLogs, ...sortedHabitLogs], 'uid').filter(({ uid }) => currentUsers[uid]).map(
            ({ uid }) => ({
                name: `${currentUsers[uid]?.loginInfo?.firstName} ${_.take(currentUsers[uid]?.loginInfo?.lastName)}.`,
                uid
            })).sort((a, b) => a.name.localeCompare(b.name));

        const metricNames = _.uniqBy(sortedMetricLogs, 'metricName').map(log => ({ metricName: log.metricName, displayName: log.displayName, isNumericMetric: log.isNumericMetric }));
        const habitNames = _.uniqBy(sortedHabitLogs, 'habitName').map(log => ({ habitName: log.habitName, displayName: log.displayName }));

        const filteredMetricLogs = sortedMetricLogs.filter(
            log => (!memberFilter.length || memberFilter.includes(log.uid)) && (!qualityFilter.length || qualityFilter.includes(log.metricName))
        );
        const filteredHabitLogs = sortedHabitLogs.filter(
            log => (!memberFilter.length || memberFilter.includes(log.uid)) && (!habitFilter.length || habitFilter.includes(log.habitName))
        );

        const lastDate = cellType === 'metric' ? _.last(filteredMetricLogs)?.date : _.last(filteredHabitLogs)?.date;

        let dateScoreMap;
        if (cellType === 'metric') {
            const metricLogsByDate: { [date: string]: number[] } = {};
            for (const log of filteredMetricLogs) {
                metricLogsByDate[log.date] = [...(metricLogsByDate[log.date] || []), METRIC_SCORE_MAPPING[log.metricScore]];
            }
            Object.keys(metricLogsByDate).forEach(date => metricLogsByDate[date] = _.mean(metricLogsByDate[date]));
            dateScoreMap = metricLogsByDate;
        } else {
            const habitLogsByDate: { [date: string]: number[] } = {};
            for (const log of filteredHabitLogs) {
                habitLogsByDate[log.date] = [...(habitLogsByDate[log.date] || []), log.habitScore];
            }
            Object.keys(habitLogsByDate).forEach(date => habitLogsByDate[date] = this._habitPercentageToScore(_.mean(habitLogsByDate[date])));
            dateScoreMap = habitLogsByDate;
        }

        return {
            allUsers,
            metricNames,
            habitNames,
            lastDate,
            dateScoreMap,
        };
    }


    render(): any {
        const { habitLogsInitialized, metricLogsInitialized } = this.props;
        const { habitFilter, memberFilter, qualityFilter, memberSearch, cellType } = this.state;
        const { allUsers, metricNames, habitNames, lastDate, dateScoreMap } = this._getCalendarGrouping();
        let uniqMetricScores = _.uniq(_.values(METRIC_SCORE_MAPPING)).sort((a, b) => a - b);
        const searchedMembers = allUsers.filter(({ name, uid }) => name.toLocaleLowerCase()?.indexOf(memberSearch.toLocaleLowerCase()) > -1);
        return (
            <div className="manual-container">
                <div className="card-columns" style={{ columnCount: '1' }}>
                    <div className={!isMobile ? 'd-flex align-items-center mb-5' : ''}>
                        <div className={`calendar-key-container ${!isMobile ? '' : ''}`}>
                            {cellType === 'metric' ? (
                                <>
                                    <span className="calendar-key-label">terrible</span>
                                    {uniqMetricScores.map((value, i) => (
                                        <div
                                            key={`calendar-key-${i}`}
                                            className="calendar-key-color"
                                            style={{
                                                backgroundColor: interpolateColor(
                                                    MAX_QUALITY_CELL_COLOR,
                                                    MIN_QUALITY_CELL_COLOR,
                                                    1 - value / 5
                                                ),
                                            }}
                                        ></div>
                                    ))}
                                    <span className="calendar-key-label">excellent</span>
                                </>
                            ) : (
                                <>
                                    <span className="calendar-key-label">{`<20%`}</span>
                                    {[1, 2, 3, 4, 5].map((value, i) => (
                                        <div
                                            key={`calendar-key-${i}`}
                                            className="calendar-key-color"
                                            style={{
                                                backgroundColor: interpolateColor(
                                                    MAX_ACTION_CELL_COLOR,
                                                    MIN_ACTION_CELL_COLOR,
                                                    1 - value / 5
                                                ),
                                            }}
                                        ></div>
                                    ))}
                                    <span className="calendar-key-label">{`>80%`}</span>
                                </>
                            )}
                        </div>
                        <div className={!isMobile ? 'd-flex ms-5' : 'd-flex mb-2 flex-wrap'}>
                            <Dropdown drop="right" className=" " autoClose="outside">
                                <Dropdown.Toggle className={`btn btn-sm me-2 ${!isMobile ? '' : 'mt-2'} toggle-button`} style={{ color: '#475569' }}>
                                    {`QUALITIES ${qualityFilter.length ? '*' : ''}`}
                                    <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item testid='all-metrics' onClick={() => this.setState({ qualityFilter: [], cellType: 'metric', habitFilter: [] })}>
                                        All Qualities
                                    </Dropdown.Item>
                                    {_.sortBy(metricNames.filter(m => !m.isNumericMetric).map(m => ({ ...m, displayName: m.displayName.toLocaleLowerCase() })), 'displayName').map(({ metricName, displayName }, i) => (
                                        <Dropdown.Item
                                            key={`metric-item-${metricName}`}
                                            testid={`metric-item-${metricName}`}
                                            onClick={() => this._toggleQualityFilter(metricName)}
                                        >
                                            <img
                                                className="image-icon me-4"
                                                src={qualityFilter.includes(metricName) ? checkboxCheck : checkbox}
                                                alt="check icon"
                                            />
                                            {displayName}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown drop="right" className="" autoClose="outside">
                                <Dropdown.Toggle className={`btn btn-sm me-2 ${!isMobile ? '' : 'mt-2'} toggle-button`} style={{ color: '#475569' }}>
                                    {`ACTIONS ${habitFilter.length ? '*' : ''}`}
                                    <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item testid='all-habits' onClick={() => this.setState({ habitFilter: [], cellType: 'action', qualityFilter: [] })}>
                                        All Actions
                                    </Dropdown.Item>
                                    {_.sortBy(habitNames.map(h => ({ ...h, displayName: h.displayName.toLocaleLowerCase() })), 'displayName').map(({ habitName, displayName }, i) => (
                                        <Dropdown.Item
                                            key={`action-items-${habitName}`}
                                            testid={`action-items-${habitName}`}
                                            onClick={() => this._togglehabitFilter(habitName)}
                                        >
                                            <img
                                                className="image-icon me-4"
                                                src={habitFilter.includes(habitName) ? checkboxCheck : checkbox}
                                                alt="check icon"
                                            />
                                            {displayName}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown drop="right" className=" " autoClose="outside">
                                <Dropdown.Toggle className={`btn btn-sm ${!isMobile ? '' : 'mt-2'} toggle-button`} style={{ color: '#475569' }}>
                                    {memberFilter.length ? 'MEMBERS *' : 'MEMBERS'}
                                    <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Header>
                                        <div className="search-uid">
                                            <img className="image-icon " src={searchIcon} alt="search icon" />

                                            <input
                                                testid="search-user"
                                                onChange={(e) => this.setState({ memberSearch: e.target.value })}
                                                type="text"
                                                placeholder="Search"
                                                className="form-input"
                                                aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                            />
                                        </div>
                                    </Dropdown.Header>
                                    <Dropdown.Item testid='all-members' onClick={() => this.setState({ memberFilter: [] })}>
                                        All members{' '}
                                    </Dropdown.Item>
                                    {searchedMembers.map(({ uid, name }, i) => (
                                        <Dropdown.Item testid={`member-${uid}`} key={`member-${uid}`} onClick={() => this._toggleMemberFilter(uid)}>
                                            <img
                                                className="image-icon me-4"
                                                src={memberFilter.includes(uid) ? checkboxCheck : checkbox}
                                                alt="check icon"
                                            />
                                            {name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {!habitLogsInitialized || !metricLogsInitialized ? (
                        getLoadingIndicator()
                    ) : (
                        <div className="row card-deck g-3">
                            {this.getCalendarMonths(lastDate, dateScoreMap)}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, content: { currentUsers, metricLogs, habitLogs, chosenProtocolsMapping, metricLogsInitialized, habitLogsInitialized } }) => ({
    auth,
    metricLogs,
    metricLogsInitialized,
    habitLogsInitialized,
    habitLogs,
    chosenProtocolsMapping,
    currentUsers,
});

const connectedComponent: ComponentType<{}> = connect(mapStateToProps, {
    fetchUsers,
    fetchMetricLogsForPastMonth,
    fetchAdherenceLogsForPastMonth,
    fetchMetricLogsForYear,
    fetchHabitLogsForYear,
    setFilteredTimeFrame,
    setNumericFilters,
    setOtherFilters,
})(Calendar);

export default connectedComponent;
