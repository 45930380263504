import { isAlpha } from "../appConfig";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const sentryInitObject = {
    dsn: "https://339089730b9e4fc99044010526548e39@o613370.ingest.sentry.io/5748934",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    },
};

export const initializeSentry = () => {
    if (!isAlpha()) {
        Sentry.init(sentryInitObject);
    }
}