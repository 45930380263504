// @flow

import 'bootstrap/dist/css/bootstrap.css';
import { padStart } from 'lodash';

import moment from 'moment';

import * as React from 'react';

import type { ComponentType } from 'react';
import type { TableCellProps } from '../../../flowTypes';

type CalendarProps = {
    year: string,
    month: string,
    RowEelement: ComponentType<{}>,
    CellElement: ComponentType<TableCellProps>,
    HeaderCellElement: ComponentType<{}>,
    HeaderRowElement: ComponentType<{}>,
    datesData: { [date: string]: {} },
    datesScore: { [date: string]: number },
    showHeader: boolean,
    reverseDates: boolean,
    onClickDate: (date: string, dateInfo: any) => void,
    type: "metric" | "action"
};

export class CalendarMonth extends React.Component<CalendarProps> {
    getDatesReverse(): any {
        const { year, month, RowEelement, CellElement, datesData, datesScore, onClickDate, type ="metric" } = this.props;
        const date = moment(`${year}/${month}`, 'YYYY/MM');
        const currentDate = moment();
        const currentMonth = parseInt(currentDate.month()) + 1;
        const daysInMonth = currentMonth === parseInt(month) ? currentDate.date() : date.daysInMonth();
        const weekDayOfLastDay = moment(`${year}/${month}/${daysInMonth}`, 'YYYY/MM/DD').day();
        let iterDate = daysInMonth;
        let calendarRows = [];

        for (let row = 0; row < 6 && iterDate > 0; row++) {
            let calendarRow = [];

            for (let column = 6; column >= 0; column--) {
                const dateString = `${month}/${padStart(iterDate + '', 2, '0')}/${year}`;
                const data = datesScore[dateString];

                if (iterDate < 1) {
                    break;
                }
                // fill empty top cells
                if (row === 0 && column > weekDayOfLastDay) {
                    calendarRow.unshift(<CellElement key={`emptyday-${column}}`} children="" />);
                } else {
                    calendarRow.unshift(
                        <CellElement
                            type = {type}
                            testid="calendar-cell"
                            specificId={`calendar-cell-${iterDate}`}
                            key={`date-${iterDate}`}
                            children={<div>{iterDate}</div>}
                            data={data}
                            onClick={() => onClickDate(dateString, datesData)}
                        />
                    );
                    iterDate--;
                }
            }
            // fill empty bottom cells
            while (calendarRow.length < 7) {
                calendarRow.unshift(<CellElement key={`emptyfill-${calendarRow.length}`} children="" />);
            }
            calendarRows.push(<RowEelement key={`table-row-${calendarRows.length}`} children={calendarRow} />);
        }

        return calendarRows;
    }

    getDates(): any {
        const { year, month, RowEelement, CellElement, datesData, datesScore, onClickDate,  type="metric" } = this.props;
        const date = moment(`${year}/${month}`, 'YYYY/MM');
        const currentDate = moment();
        const currentMonth = parseInt(currentDate.month()) + 1;
        const daysInMonth = currentMonth === parseInt(month) ? currentDate.date() : date.daysInMonth();
        const weekdayOfFirstDay = moment(`${year}/${month}/01`, 'YYYY/MM/DD').day();
        let iterDate = 1;
        let calendarRows = [];

        for (let row = 0; row < 6 && iterDate <= daysInMonth; row++) {
            let calendarRow = [];

            for (let column = 0; column < 7; column++) {
                const dateString = `${month}/${padStart(iterDate + '', 2, '0')}/${year}`;
                const data = datesScore[dateString];

                if (iterDate > daysInMonth) {
                    break;
                }
                // fill empty top cells
                if (row === 0 && column < weekdayOfFirstDay) {
                    calendarRow.push(<CellElement key={`emptyday-${column}}`} children="" />);
                } else {
                    calendarRow.push(
                        <CellElement
                            testid="calendar-cell"
                            specificId={`calendar-cell-${iterDate}`}
                            key={`date-${iterDate}`}
                            children={<div>{iterDate}</div>}
                            type={type}
                            data={data}
                            onClick={() => onClickDate(dateString, datesData)}
                        />
                    );
                    iterDate++;
                }
            }
            // fill empty bottom cells
            while (calendarRow.length < 7) {
                calendarRow.push(<CellElement key={`emptyfill-${calendarRow.length}`} children="" />);
            }
            calendarRows.push(<RowEelement key={`table-row-${calendarRows.length}`} children={calendarRow} />);
        }

        return calendarRows;
    }

    render(): any {
        const { showHeader, HeaderCellElement, HeaderRowElement, month, year, reverseDates = false } = this.props;

        return (
            <table className = "table calendar">
                <thead>
                    {showHeader ? (
                        <HeaderRowElement testid="calendar-header">
                            {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((day, i) => (
                                <HeaderCellElement key={`header-${month}-${year}-${i}`} children={day} />
                            ))}
                        </HeaderRowElement>
                    ) : null}
                </thead>
                <tbody>{reverseDates ? this.getDatesReverse() : this.getDates()}</tbody>
            </table>
        );
    }
}
