export const UPDATE_TIME = 'UPDATE_TIME';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const SET_PROFILE_PIC_ID = 'SET_PROFILE_PIC_ID';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const OPEN_BRIGADE = 'OPEN_BRIGADE';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_PROTOCOLS = 'UPDATE_PROTOCOLS';
export const UPDATE_THIS_MONTH_METRIC_LOGS = 'UPDATE_THIS_MONTH_METRIC_LOGS';
export const UPDATE_THIS_MONTH_HABIT_LOGS = 'UPDATE_THIS_MONTH_HABIT_LOGS';
export const UPDATE_USERS_CHOSEN_PROTOCOLS = 'UPDATE_USERS_CHOSEN_PROTOCOLS';
export const UPDATE_CHOSEN_LEVEL_FOR_PROTOCOL = 'UPDATE_CHOSEN_LEVEL_FOR_PROTOCOL';
export const UPDATE_STATS_FILTERED_TIMEFRAME = 'UPDATE_STATS_FILTERED_TIMEFRAME';
export const UPDATE_STATS_NUMERIC_FILTERS = 'UPDATE_STATS_NUMERIC_FILTERS';
export const UPDATE_STATS_OTHER_FILTERS = 'UPDATE_STATS_OTHER_FILTERS';
export const UPDATE_STATS_QUALITATIVE_METRIC_FILTERS = 'UPDATE_STATS_QUALITATIVE_METRIC_FILTERS';
export const UPDATE_USERS_LEVELS_INFO = 'UPDATE_USERS_LEVELS_INFO';
export const UPDATE_NETWORK_DEACTIVATED = 'UPDATE_NETWORK_DEACTIVATED';
export const UPDATE_SENT_NOTIFICATIONS = 'UPDATE_SENT_NOTIFICATIONS';
export const UPDATE_NETWORK_POSTS = 'UPDATE_NETWORK_POSTS';
export const UPDATE_CLOSED_NETWORK = 'UPDATE_CLOSED_NETWORK';
export const UPDATE_SECRET_KEY = 'UPDATE_SECRET_KEY';
export const UPDATE_METRIC_LOGS = 'UPDATE_METRIC_LOGS';
export const UPDATE_HABIT_LOGS = 'UPDATE_HABIT_LOGS';
export const ASSIGN_PROTOCOL_AND_LEVEL_TO_USER = 'ASSIGN_PROTOCOL_AND_LEVEL_TO_USER';
export const UPDATE_MODERATORS = 'UPDATE_MODERATORS';
/* export const CREATE_STREAM = 'CREATE_STREAM';
export const FETCH_STREAM = 'FETCH_STREAM';
export const FETCH_STREAMS = 'FETCH_STREAMS';
export const EDIT_STREAM = 'EDIT_STREAM';
export const DELETE_STREAM = 'DELETE_STREAM'; */