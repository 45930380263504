// @flow

import { LOGIN, LOGOUT, UPDATE_MODERATORS, UPDATE_NETWORK_DEACTIVATED, UPDATE_SECRET_KEY } from '../actions/types';

import type { AuthObject } from '../flowTypes';

const INITIAL_STATE: AuthObject = {
    isLoggedIn: false,
    username: '',
    name: '',
    networkKey: '',
    deactivated: true,
    password: '',
    uid: '',
    isModerator: false,
    secretKey: '',
    moderators: {},
    moderatorsInitialized: false,
};

export default (state: AuthObject = INITIAL_STATE, action: any): AuthObject => {
    switch (action.type) {
        case LOGIN:
            const {
                username,
                name,
                networkKey,
                deactivated,
                password,
                uid,
                isModerator,
            } = action.payload || {};
            return {
                ...state,
                isLoggedIn: Boolean(username),
                username,
                name,
                networkKey,
                deactivated,
                password,
                uid,
                isModerator
            };
        case UPDATE_MODERATORS:
            return { ...state, moderators: action.payload, moderatorsInitialized: true };
        case UPDATE_SECRET_KEY:
            return { ...state, secretKey: action.payload };
        case LOGOUT:
            return { ...state, isLoggedIn: false, username: '', name: '' };
        case UPDATE_NETWORK_DEACTIVATED:
            return { ...state, deactivated: action.payload };
        default:
            return state;
    }
};