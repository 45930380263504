
import {combineReducers} from 'redux';
import authReducer from './authReducer';
import contentReducer from './contentReducer';
import utilitiesReducer from './utilitiesReducer';

export default combineReducers({
    auth: authReducer,
    content: contentReducer,
    utilities: utilitiesReducer,
});