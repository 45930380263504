// @flow

import React from 'react'
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
// import moment from 'moment';
import protocolIcon from '../../../assets/svgs/protocol-blue.svg';
import menuIcon from '../../../assets/svgs/menu.svg';
import questionIcon from '../../../assets/svgs/question.svg';
import assignIcon from '../../../assets/svgs/arrow-up-right.svg';
import previewIcon from '../../../assets/svgs/eye-blue.svg';
import editIcon from '../../../assets/svgs/edit.svg';
import duplicateIcon from '../../../assets/svgs/duplicate.svg';
import trashIcon from '../../../assets/svgs/trash.svg';
import infoIcon from '../../../assets/svgs/info.svg';




import type { ComponentType } from 'react';
import type { Protocol } from '../../../flowTypes';
import _ from 'lodash';

type ProtocolCardProps = {
    protocol: Protocol,
    isSelected: boolean,
    onSelect: () => void,
    onProtocolAssign: () => void,
    onProtocolEdit: (id: string, levels: Protocol[], name: string) => void,
    onProtocolPreview: (id: string, levels: Protocol[], name: string) => void,
    onProtocolDuplicate: (id: string, name: string) => void,
    onProtocolDelete: (id: string) => void,
    makeProtocolPrivate: (id: string, value: boolean) => void,
    protocolEditor?: string,
};

export const ProtocolCard: ComponentType<ProtocolCardProps> = ({ protocol, protocolEditor = '', makeProtocolPrivate = _.noop, onProtocolPreview = _.noop, onProtocolDuplicate = _.noop, onProtocolDelete = _.noop, onProtocolAssign = _.noop, onProtocolEdit = _.noop, isSelected = false, onSelect = _.noop }) => {
    const { name, id, isAPrivateNetworkProtocol, /* dateTimestamp, */ levels = [] } = protocol || {};
    const getPopover = ({ title, description }: { title: string, description: string }): any => {
        return (
            <Popover>
                <Popover.Body className="tooltip-body">
                    <div className="tooltip-head">
                        <img className="image-icon" src={infoIcon} alt="info icon" />
                        <p className="tooltip-title">{title}</p>
                    </div>
                    <p className="tooltip-description">{description}</p>
                </Popover.Body>
            </Popover>
        );
    };

    return (
        <div className="protocol-card-container">
            <div testid="select-button" onClick={onSelect} className={`top-section ${isSelected ? 'active' : ''}`}>
                <div className="protocol-head">
                    <div className={`circle-icon ${isSelected ? 'active' : ''}`}>
                        <img className="image-icon" src={protocolIcon} alt="protocol icon" />
                    </div>
                    <div className="protocol-info">
                        <p className="protocol-name">{name}</p>
                        {/* <p className="protocol-date">Date Created: {dateTimestamp ? moment(dateTimestamp).format('MMMM D, YYYY') : ""}</p> */}
                    </div>
                </div>
                <Dropdown>
                    <Dropdown.Toggle id={`dropdown-${id}`} testid="menu-button" className="btn menu-button align-self-start">
                        <img className="image-icon" src={menuIcon} alt="menu-icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item testid="edit-button" disabled={Boolean(protocolEditor)} onClick={() => onProtocolEdit(id, levels, name)} className="d-flex align-item-center justify-content-between">
                            <span>Edit</span>
                            <img className="image-icon" alt="menu-icon" src={editIcon} />
                        </Dropdown.Item>
                        <Dropdown.Item testid="duplicate-button" onClick={() => onProtocolDuplicate(id, name)} className="d-flex align-item-center justify-content-between">
                            <span>Duplicate</span>
                            <img className="image-icon" alt="menu-icon" src={duplicateIcon} />
                        </Dropdown.Item>
                        <Dropdown.Item testid="delete-button" disabled={Boolean(protocolEditor)} onClick={() => onProtocolDelete(id)} className="d-flex align-item-center justify-content-between">
                            <span>Delete</span>
                            <img className="image-icon" alt="menu-icon" src={trashIcon} />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={'bottom-section'}>
                <div className="privacy">
                    <span>{"Public"}</span>
                    <OverlayTrigger
                        overlay={getPopover({
                            title: 'Public Protocol',
                            description: "Everyone in your network can use this protocol.",
                        })}
                        testid="activity-tooltip"
                    >
                        <div className="table-head-icon" style={{ marginLeft: 5 }}>
                            <img
                                className="extra-small-icon icon-left"
                                src={questionIcon}
                                alt="tooltip icon"
                            />
                        </div>
                    </OverlayTrigger>
                    <div className="app-toggle-container">
                        <input testid="private-input" id={`input-check-${id}`} checked={!isAPrivateNetworkProtocol} onChange={(e) => makeProtocolPrivate(id, !isAPrivateNetworkProtocol)} type="checkbox" />
                        <label htmlFor={`input-check-${id}`}></label>
                    </div>
                </div>
                <button testid="preview-button" className="btn app-btn-outline" onClick={() => onProtocolPreview(id, levels, name)}>
                    <img src={previewIcon} className="image-icon" alt="preview icon" />
                    Preview
                </button>
                <button testid="assign-button" onClick={() => onProtocolAssign()} className="btn app-btn-secondary">
                    Assign Protocol
                    <img src={assignIcon} className="image-icon" alt="assign icon" />
                </button>
            </div>
            <div className='editing-text-container'>
                {Boolean(protocolEditor) && <text className='editing-text'><b>{`${protocolEditor} is editing...`}</b></text>}
            </div>
        </div>
    );
};
