import React from 'react';
import { HabitForm } from './HabitForm';
import { MetricForm } from './MetricForm';
import { RemoveUserModal } from '../Users/RemoveUserModal';
import { ProtocolNameForm } from './ProtocolNameForm';
import { categorizeQualities, getAllIndexes } from '../../UI Resources';
import { HABIT_CATEGORIES } from '../../../Constants';
import { setProtocolsForNetwork } from '../../../actions';
import { connect } from 'react-redux';
import type { Protocol, Habit, Metric, ContentObject, AuthObject } from '../../../flowTypes';

import Modal from '@material-ui/core/Modal';
import protocolIcon from '../../../assets/svgs/protocol-blue.svg';
import plusIcon from '../../../assets/svgs/circle-plus-blue.svg';
import minustIcon from '../../../assets/svgs/circle-minus-blue.svg';
import editIcon from '../../../assets/svgs/edit-blue.svg';
import removeIcon from '../../../assets/svgs/circle-cancel-blue.svg';
import _ from 'lodash';

type Props = {
    protocolLevels: Protocol[],
    onDone: () => void,
    setProtocolsForNetwork: (protocols: Protocol[], networkKey: string) => void;
    content: ContentObject,
    auth: AuthObject,
    mode: 'edit' | 'add',
    protocolId: string | undefined,
    protocolName: string | undefined
};

type State = {
    habitInfo: Habit,
    currentLevel: number,
    qualityInfo: Metric,
    protocolLevels: [],
    showLevelModal: boolean,
    showMetricsModal: boolean,
    showHabitsModal: boolean,
    showRemoveLevelModal: boolean,
    showProtocolNameModal: boolean,
};

export class ProtocolForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { protocolLevels } = props;
        this.state = {
            habitInfo: {},
            qualityInfo: {},
            currentLevel: 0,
            protocolLevels: protocolLevels || [],
            showLevelModal: false,
            showMetricsModal: false,
            showHabitsModal: false,
            showRemoveLevelModal: false,
            showProtocolNameModal: false,
        };
    }

    removeLevel() {
        const { currentLevel, protocolLevels } = this.state;
        let newlevels = _.cloneDeep(protocolLevels);
        newlevels.splice(currentLevel, 1);
        for (const l of newlevels) {
            if (l.level > currentLevel) {
                l.level = l.level - 1;
            }
        }
        this.setState({
            protocolLevels: newlevels,
            currentLevel: currentLevel >= newlevels.length ? newlevels.length - 1 : currentLevel,
            showRemoveLevelModal: false
        });
    }

    metricPrompt(mode: 'add' | 'edit', id) {
        switch (mode) {
            case 'add':
                this.setState({ qualityInfo: {}, showMetricsModal: true });
                break;
            case 'edit':
                const days = [1, 2, 3, 4, 5, 6, 7];
                const { currentLevel, protocolLevels } = this.state;
                const quality = protocolLevels[currentLevel].metrics[id];
                const daysToInclude = days.filter((day) => !quality?.daysToExcludeInPrompting?.includes(day));
                this.setState({
                    qualityInfo: {
                        ...quality,
                        daysToExcludeInPrompting: daysToInclude.length === 7 ? [] : daysToInclude,
                        id,
                    },
                    showMetricsModal: true,
                });
                break;
            default:
                break;
        }
    }

    onSaveProtocol = async (name: string) => {
        const { content, auth, setProtocolsForNetwork, onDone = () => true, mode, protocolId } = this.props;
        const { protocolLevels } = this.state
        const networkKey = auth.networkKey;
        const protocols = content.protocols;
        const id = name.toLocaleLowerCase().split(" ").join("-");
        switch (mode) {
            case 'add':
                protocolLevels.forEach((protocol, i) => {
                    protocols.unshift({ ...protocol, name: name, id, level: i, isAPrivateNetworkProtocol: false, isAUserCreatedProtocol: false, isADeactivatedNetworkProtocol: false, isANetworkProtocol: true })
                });
                setProtocolsForNetwork(protocols, networkKey, id);
                onDone();
                break;
            case 'edit':
                const protocolIds = protocols.map(({ id }) => id);
                const indexes = getAllIndexes(protocolIds, protocolId);
                if (indexes.length) {
                    const protocolsToAdd = [];
                    protocolLevels.forEach((protocol, i) => {
                        protocolsToAdd.push({ ...protocol, name: name, id, level: i })
                    });
                    indexes.forEach((index, i) => {
                        if (i === 0) {
                            protocols.splice(index, 1, ...protocolsToAdd);
                        }
                        else {
                            protocols.splice(index + (protocolsToAdd.length - 1), 1);
                        }
                    })

                    setProtocolsForNetwork(protocols, networkKey, id);
                    onDone();
                }
                break;
            default:
                break;
        }
    }

    habitPrompt(mode: 'add' | 'edit', id) {
        switch (mode) {
            case 'add':
                this.setState({ habitInfo: {}, showHabitsModal: true });
                break;
            case 'edit':
                const days = [1, 2, 3, 4, 5, 6, 7];
                const { currentLevel, protocolLevels } = this.state;
                const habit = protocolLevels[currentLevel].habits[id];
                const daysToInclude = days.filter((day) => habit.daysToExcludeInPrompting && !habit?.daysToExcludeInPrompting?.includes(day));
                this.setState({
                    habitInfo: {
                        ...habit,
                        daysToExcludeInPrompting: daysToInclude.length === 7 ? [] : daysToInclude,
                        id,
                    },
                    showHabitsModal: true,
                });
                break;
            default:
                break;
        }
    }

    removeMetric = (id) => {
        const { protocolLevels, currentLevel } = this.state;
        const newProtocol = _.cloneDeep(protocolLevels[currentLevel]);
        newProtocol.metrics.splice(id, 1);
        protocolLevels[currentLevel] = newProtocol;
        this.setState({ protocolLevels: protocolLevels });
    };
    removeHabit = (id) => {
        const { protocolLevels, currentLevel } = this.state;
        const newProtocol = _.cloneDeep(protocolLevels[currentLevel]);
        newProtocol.habits.splice(id, 1);
        protocolLevels[currentLevel] = newProtocol;
        this.setState({ protocolLevels: protocolLevels });
    };

    addNewLevel = () => {
        const { protocolLevels, currentLevel } = this.state;
        const newProtocol = _.cloneDeep(protocolLevels[currentLevel]);
        protocolLevels.splice(currentLevel, 0, { ...newProtocol, level: currentLevel + 1 });
        this.setState({
            protocolLevels: protocolLevels,
            currentLevel: currentLevel + 1,
            showLevelModal: false,
        });
    };

    saveMetric = (metric: Metric, oldMetricName?: string) => {
        const { currentLevel, protocolLevels } = this.state;
        const currentProtocol = protocolLevels[currentLevel];

        currentProtocol.metrics = [...((currentProtocol.metrics || []).filter(m => m.name !== metric.name && m.name !== oldMetricName)), metric];
        protocolLevels[currentLevel] = currentProtocol;

        this.setState({ protocolLevels: protocolLevels, showMetricsModal: false });
    };

    saveHabit = (habit: Habit, oldHabitName?: string) => {
        const { currentLevel, protocolLevels } = this.state;
        const currentProtocol = protocolLevels[currentLevel];

        currentProtocol.habits = [...((currentProtocol.habits || []).filter(h => h.name !== habit.name && h.name !== oldHabitName)), habit];
        protocolLevels[currentLevel] = currentProtocol;

        this.setState({ protocolLevels: protocolLevels, showHabitsModal: false });
    };

    render() {
        const {
            protocolLevels,
            currentLevel,
            showLevelModal,
            showProtocolNameModal,
            showMetricsModal,
            showRemoveLevelModal,
            showHabitsModal,
            habitInfo,
            qualityInfo,
        } = this.state;
        const { onDone, protocolName } = this.props;
        const currentProtocol = protocolLevels[currentLevel] || {};
        const { numerics, nonNumerics } = categorizeQualities(currentProtocol.metrics || []);
        const actionCategories = _.groupBy(
            (currentProtocol.habits || []).map((habit, id) => ({ ...habit, id, type: habit.type.toLocaleLowerCase() })),
            'type'
        );


        return (
            <>
                {showProtocolNameModal ? (
                    <Modal
                        testid="name-modal"
                        open={showProtocolNameModal}
                        onClose={() => this.setState({ showProtocolNameModal: false })}
                        style={{ display: 'flex' }}
                        BackdropProps={{ style: { opacity: .25 } }}
                    >
                        <>
                            <ProtocolNameForm
                                defaultName={protocolName}
                                saveProtocol={(name) => this.onSaveProtocol(name)}
                                onDone={() => this.setState({ showProtocolNameModal: false })}
                            />
                        </>
                    </Modal>
                ) : (
                    <div className="modal-container modal-md">
                        <div className="modal-head">
                            <div className="modal-icon-container">
                                <img src={protocolIcon} className="modal-icon" alt="modal icon" />
                            </div>
                            <p testid="modal-title" className="modal-title">
                                Create Protocol
                            </p>
                        </div>
                        <div className="">
                            <p className="modal-description">Add qualities and actions to create a protocol.</p>
                            <div className="modal-bd modal-bd-short">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="section-level d-flex">
                                        {Boolean(protocolLevels.length - 1)
                                            && _.take(protocolLevels, currentLevel).map((level, index) => (
                                                <div
                                                    key={`level-button-${index}`}
                                                    testid={`level-button-${index}`}
                                                    onClick={() => this.setState({ currentLevel: index })}
                                                    className={`level-btn`}
                                                >
                                                    {index + 1}
                                                </div>
                                            ))}
                                        {protocolLevels.length > 1 ? (
                                            <button
                                                testid="remove-level-button"
                                                className="btn btn-sm btn-ghost"
                                                onClick={() => this.setState({ showRemoveLevelModal: true })}
                                            >
                                                <img className="image-icon button-icon" alt="plus" src={minustIcon} />
                                            </button>
                                        ) : null}
                                        <div className="level-btn-current">{`Level ${currentLevel + 1}`}</div>
                                        {Boolean(protocolLevels.length <= 8) && (
                                            <button
                                                testid="level-button"
                                                className="btn btn-sm btn-ghost"
                                                onClick={() => this.setState({ showLevelModal: true })}
                                            >
                                                <img className="image-icon button-icon" alt="plus" src={plusIcon} />
                                            </button>
                                        )}
                                        {Boolean(protocolLevels.length - 1) &&
                                            _.takeRight(protocolLevels, protocolLevels.length - 1 - currentLevel).map((level, index) => (
                                                <div
                                                    key={`right-level-button-${index}`}
                                                    testid={`right-level-button-${index}`}
                                                    onClick={() => this.setState({ currentLevel: index + currentLevel + 1 })}
                                                    className={`level-btn`}
                                                >
                                                    {index + currentLevel + 2}
                                                </div>
                                            ))}
                                    </div>
                                    <button
                                        testid="quality-button"
                                        className="btn btn-block btn-lg app-btn-outline mt-3"
                                        onClick={() => this.metricPrompt('add')}
                                    >
                                        <img src={plusIcon} alt="addicon" className="image-icon button-icon" />
                                        {'Quality - What to improve'}
                                    </button>
                                    {nonNumerics.length ? (
                                        <div className="qualities-list">
                                            <p className="quality-header">Slider</p>
                                            {nonNumerics.map(({ displayName, id }, i) => (
                                                <div className="metric-row" key={`numeric-quality-${i}`}>
                                                    <div className="highlight">{displayName}</div>
                                                    <button
                                                        className="btn btn-sm btn-ghost"
                                                        onClick={() => this.metricPrompt('edit', id)}
                                                        testid={`edit-nonNumeric-${id}`}
                                                    >
                                                        <img
                                                            className="image-icon smaller-button-icon"
                                                            alt="editIcon"
                                                            src={editIcon}
                                                        />
                                                    </button>
                                                    <button
                                                        testid={`remove-nonNumeric-${id}`}
                                                        className="btn btn-sm btn-ghost mx-2"
                                                        onClick={() => this.removeMetric(id)}
                                                    >
                                                        <img
                                                            className="image-icon button-icon"
                                                            alt="remove icon"
                                                            src={removeIcon}
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                    {numerics.length ? (
                                        <div className="qualities-list">
                                            <p className="quality-header">Number</p>
                                            {numerics.map(({ displayName, id }, i) => (
                                                <div className="metric-row" key={`numeric-quality-${i}`}>
                                                    <div className="highlight">{displayName}</div>
                                                    <button
                                                        testid={`edit-quality-button-${id}`}
                                                        className="btn btn-sm btn-ghost"
                                                        onClick={() => this.metricPrompt('edit', id)}
                                                    >
                                                        <img
                                                            className="image-icon smaller-button-icon"
                                                            alt="editIcon"
                                                            src={editIcon}
                                                        />
                                                    </button>
                                                    <button
                                                        testid={`remove-quality-button-${id}`}
                                                        className="btn btn-sm btn-ghost mx-2"
                                                        onClick={() => this.removeMetric(id)}
                                                    >
                                                        <img
                                                            className="image-icon button-icon"
                                                            alt="remove icon"
                                                            src={removeIcon}
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}

                                    <button
                                        testid="action-button"
                                        className="btn btn-block btn-lg app-btn-outline mt-3"
                                        onClick={() => this.habitPrompt('add')}
                                    >
                                        <img src={plusIcon} alt="addicon" className="image-icon button-icon" />
                                        {'Action - How to improve'}
                                    </button>
                                    {HABIT_CATEGORIES.map((category) =>
                                        actionCategories[category] ? (
                                            <div className="qualities-list" key={category}>
                                                <p className="quality-header">{_.capitalize(category)}</p>
                                                {actionCategories[category].map(({ displayName, id, context }, i) => (
                                                    <div className="metric-row" key={`numeric-quality-${i}`}>
                                                        <div className="highlight">{context ? `${context}, ` : ''}{displayName}</div>
                                                        <button
                                                            testid={`edit-button-${id}`}
                                                            className="btn btn-sm btn-ghost"
                                                            onClick={() => this.habitPrompt('edit', id)}
                                                        >
                                                            <img
                                                                className="image-icon smaller-button-icon"
                                                                alt="editIcon"
                                                                src={editIcon}
                                                            />
                                                        </button>
                                                        <button
                                                            testid={`remove-button-${id}`}
                                                            className="btn btn-sm btn-ghost mx-2"
                                                            onClick={() => this.removeHabit(id)}
                                                        >
                                                            <img
                                                                className="image-icon button-icon"
                                                                alt="remove icon"
                                                                src={removeIcon}
                                                            />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center mt-4">
                                <button
                                    disabled={
                                        !!!currentProtocol?.metrics?.length || !!!currentProtocol?.habits?.length
                                    }
                                    testid="submit-button"
                                    className="btn btn-block btn-lg app-btn-primary mt-3"
                                    onClick={() => this.setState({ showProtocolNameModal: true })}
                                >
                                    {'Next'}
                                </button>
                                <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                    Cancel
                                </button>
                            </div>
                            {showLevelModal ? (
                                <Modal
                                    testid="level-modal"
                                    open={showLevelModal}
                                    onClose={() => this.setState({ showLevelModal: false })}
                                    style={{ display: 'flex' }}
                                >
                                    <>
                                        <RemoveUserModal
                                            onDone={() => this.setState({ showLevelModal: false })}
                                            removeUser={() => {
                                                this.addNewLevel();
                                            }}
                                            positive={true}
                                            removeIcon={plusIcon}
                                            title="Add Level"
                                            description="You are about to add a level. Components are copied from the last level for easier editing."
                                        />
                                    </>
                                </Modal>
                            ) : null}
                            {showRemoveLevelModal ? (
                                <Modal
                                    testid="remove-modal"
                                    open={showRemoveLevelModal}
                                    onClose={() => this.setState({ showRemoveLevelModal: false })}
                                    style={{ display: 'flex' }}
                                >
                                    <>
                                        <RemoveUserModal
                                            onDone={() => this.setState({ showRemoveLevelModal: false })}
                                            removeUser={() => {
                                                this.removeLevel();
                                            }}
                                            removeIcon={minustIcon}
                                            positive={false}
                                            title="Delete Level"
                                            description="You are about to delete a level. If you do, it can’t be recovered. Are you sure?"
                                        />
                                    </>
                                </Modal>
                            ) : null}
                            {showMetricsModal ? (
                                <Modal
                                    testid="quality-modal"
                                    open={showMetricsModal}
                                    onClose={() => this.setState({ showMetricsModal: false })}
                                    style={{ display: 'flex' }}
                                    hideBackdrop={true}
                                >
                                    <>
                                        <MetricForm
                                            metric={qualityInfo}
                                            onDone={() => this.setState({ showMetricsModal: false })}
                                            addMetric={this.saveMetric}
                                            level={currentLevel}
                                        />
                                    </>
                                </Modal>
                            ) : null}
                            {showHabitsModal ? (
                                <Modal
                                    testid="action-modal"
                                    open={showHabitsModal}
                                    onClose={() => this.setState({ showHabitsModal: false })}
                                    style={{ display: 'flex' }}
                                    hideBackdrop={true}
                                >
                                    <>
                                        <HabitForm
                                            habit={habitInfo}
                                            onDone={() => this.setState({ showHabitsModal: false })}
                                            saveHabit={this.saveHabit}
                                            level={currentLevel}
                                        />
                                    </>
                                </Modal>
                            ) : null}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ auth, content }) => ({ auth, content });

const connectedProtocolForm = connect(mapStateToProps, {
    setProtocolsForNetwork,
})(ProtocolForm);

export default connectedProtocolForm;
