// @flow

import {
    LOGOUT,
    UPDATE_CHOSEN_LEVEL_FOR_PROTOCOL,
    UPDATE_CLOSED_NETWORK,
    UPDATE_NETWORK_POSTS,
    UPDATE_PROTOCOLS,
    UPDATE_SENT_NOTIFICATIONS,
    UPDATE_STATS_FILTERED_TIMEFRAME,
    UPDATE_STATS_NUMERIC_FILTERS,
    UPDATE_STATS_OTHER_FILTERS,
    UPDATE_STATS_QUALITATIVE_METRIC_FILTERS,
    UPDATE_THIS_MONTH_HABIT_LOGS,
    UPDATE_THIS_MONTH_METRIC_LOGS,
    UPDATE_USERS,
    UPDATE_USERS_CHOSEN_PROTOCOLS,
    UPDATE_USERS_LEVELS_INFO,
    UPDATE_METRIC_LOGS,
    UPDATE_HABIT_LOGS,
    ASSIGN_PROTOCOL_AND_LEVEL_TO_USER
} from '../actions/types';

import type { ContentObject } from '../flowTypes';


const INITIAL_STATE: ContentObject = {
    currentUsers: {},
    thisMonthMetricLogs: [],
    thisMonthMetricLogsInitialized: false,
    thisMonthHabitLogs: [],
    thisMonthHabitLogsInitialized: false,
    metricLogs: [],
    metricLogsInitialized: false,
    habitLogs: [],
    habitLogsInitialized: false,
    chosenProtocolsMapping: {},
    chosenLevelInfo: {},
    statsFilteredTimeFrame: 'month',
    statsNumericFilters: [],
    statsOtherFilters: [],
    statsQualitativeMetricFilters: [],
    usersLevelMapping: {},
    protocols: [],
    protocolsInitialized: false,
    sentNotifications: [],
    posts: [],
    isClosedNetwork: false,
};

export default (state: ContentObject = INITIAL_STATE, action: any): ContentObject => {
    switch (action.type) {
        case UPDATE_USERS:
            return { ...state, currentUsers: action.payload };
        case UPDATE_CLOSED_NETWORK:
            return { ...state, isClosedNetwork: action.payload };
        case UPDATE_PROTOCOLS:
            return { ...state, protocols: action.payload, protocolsInitialized: true };
        case UPDATE_THIS_MONTH_METRIC_LOGS:
            return { ...state, thisMonthMetricLogs: action.payload, thisMonthMetricLogsInitialized: true };
        case UPDATE_THIS_MONTH_HABIT_LOGS:
            return { ...state, thisMonthHabitLogs: action.payload, thisMonthHabitLogsInitialized: true };
        case UPDATE_USERS_CHOSEN_PROTOCOLS:
            return { ...state, chosenProtocolsMapping: { ...state.chosenProtocolsMapping, ...action.payload } }
        case UPDATE_CHOSEN_LEVEL_FOR_PROTOCOL:
            return { ...state, chosenLevelInfo: { ...state.chosenLevelInfo, [action.payload.protocol]: action.payload.level } }
        case ASSIGN_PROTOCOL_AND_LEVEL_TO_USER:
            return { ...state,  usersLevelMapping: { ...state.usersLevelMapping, ...action.payload.mapping }, chosenProtocolsMapping: { ...state.chosenProtocolsMapping, ...action.payload.chosenProtocolsMapping} }
        case UPDATE_STATS_FILTERED_TIMEFRAME:
            return { ...state, statsFilteredTimeFrame: action.payload };
        case UPDATE_STATS_NUMERIC_FILTERS:
            return { ...state, statsNumericFilters: action.payload };
        case UPDATE_STATS_OTHER_FILTERS:
            return { ...state, statsOtherFilters: action.payload };
        case UPDATE_STATS_QUALITATIVE_METRIC_FILTERS:
            return { ...state, statsQualitativeMetricFilters: action.payload };
        case UPDATE_USERS_LEVELS_INFO:
            return { ...state, usersLevelMapping: { ...state.usersLevelMapping, ...action.payload } };
        case UPDATE_SENT_NOTIFICATIONS:
            return { ...state, sentNotifications: action.payload };
        case UPDATE_NETWORK_POSTS:
            return { ...state, posts: action.payload };
        case UPDATE_METRIC_LOGS:
            return { ...state, metricLogs: action.payload, metricLogsInitialized: true };
        case    UPDATE_HABIT_LOGS:
            return { ...state, habitLogs: action.payload, habitLogsInitialized: true };
        case LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
};