// @flow

import 'bootstrap/dist/css/bootstrap.css';
import '../../../general.scss';
import '../../../styles/members.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React from 'react';
import 'firebase/storage';
import { CSVLink } from "react-csv";

import {
    fetchUsers,
    fetchMetricLogsForPastMonth,
    fetchProtocols,
    updateUsers,
    setNewUserLevelMapping,
    fetchSentNotifications,
    updateSentNotifications,
    setNewUserProtocol,
    fetchIsClosedNetwork,
    setClosedNetwork,
} from '../../../actions';
import { removeUserFromNetwork, setLevelForUser } from '../../../api/firebase';
import _ from 'lodash';
import { decodeUserEmail, encodeUserEmail, convertProtocolIDToName, formatPhoneNumber } from '../../UI Resources';

import type { ComponentType } from 'react';
import type { AuthObject, ContentObject, CurrentUsersObject, CurrentUser, UserLevelMapping } from '../../../flowTypes';
import Modal from '@material-ui/core/Modal';
import { NotificationForm } from './NotificationForm';
import { EditUserForm } from './EditUserForm';
import { BroadcastForm } from './BroadcastForm';
import { RemoveUserModal } from './RemoveUserModal';
import broadcastIcon from '../../../assets/svgs/broadcast.svg';
import exportIcon from '../../../assets/svgs/export.svg';
import bellIcon from '../../../assets/svgs/bell.svg';
import circleMinusIcon from '../../../assets/svgs/circleminus.svg';
import circlePlusIcon from '../../../assets/svgs/circleplus.svg';
import editIcon from '../../../assets/svgs/edit-blue.svg';
import removeUserIcon from '../../../assets/svgs/user-red.svg';
import searchIcon from '../../../assets/svgs/search.svg';
import caretDownIcon from '../../../assets/svgs/caret-down.svg';
import caretUpIcon from '../../../assets/svgs/caret-up.svg';
import checkIcon from '../../../assets/svgs/circle-check.svg';
import { DEFAULT_EASY_PROTOCOL, DEFAULT_EASY_PROTOCOL_ID } from '../../../Constants';
import { Dropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';


type Props = {
    auth: AuthObject,
    content: ContentObject,
    fetchUsers: () => void,
    fetchProtocols: () => void,
    updateUsers: (currentUsers: CurrentUsersObject) => void,
    setNewUserLevelMapping: (mapping: UserLevelMapping) => void,
    updateSentNotifications: (newNotifications: any[]) => void,
    setNewUserProtocol: (id: string, uid: string, mapping: { [user: string]: string }) => void,
};

type State = {
    showRemoveUserModal: boolean,
    selectedUserId: string,
    selectedUser: CurrentUser,
    uidSearchText: string;
    showNotificationsModal: boolean,
    showEditUserModal: boolean,
    showBroadcastModal: boolean,
    nameSortOrder: 'asc' | 'desc' | '';
    levelSortOrder: 'asc' | 'desc' | '';
    protocolSortOrder: 'asc' | 'desc' | '';
}
export class Users extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showRemoveUserModal: false,
            userToBeRemovedUsername: '',
            selectedUserId: '',
            selectedUser: {},
            uidSearchText: '',
            showNotificationsModal: false,
            showEditUserModal: false,
            showBroadcastModal: false,
            nameSortOrder: '',
            levelSortOrder: '',
            protocolSortOrder: ''
        };
    }

    componentDidMount() {
        const {
            fetchUsers,
            fetchProtocols
        } = this.props;

        if (fetchUsers) {
            fetchUsers();
        }

        if (fetchProtocols) {
            fetchProtocols();
        }


    }

    render(): any {
        const { auth, content } = this.props;
        const { networkKey } = auth || {};
        const { currentUsers = {} } = content || {};
        const {
            showNotificationsModal,
            selectedUserId,
            selectedUser,
            showRemoveUserModal,
            showEditUserModal,
            showBroadcastModal,
            nameSortOrder,
            levelSortOrder,
            protocolSortOrder,
        } = this.state;

        return (
            <div className="manual-container">
                <div className="d-flex justify-content-between">
                    <button testid="broadcast-button" className="btn app-btn-primary btn-lg" onClick={() => this.setState({ showBroadcastModal: true })}>
                        <img className="image-icon button-icon" src={broadcastIcon} alt="broadcast icon" />
                        Broadcast
                    </button>
                    {!isMobile && <CSVLink testid="download-csv" className="btn app-btn-outline btn-lg" data={this.getCsvDownloadData()}>
                        <img className="image-icon button-icon" src={exportIcon} alt="export icon" />
                        Export
                    </CSVLink>}
                </div>
                <div className="table-responsive" style={{ minWidth: 855, overflow: 'visible' }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">
                                    <div className="search-uid">
                                        <img className="image-icon " src={searchIcon} alt="search icon" />

                                        <input
                                            testid="search-user"
                                            onChange={(e) => this.setState({ uidSearchText: e.target.value })}
                                            type="text"
                                            placeholder="Search"
                                            className="form-input"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </div>
                                </th>
                                {!isMobile && <th scope="col">
                                    User ID
                                </th>}
                                <th scope="col">Name
                                    <div testid="name-sort" className={`sort-container ${nameSortOrder}`} onClick={this.toggleNameSort}>
                                        <img className="extra-small-icon" src={caretUpIcon} alt="up arrow" />
                                        <img className="extra-small-icon" src={caretDownIcon} alt="down arrow" />
                                    </div>
                                </th>
                                {!isMobile && <> <th scope="col">Phone</th>
                                    <th scope="col">Protocol
                                        <div testid="protocol-sort" className={`sort-container ${protocolSortOrder}`} onClick={this.toggleProtocolSort}>
                                            <img className="extra-small-icon asc" src={caretUpIcon} alt="up arrow" />
                                            <img className="extra-small-icon desc" src={caretDownIcon} alt="down arrow" />
                                        </div>

                                    </th>
                                    <th scope="col">Level
                                        <div testid="level-sort" className={`sort-container ${levelSortOrder}`} onClick={this.toggleLevelSort}>
                                            <img className="extra-small-icon asc" src={caretUpIcon} alt="up arrow" />
                                            <img className="extra-small-icon desc" src={caretDownIcon} alt="down arrow" />
                                        </div>

                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </>}
                            </tr>
                        </thead>
                        <tbody>{this._getUsersList()}</tbody>
                    </table>
                </div>
                {showNotificationsModal ? (
                    <Modal
                        testid="notification-modal"
                        onClose={() => this.setState({ showNotificationsModal: false })}
                        open={showNotificationsModal}
                        style={{ display: 'flex' }}

                    >
                        <>
                            <NotificationForm
                                onDone={() => this.setState({ showNotificationsModal: false })}
                                user={selectedUser}
                                userId={selectedUserId}
                                networkKey={networkKey}
                            />
                        </>
                    </Modal>
                ) : null}
                {showRemoveUserModal ? (
                    <Modal
                        testid="remove-user-modal"
                        open={showRemoveUserModal}
                        onClose={() => this.setState({ showRemoveUserModal: false })}
                        style={{ display: 'flex' }}

                    >
                        <>
                            <RemoveUserModal fullname={`${selectedUser.loginInfo.firstName} ${selectedUser.loginInfo.lastName}`} onDone={() => this.setState({ showRemoveUserModal: false })} removeUser={this.removeUser} />
                        </>
                    </Modal>
                ) : null}
                {showEditUserModal ? (
                    <Modal
                        testid="edit-user-modal"
                        open={showEditUserModal}
                        onClose={() => this.setState({ showEditUserModal: false })}
                        style={{ display: 'flex' }}
                    >
                        <>
                            <EditUserForm user={selectedUser} userId={selectedUserId} networkKey={networkKey} onDone={() => this.setState({ showEditUserModal: false })} />
                        </>
                    </Modal>
                ) : null}
                {showBroadcastModal ? (
                    <Modal
                        testid="broadcast-modal"
                        style={{ display: 'flex' }}
                        open={showBroadcastModal}
                        onClose={() => this.setState({ showBroadcastModal: false })}
                    >
                        <>
                            <BroadcastForm currentUsers={currentUsers} networkKey={networkKey} onDone={() => this.setState({ showBroadcastModal: false })} />
                        </>
                    </Modal>
                ) : null}
            </div>
        );
    }


    notificationPrompt: any = (userId: string) => {
        const { content } = this.props;
        const { currentUsers } = content
        const user = currentUsers[userId];
        this.setState({ selectedUser: user, selectedUserId: userId, showNotificationsModal: true });
    };

    removeUserPrompt: any = (userId: string) => {
        const { content } = this.props;
        const { currentUsers } = content;
        const user = currentUsers[userId];
        this.setState({ selectedUser: user, selectedUserId: userId, showRemoveUserModal: true });
    };

    editUserPrompt: any = (userId: string) => {
        const { content } = this.props;
        const { currentUsers } = content
        const user = currentUsers[userId];
        this.setState({ selectedUser: user, selectedUserId: userId, showEditUserModal: true });
    }

    toggleNameSort: any = () => {
        let newSortValue = '';
        switch (this.state.nameSortOrder) {
            case "asc":
                newSortValue = "desc";
                break;
            case "desc":
                newSortValue = "";
                break;
            default:
                newSortValue = "asc"
        }
        this.setState({ nameSortOrder: newSortValue })
    }
    toggleLevelSort: any = () => {
        let newSortValue = '';
        switch (this.state.levelSortOrder) {
            case "asc":
                newSortValue = "desc";
                break;
            case "desc":
                newSortValue = "";
                break;
            default:
                newSortValue = "asc";
        }
        this.setState({ levelSortOrder: newSortValue })
    }
    toggleProtocolSort: any = () => {
        let newSortValue = '';
        switch (this.state.protocolSortOrder) {
            case "asc":
                newSortValue = "desc";
                break;
            case "desc":
                newSortValue = "";
                break;
            default:
                newSortValue = "asc"
        }
        this.setState({ protocolSortOrder: newSortValue })
    }

    getCsvDownloadData(): Array<any[]> {
        const { content = {} } = this.props;
        const { currentUsers = {} } = content;
        const usersList = this.getUserListFromUsersObject(currentUsers, true);

        const csvData = [
            [
                'First Name',
                'Last Name',
                'Email',
                'User ID',
                'Protocol',
                'Level',
                'Date of Birth',
                'Age',
                'Weight',
                'Height',
                'Sex',
                'Ethnicity',
                'Compliant',
            ],
        ].concat(usersList);

        return csvData;
    }

    _getUsersList(): any {
        const { content, setNewUserProtocol } = this.props;
        const { uidSearchText } = this.state;
        const { currentUsers = {}, protocols = [], chosenProtocolsMapping = {} } = content || {};

        const usersList = this.getUserListFromUsersObject(currentUsers);
        let filteredUserList = usersList.filter((user, i) => {
            const userId = user[0][0]?.toLowerCase();
            const fullname = `${user[1]} ${user[2]}`.toLowerCase();
            const email = user[3]?.toLowerCase();
            const selectedProtocol = user[4][1]?.toLowerCase();
            const searchText = uidSearchText?.toLowerCase();
            return userId.indexOf(searchText) > -1 || fullname.indexOf(searchText) > -1 || email.indexOf(searchText) > -1 || selectedProtocol.indexOf(uidSearchText) > -1;
        });
        const { levelSortOrder, nameSortOrder, protocolSortOrder } = this.state;
        const sortFunctions = [];
        const sortDirections = [];
        if (nameSortOrder) {
            sortFunctions.push((user) => `${user[1]} ${user[2]}`.toLowerCase());
            sortDirections.push(nameSortOrder);
        };
        if (levelSortOrder) {
            sortFunctions.push((user) => user[5][0] + 1);
            sortDirections.push(levelSortOrder);
        }
        if (protocolSortOrder) {
            sortFunctions.push((user) => `${user[4][1]}`.toLowerCase());
            sortDirections.push(protocolSortOrder);
        }

        if (nameSortOrder || levelSortOrder || protocolSortOrder) {
            filteredUserList = _.orderBy(filteredUserList, sortFunctions, sortDirections)
        }

        if (!filteredUserList.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        {`No data found ${uidSearchText ? 'for' : 'for members'} ${uidSearchText}`}
                    </td>
                </tr>
            );
        }

        /* if (!protocols) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        <div className="mx-auto d-inline-block">
                            {getLoadingIndicator()}
                        </div>
                    </td>
                </tr>
            )
        } */


        return filteredUserList.map((user, index) => (
            <tr key={`user-${index}`}>
                <td className="text-center">
                    <button testid={`ping-button-${index}`} className="btn app-btn-secondary" onClick={() => this.notificationPrompt(user[0][1])}>
                        <img className="image-icon button-icon" src={bellIcon} alt="bell icon" />
                        Ping
                    </button>
                </td>
                {!isMobile && <td className="pl-4 scorecard-uid">{user[0][0]}</td>}
                <td>
                    <p testid={`fullname-${index}`} className="member-name">
                        {`${user[1]} ${user[2]}`}
                    </p>
                    {!isMobile && <p testid={`email-${index}`} className="member-email">{user[3]}</p>}
                </td>
                {!isMobile && <> <td>{user[15]}</td>
                    <td>
                        <Dropdown>
                            <Dropdown.Toggle testid={`dropdown-${index}`} id={`dropdown-protocol-${index}`} className="btn btn-dropdown">
                                {_.truncate(user[4][0], { length: 15 })}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {_.uniqBy(
                                    [...protocols, { id: user[4][1], name: user[4][0] }]
                                        .filter(
                                            (prot) =>
                                                !prot.isADeactivatedNetworkProtocol &&
                                                (!prot.isAPrivateNetworkProtocol ||
                                                    (prot.usersWhitelist &&
                                                        prot.usersWhitelist.includes(decodeUserEmail(user[13]))))
                                        )
                                        .map((protocol) => ({ id: protocol.id, name: protocol.name })),
                                    'id'
                                ).map((protocol, i) => {
                                    const id = protocol.id;
                                    return (
                                        <Dropdown.Item
                                            testid={`protocol-user-${index}-${i}`}
                                            active={user[4][1] === id}
                                            key={`protocol-user-${i}`}
                                            onClick={() => setNewUserProtocol(id, user[0][1], chosenProtocolsMapping)}
                                        >
                                            {protocol.name}
                                            {user[4][1] === id ? <img src={checkIcon} className="ms-3 image-icon" alt="check icon" /> : null}
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    <td>
                        <div className="level-container">
                            {
                                <button
                                    testid={`level-down-button-${index}`}
                                    className="btn btn-light level-button"
                                    disabled={!!!(user[5][0] > 0)}
                                    onClick={() => this.onSetLevelForUser(user[0][1], user[5][0] - 1, user[4][1])}
                                >
                                    <img src={circleMinusIcon} alt="minusIcon" className="image-icon button-icon" />
                                </button>
                            }
                            <span key={`level-${index}`} testid={`level-${index}`} className="text-align-center">
                                {`${user[5][0] + 1}`}
                            </span>
                            {
                                <button
                                    testid={`level-up-button-${index}`}
                                    className="btn btn-light level-button"
                                    disabled={!!!user[5].find((level) => level > user[5][0])}
                                    onClick={() => this.onSetLevelForUser(user[0][1], user[5][0] + 1, user[4][1])}
                                // style={{ opacity: user[5].find((level) => level > user[5][0]) ? 1 : 0 }}
                                >
                                    <img src={circlePlusIcon} className="image-icon button-icon" alt="plus Icon" />
                                </button>
                            }
                        </div>
                    </td>
                    <td>
                        <button testid={`edit-button-${index}`} className="btn app-btn-outline" onClick={() => this.editUserPrompt(user[0][1])}>
                            <img src={editIcon} className="image-icon button-icon" alt="edit icon" />
                            Edit
                        </button>
                    </td>
                    <td>
                        <button testid={`remove-button-${index}`} className="btn app-btn-outline-danger" onClick={() => this.removeUserPrompt(user[0][1])}>
                            <img src={removeUserIcon} className="image-icon button-icon" alt="remove icon" />
                            Remove
                        </button>
                    </td>
                </>}
            </tr>
        ));
    }

    onSetLevelForUser(uid: string, newLevel: number, protocolID: string): void {
        const { content, setNewUserLevelMapping } = this.props;

        let { usersLevelMapping = {} } = content;

        if (!usersLevelMapping[uid]) {
            usersLevelMapping[uid] = {};
        }

        usersLevelMapping[uid][protocolID] = newLevel;
        setNewUserLevelMapping(usersLevelMapping);
        setLevelForUser(uid, protocolID, newLevel);
    }





    removeUser: () => any = () => {
        const { selectedUserId } = this.state;
        const { content, updateUsers } = this.props;
        let { currentUsers } = content;
        delete currentUsers[selectedUserId];

        updateUsers(currentUsers);

        try {
            removeUserFromNetwork(selectedUserId);
        } catch (e) { }
    };



    getUserListFromUsersObject: (currentUsers: CurrentUsersObject, forExcelDownload?: boolean) => Array<any[]> = (
        currentUsers: CurrentUsersObject,
        forExcelDownload?: boolean
    ) => {
        const { content } = this.props;
        const {
            thisMonthMetricLogs = [],
            chosenProtocolsMapping = {},
            usersLevelMapping = {},
            protocols = [],
        } = content || {};

        const usersList = Object.keys(currentUsers).map((userKey) => {
            const userObject = currentUsers[userKey];
            const { loginInfo, personalStatsInfo, uid, profile = {} } = userObject || {};
            const { email, firstName, lastName, username } = loginInfo || {};

            const { age, dateOfBirth, ethnicity, height, sex, weight } = personalStatsInfo || {};
            const { phoneNumber = "" } = profile

            let chosenProtocolID = chosenProtocolsMapping[uid];
            const levelInfo = usersLevelMapping[uid] || {};

            const potentialProtocols = protocols.filter(
                (protocol) =>
                    !protocol.isADeactivatedNetworkProtocol &&
                    !(protocol.isAPrivateNetworkProtocol && !_.includes(protocol.usersWhitelist, username))
            );
            const otherPineconeProtocols = [
                'PINECONE_CONFIDENCE_PROTOCOL',
                'PINECONE_WEIGHT_PROTOCOL',
                'PINECONE_CONFIDENCE_PROTOCOL',
                'PINECONE_SLEEP_PROTOCOL',
                'PINECONE_HAPPINESS_PROTOCOL',
                'PINECONE_MONEY_PROTOCOL',
            ];

            const foundProtocol = !chosenProtocolID
                ? DEFAULT_EASY_PROTOCOL
                : potentialProtocols.find((protocol) => protocol.id === chosenProtocolID) || {
                    id: chosenProtocolID,
                    name: convertProtocolIDToName(chosenProtocolID),
                };

            const currentLevel = levelInfo[foundProtocol.id] || 0;
            const availableLevels =
                foundProtocol.id === DEFAULT_EASY_PROTOCOL_ID
                    ? [0, 1, 2, 3]
                    : otherPineconeProtocols.includes(foundProtocol.id)
                        ? [0, 1, 2]
                        : protocols
                            .filter((protocol) => protocol.id === foundProtocol.id)
                            .map((protocol) => protocol.level)
                            .sort();

            return [
                forExcelDownload ? _.join(_.take(uid, 6), '') : [_.join(_.take(uid, 6), ''), uid],
                firstName,
                lastName,
                email,
                forExcelDownload ? foundProtocol.name : [foundProtocol.name, foundProtocol.id],
                forExcelDownload ? currentLevel : [currentLevel, ...availableLevels],
                dateOfBirth,
                age,
                weight,
                height,
                sex,
                ethnicity,
                thisMonthMetricLogs.find((log) => log.user === username) ? 'Yes' : 'No',
                forExcelDownload ? null : encodeUserEmail(username),
                forExcelDownload ? null : userKey,
                formatPhoneNumber(phoneNumber),
            ];
        });

        return usersList;
    };
}



const mapStateToProps = (state) => {
    const {
        content,
        auth
    } = state;

    return {
        content,
        auth
    };
}

const connectedComponent: ComponentType<Props> = connect(mapStateToProps, {
    fetchUsers,
    fetchProtocols,
    fetchMetricLogsForPastMonth,
    updateUsers,
    setNewUserLevelMapping,
    fetchSentNotifications,
    updateSentNotifications,
    setNewUserProtocol,
    fetchIsClosedNetwork,
    setClosedNetwork
})(withRouter(Users));

export default connectedComponent;