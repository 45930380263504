// @flow
import React from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Calendar from '../Calendar';
import ScoreCard from './ScoreCard';
import Outcomes from './Outcomes';
import Adherence from './Adherence';
import type {ComponentType} from 'react';

type Props = {
    match: any
};

type State = {};

export class Insights extends React.Component<Props, State> {
    render(): any {
        const { path } = this.props.match;
        return (
            <Switch>
                <Route exact path={path}>
                    <Redirect to = {`${path}/scorecard`} />
                </Route>
                <Route exact path={`${path}/scorecard`}>
                   <ScoreCard />
                </Route>
                <Route exact path={`${path}/outcomes`}>
                    <Outcomes />
                </Route>
                <Route exact path={`${path}/adherence`}>
                    <Adherence />
                </Route>
                <Route exact path={`${path}/heatmap`}>
                    <Calendar />
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = ({ auth, content }) => ({
    auth, content
});

const  ConnectedInsights:ComponentType<{}> = connect(mapStateToProps, null)(withRouter(Insights));

export default ConnectedInsights
