export const waterDescription = `The body is made up mostly of water and uses it in all its cells, organs, and tissues to maintain necessary functions. Proper hydration improves physical performance, energy levels, brain function, and can aid in weight loss. Symptoms of dehydration are weakness, low blood pressure, dizziness, and confusion.

[How much water should you drink? - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/how-much-water-should-you-drink)`;

export const sunlightDescription = `Vitamin D, which is best absorbed via sunlight, plays a vital role in mediating mood, energy, auto-immunity, bone strength, and hair and skin quality.  In fact, deficiency can lead to a variety of more serious health issues down the road. Spending time outside has also been shown to reduce stress and depression.

[Vitamin D Fact Sheet - National Institute of Health](https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/)

[How does nature exposure make people healthier? - PLOS One](https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0202246)`;

export const readingDescription = `Don't underestimate the value of everyday activities like reading. Not only will you learn more but it may be enough to help you preserve your memory. Studies have found that people who participated in mentally stimulating activities such as reading books had a slower rate of decline in memory.

[Reading books may add years to your life - Harvard Health Publishing](https://www.health.harvard.edu/healthy-aging/reading-books-may-add-years-to-your-life)

[Read a book, preserve your memory - Harvard Health Publishing](https://www.health.harvard.edu/mind-and-mood/read-a-book-preserve-your-memory)`;

export const meditationDescription = `The aim of meditation is not to push aside stress or block out negative thinking, but to notice those thoughts and feelings and understand that you don't have to act on them. This could be as simple as closing your eyes and repeating a single phrase or word, or counting breaths. Meditation has been found to help with stress, anxiety, and focus, and actually change certain brain regions that are specifically linked with depression.

[How meditation helps with depression - Harvard Health Publishing](https://www.health.harvard.edu/mind-and-mood/how-meditation-helps-with-depression)

[Meditation may help you catch mental mistakes - Harvard Health Publishing](https://www.health.harvard.edu/mind-and-mood/meditation-may-help-you-catch-mental-mistakes)

[Two mindfulness meditation exercises to try - Harvard Health Publishing](https://www.health.harvard.edu/alternative-and-complementary-medicine/two-mindfulness-meditation-exercises-to-try)`;

export const alcoholDescription = `Alcohol plays a role in at least half of all serious trauma injuries and deaths from burns, drownings, and homicides. It’s also involved in four out of 10 fatal falls and traffic crashes, as well as suicides. Avoiding alcohol leads to a healthier brain, heart, and liver, weight loss, better relationships, and higher quality sleep.

[What Happens to Your Body When You Stop Drinking Alcohol - WebMD](https://www.webmd.com/mental-health/addiction/ss/slideshow-quit-alcohol-effects)

[Here’s what happens to your body when you quit alcohol for 30 days - Healthline Media](https://www.healthline.com/health-news/what-happens-to-your-body-when-you-quit-alcohol-for-30-days#Benefits-of-Dry-January)`;

export const stretchingDescription = `Stretching keeps the muscles flexible, strong, and healthy. Without it, the muscles shorten and become tight. Stretching helps maintain range of motion while preventing joint pain, strains, and muscle damage.

[Why should you stretch? - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/why-should-you-stretch)

[The ideal stretching routine - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/the-ideal-stretching-routine)`;

export const stepsDescription = `Walking improves cardiac risk factors such as cholesterol, blood pressure, diabetes, obesity, vascular stiffness and inflammation, and mental stress. Getting outside and walking can also help protect against dementia, peripheral artery disease, obesity, diabetes, some cancers, and depression.

[Walking your steps to health - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/walking-your-steps-to-health)`;


export const addedSugar = `Added sugar refers to sugars and syrups that are included in food products and beverages to increase sweetness and texture and extend shelf life. (This is different from natural sugar found in fruit, vegetables, and dairy.) A little extra sugar may seem harmless, but ingested often, it can contribute to a variety of health issues, like obesity, diabetes, and heart disease.

[The bitter truth about added sugar - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/the-bitter-truth-about-added-sugar)

[Reducing Sugar in Your Diet - Harvard Health Publishing](https://www.health.harvard.edu/diet-and-weight-loss/reducing-sugar-in-your-diet)`;

export const greenVegetablesDescription = `Most salad greens contain essential dietary nutrients, such as vitamins, minerals, and water. They also provide fiber which helps cardiovascular and gastrointestinal health. Greens are low in calories, but high in nutrients and volume, which can be helpful in losing weight. Some of the most nutritious greens include spinach, kale, romaine, watercress, and arugula. They are rich in vitamins A, C, and K; several B vitamins (including folate); and potassium.

[Salad greens: Getting the most bang for the bite - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/salad-greens-getting-the-most-bang-for-the-bite)`;

export const strengthTrainingDescription = `Strength increases with exercises that require you to resist an opposing force. A well-rounded strength-training program works all major muscle groups, but not necessarily all on the same day. Strength training is vital for longevity as it promotes strong bones and muscles, improves balance, cholesterol, and cardiovascular fitness, helps control blood sugar and body weight, relieves joint pains, expands range of motion, and raises confidence and mood. 

[How and why to add strength straining to your exercise plan - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/how-and-why-to-add-strength-training-to-your-exercise-plan)`;

export const intenseCadioDescription = `Intense cardio may help you burn more calories than traditional exercise, or burn the same amount of calories in a shorter amount of time. It can elevate your metabolism for hours after exercise, burn visceral fat, and improve oxygen consumption. Intense cardio can also help to reduce blood pressure, heart rate, blood sugar, and insulin resistance.

[7 Benefits of High-Intensity Interval Training (HIIT) - Healthline Media](https://www.healthline.com/nutrition/benefits-of-hiit#TOC_TITLE_HDR_6)`;

export const addedSugarDescription = `Added sugar refers to sugars and syrups that are included in food products and beverages to increase sweetness and texture and extend shelf life. (This is different from natural sugar found in fruit, vegetables, and dairy.) A little extra sugar may seem harmless, but ingested often, it can contribute to a variety of health issues, like obesity, diabetes, and heart disease.

[The bitter truth about added sugar - Harvard Health Publishing](https://www.health.harvard.edu/staying-healthy/the-bitter-truth-about-added-sugar)

[Reducing Sugar in Your Diet - Harvard Health Publishing](https://www.health.harvard.edu/diet-and-weight-loss/reducing-sugar-in-your-diet)`;

export const intermittentFastingDescription = `Intermittent fasting (IF) is an eating pattern that cycles between periods of fasting and eating where the day or week is split into eating and fasting periods. When you fast, human growth hormone levels go up and insulin levels go down. Your body’s cells also change the expression of genes and initiate important cellular repair processes. Research shows that intermittent fasting is a way to manage your weight and prevent — or even reverse — some forms of disease.

[Intermittent Fasting 101 — The Ultimate Beginner’s Guide - Healthline Media](https://www.healthline.com/nutrition/intermittent-fasting-guide#methods)

[Intermittent Fasting: What is it, and how does it work? - Johns Hopkins Medicine](https://www.hopkinsmedicine.org/health/wellness-and-prevention/intermittent-fasting-what-is-it-and-how-does-it-work)`;

export const refinedCarbsDescriptions = `Refined carbohydrates are primarily white flour products like breads and rolls, white rice, crackers, french fries, and sugary cereals. These foods are stripped of their nutrition and lack fiber. Research shows that refined carbs cause inflammation in your bod and are associated with obesity..

[5 Foods That Can Cause Inflammation - Cleveland Clinic Health Essentials](https://health.clevelandclinic.org/5-foods-that-can-cause-inflammation/)

[Excessive Refined Carbohydrates and Scarce Micronutrients Intake … - Hindawi](https://www.hindawi.com/journals/mi/2014/849031/)`;