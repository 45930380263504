// @flow
import React, { useEffect, useRef, useState } from 'react';
import { getLoadingIndicator } from '../../UI Resources';
import { assignUserProtocol } from '../../../actions';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import arrowIcon from '../../../assets/svgs/arrow-up-right-blue.svg';
import checkboxIcon from '../../../assets/svgs/checkbox.svg';
import checkboxCheckIcon from '../../../assets/svgs/checkbox-check.svg';
import type { ComponentType } from 'react';
import type { CurrentUsersObject, Protocol } from '../../../flowTypes';
import _ from 'lodash';
import { setProtocolForIDWithNetworkKey } from '../../../api/firebase';

type AssignProtocolFormProps = {
    selectedProtocol: Protocol,
    protocols: Protocol[],
    chosenProtocolMapping: { [uid: string]: string },
    users: CurrentUsersObject,
    onDone: () => void,
    selectedMemberId: string,
    defaultMembers: string[],
    protocolsObject: { [uid: string]: Protocol },
    networkKey: string,
};

// eslint-disable-next-line
function useOutsideAlerter(ref: any, callback: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any): any {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [ref]);
}

const emptyProtocol: Protocol = {};
export const AssignProtocolForm: ComponentType<AssignProtocolFormProps> = ({
    protocols = [],
    selectedProtocol = emptyProtocol,
    users = {},
    onDone,
    selectedMemberId = "",
    defaultMembers = [],
    chosenProtocolMapping = {},
    protocolsObject = {},
    networkKey,
}) => {
    const [protocolId, setProtocolId] = useState(selectedProtocol?.id);
    const [protocolLevel, setProtocolLevel] = useState(selectedProtocol.level);
    const [members, setMembers] = useState(selectedMemberId ? [selectedMemberId] : defaultMembers);
    const [updating, setUpdating] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showMembersMenu, setShowMembersMenu] = useState(false);
    const { loginInfo = {} } = selectedMemberId ? users[selectedMemberId] : {};
    const { firstName = '', lastName = '' } = loginInfo;
    const selectedMemberName = `${firstName} ${lastName}`;
    const dispatch = useDispatch();

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowMembersMenu(false));

    const assignProtocol = async (): Promise<any> => {
        try {
            setUpdating(true);

            const protocolsToChange = protocols.filter(p => p.id === protocolId);
            const isPrivateProtocol = protocolsToChange[0]?.isAPrivateNetworkProtocol;

            if (isPrivateProtocol) {
                for (const protocol of protocolsToChange) {
                    protocol.usersWhitelist = [...((protocol.usersWhitelist || []).filter(uid => !members.includes(uid))), ...members];
                }
                await setProtocolForIDWithNetworkKey(networkKey, protocolsToChange, protocolId);
            }

            await assignUserProtocol(protocolId, members, protocolLevel, chosenProtocolMapping)(dispatch);

            setUpdateSuccess(true);
        } catch (error) {
            setErrorMessage(error?.message || 'Unexpected Error');
        } finally {
            setUpdating(false);
        }
    };
    const selectMember = (uid: string): any => {
        const index = members.indexOf(uid);

        if (index > -1) {
            const newmembers = [...members];
            newmembers.splice(index, 1);
            setMembers(newmembers);
        } else {
            const newmember = [...members];
            newmember.push(uid);
            setMembers(newmember);
        }
    };

    const levels = protocolsObject[protocolId]?.levels || [];

    return (
        <div className="modal-container modal-md">
            <div className="modal-head d-flex ">
                <div className="modal-icon-container">
                    <img src={arrowIcon} className="modal-icon" alt="modal icon" />
                </div>
                <p testid="modal-title" className="modal-title">
                    {updateSuccess ? 'Protocol Assigned' : 'Assign Protocol'}
                </p>
            </div>
            {updateSuccess ? (
                <div className="d-flex flex-column justify-content-center">
                    <button
                        testid="done-button"
                        className="btn btn-block btn-lg app-btn-secondary mt-3"
                        onClick={onDone}
                    >
                        Okay
                    </button>
                </div>
            ) : (
                <div className="modal-bd">
                    <div className="">
                        {errorMessage ? (
                            <p testid="notification-error" className="form-error text-center">
                                {errorMessage}
                            </p>
                        ) : null}
                        <div className="form-group">
                            <label>Select Protocol</label>
                            <select
                                testid="protocol-input"
                                className="form-control"
                                value={protocolId}
                                onChange={(e) => { setProtocolId(e.target.value); setProtocolLevel(0) }}
                            >
                                {protocols.map(({ id, name }, i) => (
                                    <option key={`protocol-option-${i}`} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Select Level</label>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {levels.map((level, index) => (
                                    <div
                                        testid={`level-button-${index}`}
                                        key={`level-button-${index}`}
                                        onClick={() => setProtocolLevel(index)}
                                        className={
                                            index === protocolLevel ? 'level-btn-current active' : 'level-btn'
                                        }
                                    >
                                        {index === protocolLevel ? `Level ${index + 1}` : index + 1}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Select Members</label>
                            <Dropdown id="members-dropdown" show={showMembersMenu} ref={wrapperRef}>
                                <input
                                    testid="members-dropdown"
                                    disabled={!!selectedMemberId}
                                    value={members.length ? members.map(uid => `${users[uid].loginInfo.firstName} ${_.take(users[uid].loginInfo.lastName)}.`).join(', ') : ''}
                                    placeholder={selectedMemberId ? selectedMemberName : 'Select Members'}
                                    className="form-control"
                                    onClick={() => setShowMembersMenu((value) => !value)}
                                />
                                <Dropdown.Menu className="p-3">
                                    {Object.keys(users).sort((a, b) => users[a].loginInfo.firstName.localeCompare(users[b].loginInfo.firstName)).map((key, i) => {
                                        const val = users[key];
                                        const { uid, loginInfo: { firstName, lastName } } = val;
                                        const chosen = members.includes(uid);
                                        return (
                                            <div
                                                key={`member-${i}`}
                                                testid={`member-${i}`}
                                                className={`dropdown-head d-flex  align-items-center`}
                                                onClick={() => selectMember(uid)}
                                            >
                                                <img
                                                    alt="check icon"
                                                    src={chosen ? checkboxCheckIcon : checkboxIcon}
                                                    className="image-icon extra-small-icon mr-2"
                                                />
                                                <div className="ml-5">{`${firstName} ${_.take(lastName)}.`}</div>
                                            </div>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <button
                                testid="submit-button"
                                className="btn btn-block btn-lg app-btn-primary mt-3"
                                onClick={assignProtocol}
                                disabled={!members.length || (protocolLevel >= levels.length) || !protocolId || !protocols}
                            >
                                {updating ? getLoadingIndicator() : <>{'Assign'}</>}
                            </button>
                            <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
