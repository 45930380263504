import React, { useState } from "react";
import _ from "lodash";
import arrowIcon from '../../../assets/svgs/arrow-up-right.svg'
import searchIcon from '../../../assets/svgs/search.svg';
import caretDownIcon from '../../../assets/svgs/caret-down.svg';
import caretUpIcon from '../../../assets/svgs/caret-up.svg';
import checkboxIcon from '../../../assets/svgs/checkbox.svg';
import checkboxCheckIcon from '../../../assets/svgs/checkbox-check.svg';

import type {ComponentType} from 'react';
import type { CurrentUsersObject, Protocol } from '../../../flowTypes';


type Props = {
    currentUsers: CurrentUsersObject,
    selectedProtocolId: string,
    selectedProtocol: Protocol,
    usersWhitelist: string [],
    chosenProtocolMapping: {[userKey: string]: string},
    changeMemberProtocol: (memberID: string) => void,
    isPrivateNetworkProtocol: boolean,
    updateWhiteList: (username: string, selectedProtocol) => void; 
}


export const ProtocolTable: ComponentType<Props> = ({currentUsers = {}, isPrivateNetworkProtocol=false, updateWhiteList = _.noop, selectedProtocolId = "", selectedProtocol, usersWhitelist = [], chosenProtocolMapping= {}, changeMemberProtocol = _.noop})=>{
    const [searchString, setSearchString] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    const toggleSort = ()=>{
        switch(sortOrder){
            case 'asc': 
            setSortOrder('desc');
            break;
            case 'desc':
                setSortOrder('');
                break;
            default:
                setSortOrder('asc');
                break;
        }
    }

    let userList = Object.values(currentUsers);
    const lowercaseSearchString = searchString.toLocaleLowerCase();
    if(searchString)  userList = userList.filter(({uid, loginInfo: {firstName, lastName }})=>  !!(uid.toLocaleLowerCase().indexOf(lowercaseSearchString) > -1 | `${firstName.toLocaleLowerCase()} ${lastName.toLocaleLowerCase()}`.indexOf(lowercaseSearchString)> -1));

    if(sortOrder) userList = _.orderBy(userList, [(user)=> user.uid, (user)=> `${user.loginInfo.firstName} ${user.loginInfo.lastName}`], [sortOrder, sortOrder]);
    return (
        <div>
            {selectedProtocol?.name ? <h4><b>{selectedProtocol?.name}</b></h4> : <h5 className=""><b>{'Select a protocol to view or change assignments'}</b></h5>}
            <div className="table-responsive" style={{marginTop: 0}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">
                                <div className="search-uid">
                                    <img className="image-icon " src={searchIcon} alt="search icon" />
                                    <input
                                        testid="search-user"
                                        onChange={(e) => setSearchString(e.target.value)}
                                        type="text"
                                        placeholder="Search"
                                        className="form-input"
                                        aria-label="Small"
                                        aria-describedby="inputGroup-sizing-sm"
                                    />
                                </div>
                            </th>
                            <th scope="col">User ID</th>
                            <th scope="col">
                                Name
                                <div
                                    testid="user-sort"
                                    onClick={toggleSort}
                                    className={`sort-container ${sortOrder}`}
                                >
                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                </div>
                            </th>

                            <th scope="col" className="text-center">Can View</th>
                            <th scop="co" className="text-center">Assigned</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.map(({uid, loginInfo: {firstName, lastName, username}}, i) => (
                            <tr key={`protocol-user-${i}`}>
                                <td className="text-center">
                                    <button
                                        onClick={() => changeMemberProtocol(uid)}
                                        testid={`assign-button-${i}`}
                                        className="btn app-btn-secondary"
                                        disabled = {!!!selectedProtocolId}
                                    >
                                        Change
                                        <img className="image-icon button-icon" src={arrowIcon} alt="bell icon" />
                                    </button>
                                </td>
                                <td className="scorecard-uid">
                                    {_.take(uid, 6)}
                                </td>
                                <td className="">
                                    <b>{`${firstName}${lastName ? ` ${lastName[0]}.` : ''}`}</b>
                                </td>
                                <td className="text-center">
                                    {
                                        selectedProtocolId 
                                            ? (isPrivateNetworkProtocol
                                                ? <img alt="check icon" src={usersWhitelist.includes(uid) ? checkboxCheckIcon: checkboxIcon} testid={`canview-button-${i}`} className="image-icon" onClick={()=> updateWhiteList(uid)} />
                                                : <img alt="check icon" src={checkboxCheckIcon} className="image-icon disabled" />
                                            )
                                            : <img alt="check icon" src={checkboxIcon} className="image-icon disabled" />
                                    }
                                </td>
                                <td className="text-center">
                                    <img alt = "check icon" src = {chosenProtocolMapping[uid] === selectedProtocolId ? checkboxCheckIcon: checkboxIcon} className="image-icon" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}