// @flow

import { isAlpha } from '../appConfig';
import {
    waterDescription,
    sunlightDescription,
    readingDescription,
    meditationDescription,
    alcoholDescription,
    stretchingDescription,
    stepsDescription,
    addedSugar,
    greenVegetablesDescription,
    strengthTrainingDescription,
    intenseCadioDescription,
    intermittentFastingDescription,
    refinedCarbsDescriptions
} from './protocolDescriptions';

import type { ColorMapping, MetricScoreMapping, Metric, Protocol } from '../flowTypes';

export const EMPTY_STRING: string = '';

export const MISMATCHED_PASSWORDS_ERROR: string = 'passwords do not match.';
export const WEAK_PASSWORD_ERROR: string = 'password must be at least six characters.';
export const SIGNUP_GENERIC_ERROR: string = 'an error has occured while signing up.';
export const NETWORK_IN_USE: string = 'a network with that name already exists.';
export const EMAIL_INVALID: string = 'email format is not valid.';
export const CARD_INVALID: string = 'card not accepted.';
export const SUBSCRIPTION_FAILED: string = 'failed to create subscription.';
export const INCORRECT_ACTIVATION_CODE: string = 'the activation code is invalid.';
export const NETWORK_DOES_NOT_EXIST: string = 'a network with that name does not exist.';
export const INCORRECT_EMAIL: string = 'the email you have entered is incorrect.';
export const INCORRECT_PASSWORD: string = 'the password you have entered is incorrect.';
export const LOGIN_GENERIC_ERROR: string = 'an error has occured while logging in.';
export const GENERIC_ERROR: string = 'an error has occured.';

export const COLOR_MAPPING_FULL_ALPHA: ColorMapping = { 'terrible': 'rgb(141,72,125)', 'awful': 'rgb(141,72,125)', 'bad': 'rgb(102,89,166)', 'average': 'rgb(87,112,182)', 'okay': 'rgb(87,112,182)', 'good': 'rgb(84,159,191)', 'very good': 'rgb(84,192,169)', 'excellent': 'rgb(84,192,169)' };
export const INSIGHTS_COLORS_1: Array<string> = ['#54C0A9', '#549FBF', '#6659A6', '#8D487D'];
export const INSIGHTS_COLORS_2: Array<string> = ['#FFEC99', '#F8BD9F', '#E1A1B3'];

export const QUALITIES_INDIVIDUAL_SCORES_COLORS: Array<string> = ['#E5E8CC', '#CEE5F2', '#D8D9F4', '#EAD1DC', ...INSIGHTS_COLORS_2, ...INSIGHTS_COLORS_1];
export const CALENDAR_METRIC_COLORS: Array<string> = ['#7D2242', '#5B3E61', '#404B68', '#295369', '#125B69', '#3D6D60', '#64783D', '#AB9744', '#B36E31'];
export const CALENDAR_MIN_COLOR: string = '#CAD3D4';
export const DEFAULT_CALENDAR_COLOR: string = '#005F73';
export const EMPTY_CALENDAR_CELL_COLOR: string = '#F1F5F9';
export const MIN_QUALITY_CELL_COLOR: string = "#DDF0FF";
export const MAX_QUALITY_CELL_COLOR: string = "#004C6D";
export const MAX_ACTION_CELL_COLOR: string = "#6D003E";
export const MIN_ACTION_CELL_COLOR: string = "#FFE7F0";
export const AVERAGE_ADHERENCE_COLOR: string = '#A0BFD6';
export const PARTICIPATION_COLOR: string = '#B9B8E3';
export const ACTIONS_INDIVIDUAL_SCORES_COLORS: Array<string> = ['#CCE7E1', '#C5E0DA', '#B6D1CB', '#A0BBB4', '#92ADA5', '#839E96', '#758F87', '#668078', '#5D746D', '#556963', '#7E7E70', '#8B8B7B', '#999987', '#A8A894', '#B9B9A3', '#C3C3AB', '#CCCCB3', '#D6D6BC', '#E0E0C5', '#E5E8CC', '#EAD1DC', '#E8CCD8', '#DBC1CC', '#CEB5BF', '#C1AAB3', '#B39EA6', '#A6939A', '#98878D', '#8B7B81', '#7D6F74', '#D8D9F4', '#D0D1F2', '#C5C5EB',
    '#B9B8E3', '#A8A7CE', '#9998BB', '#8B8AAA', '#7E7D9B', '#73728D', '#696880', '#CEE5F2', '#BDD8EA', '#A0BFD6', '#94B2CB', '#88A5BF', '#7C98B3', '#768EA7', '#6A7A8E', '#637081', '#5A6675'];
export const METRIC_SCORE_MAPPING: MetricScoreMapping = { 'very bad': 1, 'awful': 1, 'terrible': 1, 'bad': 2, 'average': 3, 'okay': 3, 'good': 4, 'very good': 5, 'great': 5, 'excellent': 5 };

// eslint-disable-next-line
export const EMAIL_REGEX: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const ACTIVITY_TOOL_TIP = "The number of action responses out of the number of possible responses for the selected action(s) and time period. Filter using the Adherence column."
export const STREAK_TOOLTIP = "The number of days in a row that actions were logged by a member.";
export const DAYS_ON_TOOLTIP = "The number of days this protocol was active during the time period.";
export const ADHERENCE_TOOLTIP = "The number of completed actions out of the total number of responses for the selected action(s) and time period. Selections here affect the Activity column. ";
export const DISPOSITON_TOOLTIP = "The average score for selected slider rated qualities during the time period. For number input qualities, the most recent and average inputs are shown."

export const MONTH_GROUPING_CUTOFF = 15;
export const YEAR_GROUPING_CUTOFF = 105;

/**
 * 
 * FIREBASE CONSTANTS
 */
export const BRIGADES_PATH: string = 'brigades/';
export const FIREBASE_LIVE_CHATS_PATH: string = 'brigadeLiveChats/';
export const USERS_PATH: string = 'users/';
export const MAIN_IMAGE: string = 'main';
export const PROFILE_PICS_PATH: string = 'profilePics/';
export const POST_PICS_PATH: string = 'postPics/';

export const PROTOCOLS_DATABASE_ROOT: string = isAlpha()
    ? 'https://pinecone-alpha-protocols.firebaseio.com'
    : 'https://pinecone-protocols.firebaseio.com';

export const DEFAULT_EASY_PROTOCOL_ID = 'DEFAULT_EASY_PROTOCOL_ID';

export const AM = 'AM';
export const PM = 'PM';
export const WEEK = 'Week';
export const MONTH = 'Month';
export const YEAR = 'Year';


export const LIFESTYLE = 'lifestyle';
export const EXERCISE = 'exercise';
export const NUTRITION = 'nutrition';
export const SUPPLEMENT = 'supplement';
export const OTHER = 'other';

export const HABIT_CATEGORIES = [LIFESTYLE, EXERCISE, NUTRITION, SUPPLEMENT, OTHER]

export const NUMERIC_NOTIFICATION_DAYS = [1, 2, 3, 4, 5, 6, 7];

export const STRING_NOTIFICATION_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

const easyMetrics: Array<Metric> = [
    {
        name: 'quality-sleep-0',
        logTimes: ['AM'],
        query: 'past',
        displayName: 'sleep'
    },
    {
        name: 'quality-mood-0',
        logTimes: ['AM', 'PM'],
        query: 'present',
        displayName: 'mood'
    },
];

const normalMetrics: Array<Metric> = [
    {
        name: 'quality-sleep-0',
        logTimes: ['AM'],
        query: 'past',
        displayName: 'sleep'
    },
    {
        name: 'quality-mood-0',
        logTimes: ['AM', 'PM'],
        query: 'present',
        displayName: 'mood'
    },
    {
        name: 'quality-energy-0',
        logTimes: ['PM'],
        query: 'present',
        displayName: 'energy'
    },
];

const hardMetrics: Array<Metric> = [
    {
        name: 'quality-sleep-0',
        logTimes: ['AM'],
        query: 'past',
        displayName: 'sleep'
    },
    {
        name: 'quality-mood-0',
        logTimes: ['AM', 'PM'],
        query: 'present',
        displayName: 'mood'
    },
    {
        name: 'quality-energy-0',
        logTimes: ['PM'],
        query: 'present',
        displayName: 'energy'
    },
    {
        name: 'quality-clarity-0',
        logTimes: ['PM'],
        query: 'present',
        displayName: 'clarity'
    },
];

const extremeMetrics: Array<Metric> = [
    {
        name: 'quality-sleep-0',
        logTimes: ['AM'],
        query: 'past',
        displayName: 'sleep'
    },
    {
        name: 'quality-mood-0',
        logTimes: ['AM', 'PM'],
        query: 'present',
        displayName: 'mood'
    },
    {
        name: 'quality-energy-0',
        logTimes: ['PM'],
        query: 'present',
        displayName: 'energy'
    },
    {
        name: 'quality-clarity-0',
        logTimes: ['PM'],
        query: 'present',
        displayName: 'clarity'
    },
    {
        name: 'quality-effort-0',
        logTimes: ['PM'],
        query: 'present',
        displayName: 'effort'
    }
];

export const DEFAULT_EASY_PROTOCOL: Protocol = {
    id: DEFAULT_EASY_PROTOCOL_ID,
    name: 'Willow Wellness',
    metrics: easyMetrics,
    habits: [
        { name: 'action-water-0', type: LIFESTYLE, displayName: 'water', context: '4 glasses', description: waterDescription },
        { name: 'action-sunlight-0', type: LIFESTYLE, displayName: 'sunlight', context: '10 min.', description: sunlightDescription },
        { name: 'action-stretch-0', type: EXERCISE, displayName: 'stretch', context: '10 min.', description: stretchingDescription },
    ],
    level: 0,
    canPromote: true,
    canDemote: false,
    evaluationPeriodForPromotion: 10,
    compliancePromotionLevel: 70,
    complianceDemotionLevel: 30,
    adherencePromotionLevel: 80,
    adherenceDemotionLevel: 40,
    isAPrivateNetworkProtocol: false,
};

export const DEFAULT_NORMAL_PROTOCOL: Protocol = {
    id: DEFAULT_EASY_PROTOCOL_ID,
    name: 'Willow Wellness',
    metrics: normalMetrics,
    habits: [
        { name: 'action-water-0', type: LIFESTYLE, displayName: 'water', context: '4 glasses', description: waterDescription },
        { name: 'action-sunlight-0', type: LIFESTYLE, displayName: 'sunlight', context: '10 min.', description: sunlightDescription },
        { name: 'action-alcohol-0', type: LIFESTYLE, displayName: 'alcohol', context: 'avoided', description: alcoholDescription },
        { name: 'action-steps-0', type: EXERCISE, displayName: 'steps', context: '5000', description: stepsDescription },
        { name: 'action-stretch-0', type: EXERCISE, displayName: 'stretch', context: '10 min.', description: stretchingDescription },
        { name: 'action-added-sugar-0', type: NUTRITION, displayName: 'added sugar', context: 'avoided', description: addedSugar },
        { name: 'action-green-vegetables-0', type: NUTRITION, displayName: 'green vegetables', context: '2 serv.', description: greenVegetablesDescription },
    ],
    level: 1,
    canPromote: true,
    canDemote: true,
    evaluationPeriodForPromotion: 20,
    compliancePromotionLevel: 70,
    complianceDemotionLevel: 30,
    adherencePromotionLevel: 80,
    adherenceDemotionLevel: 40,
    isAPrivateNetworkProtocol: false,
};

export const DEFAULT_HARD_PROTOCOL: Protocol = {
    id: DEFAULT_EASY_PROTOCOL_ID,
    name: 'Willow Wellness',
    metrics: hardMetrics,
    habits: [
        { name: 'action-water-0', type: LIFESTYLE, displayName: 'water', context: '8 glasses', description: waterDescription, },
        { name: 'action-sunlight-0', type: LIFESTYLE, displayName: 'sunlight', context: '10 min.', description: sunlightDescription },
        { name: 'action-book-reading-0', type: LIFESTYLE, displayName: 'book reading', context: '20 min.', description: readingDescription },
        { name: 'action-alcohol-0', type: LIFESTYLE, displayName: 'alcohol', context: 'avoided', description: alcoholDescription, },
        { name: 'action-steps-0', type: EXERCISE, displayName: 'steps', context: '5000', description: stepsDescription, },
        { name: 'action-strength-training-0', type: EXERCISE, displayName: 'strength training', context: '30 min.', description: strengthTrainingDescription },
        { name: 'action-stretch-0', type: EXERCISE, displayName: 'stretch', context: '10 min.', description: stretchingDescription, },
        { name: 'action-added-sugar-0', type: NUTRITION, displayName: 'added sugar', context: 'avoided', description: addedSugar },
        { name: 'action-green-vegetables-0', type: NUTRITION, displayName: 'green vegetables', context: '4 serv.', description: greenVegetablesDescription },
        { name: 'action-refined-carbs-0', type: NUTRITION, displayName: 'refined carbs', context: 'avoided', description: refinedCarbsDescriptions },
    ],
    level: 2,
    canPromote: true,
    canDemote: true,
    evaluationPeriodForPromotion: 20,
    compliancePromotionLevel: 70,
    complianceDemotionLevel: 30,
    adherencePromotionLevel: 80,
    adherenceDemotionLevel: 40,
    isAPrivateNetworkProtocol: false,
};

export const DEFAULT_EXTREME_PROTOCOL: Protocol = {
    id: DEFAULT_EASY_PROTOCOL_ID,
    name: 'Willow Wellness',
    metrics: extremeMetrics,
    habits: [
        { name: 'action-water-0', type: LIFESTYLE, displayName: 'water', context: '12 glasses', description: waterDescription },
        { name: 'action-sunlight-0', type: LIFESTYLE, displayName: 'sunlight', context: '10 min.', description: sunlightDescription },
        { name: 'action-book-reading-0', type: LIFESTYLE, displayName: 'book reading', context: '20 min.', description: readingDescription },
        { name: 'action-meditation-0', type: LIFESTYLE, displayName: 'meditation', context: '10 min.', description: meditationDescription },
        { name: 'action-alcohol-0', type: LIFESTYLE, displayName: 'alcohol', context: 'avoided', description: alcoholDescription },
        { name: 'action-steps-0', type: EXERCISE, displayName: 'steps', context: '7500', description: stepsDescription },
        { name: 'action-strength-training-0', type: EXERCISE, displayName: 'strength training', context: '45 min.', description: strengthTrainingDescription },
        { name: 'action-intense-cardio-0', type: EXERCISE, displayName: 'intense cardio', context: '10 min.', description: intenseCadioDescription },
        { name: 'action-stretch-0', type: EXERCISE, displayName: 'stretch', context: '20 min.', description: stretchingDescription },
        { name: 'action-added-sugar-0', type: NUTRITION, displayName: 'added sugar', context: 'avoided', description: addedSugar },
        { name: 'action-green-vegetables-0', type: NUTRITION, displayName: 'green vegetables', context: '6 serv.', description: greenVegetablesDescription },
        { name: 'action-intermittent-fasting-0', type: NUTRITION, displayName: 'intermittent fasting', context: '16/8', description: intermittentFastingDescription },
        { name: 'action-refined-carbs-0', type: NUTRITION, displayName: 'refined carbs', context: 'avoided', description: refinedCarbsDescriptions },
        { name: 'action-multivitamin-0', type: SUPPLEMENT, displayName: 'multivitamin', context: '1 serv.' },
    ],
    level: 3,
    canPromote: false,
    canDemote: true,
    evaluationPeriodForPromotion: 20,
    compliancePromotionLevel: 70,
    complianceDemotionLevel: 30,
    adherencePromotionLevel: 80,
    adherenceDemotionLevel: 40,
    isAPrivateNetworkProtocol: false,
};