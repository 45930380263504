import React from 'react';
import { ComponentType } from 'react';

import { HABIT_CATEGORIES } from '../../../Constants';
import { connect } from 'react-redux';
import type { Protocol, Habit, Metric, ContentObject, AuthObject } from '../../../flowTypes';



import sunIcon from '../../../assets/svgs/sunlight.svg';
import moonIcon from '../../../assets/svgs/moonlight.svg';
import crossIcon from '../../../assets/svgs/circle-x-fade.svg';
import checkIcon from '../../../assets/svgs/circle-check-fade.svg';
import eyeIcon from '../../../assets/svgs/eye-blue.svg';
// import editIcon from '../../../assets/svgs/edit-white.svg';
import arrowUp from '../../../assets/svgs/arrow-up-right.svg';
import _ from 'lodash';

type Props = {
    protocolLevels: Protocol[],
    protocolId: string,
    protocolName: string,
    onDone: () => void,
    content: ContentObject,
    auth: AuthObject,
    onAssignProtocol: (protocol) => void,
    onEditProtocol: (id) => void,
};

export const Quality: ComponentType<Metric> = ({ displayName, isNumericMetric, logTimes }) => {
    return (
        <div className="quality-tile" key={displayName}>
            <div className="full-width">
                <span className="quality-name">{_.take(displayName, 14)}</span>
                <span className="quality-type">{isNumericMetric ? '(number)' : '(slider)'}</span>
            </div>
            {!isNumericMetric ? (
                <div className="quality-icon" style={{minWidth: (logTimes.includes('AM') && logTimes.includes('PM')) ? 55 : 0}}>
                    {logTimes.includes('AM') ? <img className="image-icon" src={sunIcon} alt="sun icon" /> : null}
                    {logTimes.includes('PM') ? <img className="image-icon" src={moonIcon} alt="moon icon" /> : null}
                </div>
            ) : (
                <p className="quality-bar">######</p>
            )}
        </div>
    );
};

export const Action: ComponentType<Habit> = ({ context, displayName }) => {
    return (
        <div className="action-tile" key={displayName}>
            <div className="details">
                <div className="context">{context}</div>
                <div className="name">{displayName}</div>
            </div>
            <div className="ms-auto">
                <img className="image-icon small-icon left" src={crossIcon} alt="check icon" />
                <img className="image-icon small-icon" src={checkIcon} alt="cross icon" />
            </div>
        </div>
    );
};

type State = {
    currentLevel: number,
};

export class ProtocolPreview extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentLevel: 0,
        };
    }

    render() {
        const { currentLevel } = this.state;
        const {
            onDone = _.noop,
            protocolLevels = [],
            onAssignProtocol = _.noop,
            // onEditProtocol = () => { },
            protocolName = '',
            // protocolId = '',
        } = this.props;
        const currentProtocol = protocolLevels[currentLevel] || {};
        const qualities = currentProtocol.metrics || [];
        const actionCategories = _.groupBy(
            (currentProtocol.habits || []).map((habit, id) => ({ ...habit, id, type: habit.type.toLowerCase() })),
            'type'
        );

        return (
            <>
                <div className="modal-container modal-md" style={{ overflowY: 'auto' }}>
                    <div className="modal-head">
                        <div className="modal-icon-container">
                            <img src={eyeIcon} className="modal-icon" alt="modal icon" />
                        </div>
                        <p testid="modal-title" className="modal-title">
                            {`${protocolName} Preview`}
                        </p>
                    </div>
                    <div className="">
                        <div className="modal-bd ">
                            <div className="d-flex flex-column ">
                                <div className="section-level d-flex mb-3 justify-content-center">
                                    {protocolLevels.length
                                        ? protocolLevels.map((level, index) => (
                                            <div
                                                testid={`level-button-${index}`}
                                                key={`level-button-${index}`}
                                                onClick={() => this.setState({ currentLevel: index })}
                                                className={
                                                    index === currentLevel ? 'level-btn-current active' : 'level-btn'
                                                }
                                            >
                                                {index === currentLevel ? `Level ${index + 1}` : index + 1}
                                            </div>
                                        ))
                                        : null}
                                </div>
                            </div>
                            <div className="d-flex flex-column " style={{ maxHeight: 300, overflow: 'scroll' }}>
                                <p className="quality-header">Qualities</p>
                                <div className="metric-list">
                                    {qualities.map((quality, i) => (
                                        <div key={quality.displayName} style={{width: '48%'}}>
                                            <Quality {...quality} />
                                        </div>
                                    ))}
                                </div>

                                <p className="quality-header">Actions</p>
                                {HABIT_CATEGORIES.map((category) =>
                                    actionCategories[category] ? (
                                        <div className="qualities-list" key={category}>
                                            <p className="quality-header light" style={{marginBottom: 5}}>{_.capitalize(category)}</p>
                                            {actionCategories[category].map((action, i) => (
                                                <div key={action.displayName}>
                                                    <Action {...action} />
                                                </div>
                                            ))}
                                        </div>
                                    ) : null
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-center mt-4">
                                <div className="d-flex flex-row space-around">
                                    {/* <button
                                        testid="edit-button"
                                        className="btn btn-extra btn-lg app-btn-primary btn-48"
                                        onClick={() => onEditProtocol(protocolId, protocolLevels, protocolName)}
                                    >
                                        Edit
                                        <img className="image-icon" alt="edit icon" src={editIcon} />
                                    </button> */}
                                    <button
                                        testid="assign-button"
                                        className="btn btn-extra btn-block btn-lg app-btn-secondary btn-48"
                                        onClick={() => onAssignProtocol(currentProtocol)}
                                        style={{width: '100%'}}
                                    >
                                        Assign
                                        <img className="image-icon" alt="edit icon" src={arrowUp} />
                                    </button>
                                </div>

                                <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth, content }) => ({ auth, content });

const connectedPreview = connect(mapStateToProps, {})(ProtocolPreview);

export default connectedPreview;
