// @flow
import React from 'react';
import type { AuthObject } from '../../flowTypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';

type Props = {
    auth: AuthObject,
    location: { pathname: string }
};

export class TopNav extends React.Component<Props> {
    render(): any {
        const { networkKey } = this.props.auth || {};
        let { pathname } = this.props.location || {};
        pathname = pathname && pathname.length ? pathname.substring(1) : '';
        const subtitle = pathname.split('/')[1];
        const name = pathname.split('/')[0];

        return (
            <div className="top-nav">
                <div testid="logo-container" className="logo-container">
                    {/* <span className="network-logo">{networkKey[0]}</span> */}
                    {!isMobile && <span testid="network-name" className="network-name">
                        {networkKey === 'Church' ? 'Whetstone' : (networkKey || 'network')}
                    </span>}
                </div>
                <span testid="current-page" className={`${!isMobile ? '' : 'mb-2'} curr-page`}>
                    <div>
                        {_.capitalize(name || 'Dashboard')}
                    </div>
                    {Boolean(subtitle) && <div className="curr-page-subtitle">
                        {_.capitalize(subtitle)}
                    </div>}
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const connected: any = connect(mapStateToProps, {})(withRouter(TopNav));
export default connected;
