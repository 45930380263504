// @flow
import React, { useState } from 'react';


import plusIconMain from '../../../assets/svgs/plus-blue.svg';

import type { ComponentType } from 'react';
import type { Metric } from '../../../flowTypes';
import _ from 'lodash';

type MetricFormProps = {
    addMetric:  (metric: Metric, oldMetricName?: string) => void; 
    onDone: ()=> void;
    metric: Metric;
    level: number
};

export const MetricForm: ComponentType<MetricFormProps> = ({ onDone = _.noop, addMetric = _.noop, metric, level = 0 }) => {
    const [name, setName]= useState(metric?.displayName || "");
    const [isNumeric, setIsNumber] = useState(metric?.isNumericMetric || false);
    const [logTimes, setLogTimes] = useState(metric?.logTimes || ["AM"]);
    const [queryType, setQuetyType]= useState(metric?.query || "present");
    const [activeDays, setActiveDays] = useState(metric?.daysToExcludeInPrompting || []);
    
    const oldMetricName = metric?.name;
    
    const onSaveMetric = ()=>{
        const days = [1, 2, 3, 4, 5, 6, 7];
        const daysToExcludeInPrompting = activeDays.length? days.filter((day)=> !activeDays.includes(day)): []
        const metric: Metric = {
            displayName: name,
            name: `quality ${name} ${level}`.toLocaleLowerCase().split(" ").join('-'),
            isNumericMetric: isNumeric,
            logTimes,
            query: queryType,
            daysToExcludeInPrompting
        }

        addMetric(metric, oldMetricName);
    }
   
    const setDaysActive = (day: null  | number) =>{
        if(day === null){
            setActiveDays([]);
            return
        }
        const activeDaysCopy = [...activeDays];
        const dayIndex = activeDaysCopy.indexOf(day);
        if(dayIndex > -1){
            activeDaysCopy.splice(dayIndex, 1);
            setActiveDays(activeDaysCopy);

        }
        else{
            activeDaysCopy.push(day);
            setActiveDays(activeDaysCopy.length === 7 ? [] :  activeDaysCopy);
        }
    }

    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={plusIconMain} className="modal-icon" alt="modal icon" />
                </div>
                <p testid="modal-title" className="modal-title">
                Add Quality
                </p>
            </div>
            <div className="">
            <>
                 <p  className="modal-description">
                 Qualities are the outcomes variables members track and improve by doing actions.
                </p>
                <div className="modal-bd modal-bd-short">
                    <div className="d-flex flex-column justify-content-center">
                        
                        <div className="form-group">
                            <label>What quality to improve*</label>
                            <input
                                placeholder="sleep, mood, output, weight, etc."
                                testid="name-input"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{color: name ? 'black': '#94A3B8'}}
                            />
                        </div>
                        <div className="form-group">
                            <label>How it’s measured</label>
                            <div className="form-control d-flex p-0">
                                <div testid="nonNumeric" style={{borderTopLeftRadius: 4, borderBottomLeftRadius: 4}} className={`form-control option ${!isNumeric ? 'active': ''}`} onClick = {()=> setIsNumber(false)}>
                                    Slider
                                </div>
                                <div testid="numeric" style={{borderTopRightRadius: 4, borderBottomRightRadius: 4}} className={`form-control option ${isNumeric? 'active': ''}`} onClick = {()=> setIsNumber(true)}>
                                    Number
                                </div>
                            </div>
                        </div>
                        {!isNumeric && <><div className="form-group">
                            <label>When it’s asked</label>
                            <div className="form-control d-flex p-0">
                                
                                <div testid="morning-time" style={{borderTopLeftRadius: 4, borderBottomLeftRadius: 4}} className={`form-control option ${(logTimes.includes('AM') && !logTimes.includes('PM'))? 'active': ''}`} onClick = {()=> setLogTimes(['AM'])}>
                                    Morning
                                </div>
                                <div testid="evening-time" className={`form-control option ${(logTimes.includes('PM') && !logTimes.includes('AM')) ? 'active': ''}`} onClick = {()=> setLogTimes(['PM'])}>
                                    Evening
                                </div>
                                <div testid="both-time" style={{borderTopRightRadius: 4, borderBottomRightRadius: 4}} className={`form-control option ${logTimes.includes('AM') && logTimes.includes('PM') ? 'active': ''}`} onClick = {()=> setLogTimes(['AM','PM'])}>
                                    Both
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>How it’s asked</label>
                            <div  className="form-control d-flex p-0">
                                <div testid="present" style={{borderTopLeftRadius: 4, borderBottomLeftRadius: 4}} className={`form-control option ${queryType === "present" ? 'active': ''}`} onClick = {()=> setQuetyType("present")}>
                                    How is your ___ ?
                                </div>
                                <div testid="past" style={{borderTopRightRadius: 4, borderBottomRightRadius: 4}} className={`form-control option ${queryType === "past" ? 'active': ''}`} onClick = {()=> setQuetyType("past")}>
                                    How was your ___ ?
                                </div>
                            </div>
                        </div></>}
                        <div className="form-group">
                            <label>Days active</label>
                            <div className="form-control d-flex p-0">
                                <div testid="all-day" style={{paddingLeft: 0, paddingRight: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: 4}} className={`form-control option ${!activeDays || !activeDays.length ? 'active': ''}`} onClick = {()=> setDaysActive(null)}>
                                  All
                                </div>
                                <div testid="sunday" style={{paddingLeft: 0, paddingRight: 0}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(1) ? 'active': ''}`} onClick = {()=> setDaysActive(1)}>
                                    Sun
                                </div>
                                <div testid="monday" style={{paddingLeft: 0, paddingRight: 0}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(2) ? 'active': ''}`} onClick = {()=> setDaysActive(2)}>
                                    Mon
                                </div>
                                <div testid="tuesday" style={{paddingLeft: 0, paddingRight: 0}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(3) ? 'active': ''}`} onClick = {()=> setDaysActive(3)}>
                                    Tue
                                </div>
                                <div testid="wednesday" style={{paddingLeft: 0, paddingRight: 0}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(4) ? 'active': ''}`} onClick = {()=> setDaysActive(4)}>
                                    Wed
                                </div>
                                <div testid="thursday" style={{paddingLeft: 0, paddingRight: 0}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(5) ? 'active': ''}`} onClick = {()=> setDaysActive(5)}>
                                    Thur
                                </div>
                                <div testid="friday" style={{paddingLeft: 0, paddingRight: 0}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(6) ? 'active': ''}`} onClick = {()=> setDaysActive(6)}>
                                    Fri
                                </div>
                                <div testid="saturday" style={{paddingLeft: 0, paddingRight: 0, borderTopRightRadius: 4, borderBottomRightRadius: 4}} className={`form-control option ${activeDays.length !==7 && activeDays.includes(7) ? 'active': ''}`} onClick = {()=> setDaysActive(7)}>
                                    Sat
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center mt-4">
                            <button
                                testid="submit-button"
                                className="btn btn-block btn-lg app-btn-secondary mt-3"
                                onClick={() => onSaveMetric()}
                                disabled={!name}
                            >
                                Save
                            </button>
                            <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                Cancel
                            </button>
                </div>
                </>
            </div>
            
        </div>
    );
};
