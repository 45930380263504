// @flow
import 'bootstrap/dist/css/bootstrap.css';
import '../../../general.scss';
import 'react-circular-progressbar/dist/styles.css';
import React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { fetchMetricLogsForYear, fetchHabitLogsForYear, fetchUsers } from '../../../actions';
import {
    getLoadingIndicator,
    getShortCalendarFormatForDate,
    periodToPriorAndCurentTimestamp,
    getCalendarTooltip,
    getTimeAdjustedTrend,
    matchFilter,
} from '../../UI Resources';
import _, { toInteger } from 'lodash';
import { METRIC_SCORE_MAPPING, QUALITIES_INDIVIDUAL_SCORES_COLORS } from '../../../Constants';
import exportIcon from '../../../assets/svgs/export.svg';

import searchIcon from '../../../assets/svgs/search.svg';
import caretDownIcon from '../../../assets/svgs/caret-down.svg';
import caretUpIcon from '../../../assets/svgs/caret-up.svg';
import checkboxCheck from '../../../assets/svgs/checkbox-check.svg';
import checkbox from '../../../assets/svgs/checkbox.svg';
import arrowUp from '../../../assets/svgs/up-arrow.svg';
import arrowDown from '../../../assets/svgs/down-arrow.svg';
import { VictoryChart, VictoryGroup, VictoryBar, VictoryLabel, VictoryLine, VictoryLegend, VictoryAxis } from 'victory';
import {
    CircularProgressbarWithChildren as CircularProgressbar,
    CircularProgressbar as CircleProgress,
} from 'react-circular-progressbar';

import type { AuthObject, MetricScoreInCollection, HabitScoreInCollection, CurrentUser } from '../../../flowTypes';
import type { ComponentType } from 'react';
import { Dropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const font = 'Inter';
type Props = {
    location: any,
    auth: AuthObject,
    metricLogs: Array<MetricScoreInCollection>,
    habitLogs: Array<HabitScoreInCollection>,
    metricLogsInitialized: boolean,
    habitLogsInitialized: boolean,
    path: any,
    fetchMetricLogsForYear: (networkKey: string) => void,
    fetchHabitLogsForYear: (networkKey: string) => void,
    fetchUsers: () => void,
    chosenProtocolsMapping: { [key: string]: string },
    showNotificationModal: boolean,
    currentUsers: any,
};
type sortOrder = '' | 'asc' | 'desc';
type ChartType = 'scores' | 'trends';
type Period = { number: number, period: string };
type State = {
    chartType: ChartType,
    priorSortOrder: sortOrder,
    currentSortOrder: sortOrder,
    changeSortOrder: sortOrder,
    logSortOrder: sortOrder,
    selectedUserId: string,
    adherenceSortOrder: sortOrder,
    currentPeriod: Period,
    dispositionFilter: string,
    memberFilter: string[],
    qualityFilter: string[],
    numericQualityFilter: boolean,
    selectedUser: CurrentUser,
    adherenceFilter: string,
    showNotificationModal: boolean,
    showBroadcastModal: boolean,
    searchString: string,
    memberSearch: string,
};

export class Outcomes extends React.Component<Props, State> {
    dateFilter: Period[] = [
        { number: 1, period: 'y' },
        { number: 6, period: 'M' },
        { number: 3, period: 'M' },
        { number: 1, period: 'M' },
        { number: 2, period: 'w' },
        { number: 1, period: 'w' },
    ];
    constructor(props: Props) {
        super(props);
        this.state = {
            priorSortOrder: '',
            currentSortOrder: '',
            changeSortOrder: '',
            logSortOrder: '',
            memberFilter: [],
            qualityFilter: [],
            numericQualityFilter: false,
            adherenceSortOrder: '',
            currentPeriod: { number: 2, period: 'w' },
            selectedUser: {},
            selectedUserId: '',
            dispositionFilter: '',
            adherenceFilter: '',
            showNotificationModal: false,
            memberSearch: '',
            showBroadcastModal: false,
            chartType: 'scores',
            searchString: '',
        };
    }

    componentDidMount() {
        const {
            metricLogs,
            habitLogs,
            auth = {},
            fetchMetricLogsForYear,
            fetchHabitLogsForYear,
            currentUsers = {},
            fetchUsers,
        } = this.props;
        const { networkKey } = auth;
        if (!metricLogs?.length) {
            fetchMetricLogsForYear(networkKey);
        }
        if (!habitLogs?.length) {
            fetchHabitLogsForYear(networkKey);
        }
        if (!Object.keys(currentUsers).length) {
            fetchUsers();
        }
    }

    _toggleMemberFilter(userid: string): any {
        const { memberFilter } = this.state;
        const index = memberFilter.indexOf(userid);
        index > -1 ? memberFilter.splice(index, 1) : memberFilter.push(userid);
        this.setState({ memberFilter });
    }
    _toggleQualityFilter(metricName: string): any {
        const { qualityFilter, numericQualityFilter } = this.state;
        if (numericQualityFilter) {
            this.setState({ qualityFilter: [metricName], numericQualityFilter: false });
            return;
        }
        const index = qualityFilter.indexOf(metricName);
        index > -1 ? qualityFilter.splice(index, 1) : qualityFilter.push(metricName);
        this.setState({ qualityFilter, numericQualityFilter: false });
    }
    _toggleNumericQualityFilter(metricName: string): any {
        const { qualityFilter } = this.state;
        const index = qualityFilter.indexOf(metricName);
        this.setState({ qualityFilter: index > -1 ? [] : [metricName], numericQualityFilter: !(index > -1) });
    }

    _getUserScoreCard(): any {
        const { currentPeriod, memberFilter } = this.state;
        const { metricLogs = [], currentUsers = {} } = this.props;
        const { current, prior } = periodToPriorAndCurentTimestamp(currentPeriod);

        const currentUnfilteredMetricLogs = metricLogs.filter(log => matchFilter(log, currentUsers, current.start, current.end));
        const priorUnfilteredMetricLogs = metricLogs.filter(log => matchFilter(log, currentUsers, prior.start, prior.end));

        const currentLogs = this._applyMetricFilter(memberFilter.length ? currentUnfilteredMetricLogs.filter(log => memberFilter.includes(log.uid)) : currentUnfilteredMetricLogs);
        const priorLogs = this._applyMetricFilter(memberFilter.length ? priorUnfilteredMetricLogs.filter(log => memberFilter.includes(log.uid)) : priorUnfilteredMetricLogs);

        const allMetrics = _.uniqBy(currentUnfilteredMetricLogs, 'metricName').map(log => ({ metricName: log.metricName, isNumericMetric: log.isNumericMetric, displayName: log.displayName }));
        const allUsers = _.uniqBy(currentUnfilteredMetricLogs, 'uid').filter(({ uid }) => currentUsers[uid]).map(
            ({ uid }) => ({
                name: `${currentUsers[uid]?.loginInfo?.firstName} ${_.take(currentUsers[uid]?.loginInfo?.lastName)}.`,
                uid
            })).sort((a, b) => a.name.localeCompare(b.name));

        const currentOverallMean = currentLogs.length ? (currentLogs.reduce(
            (sum, log) => sum + (log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore]),
            0
        ) / currentLogs.length) : 0;

        const priorOverallMean = priorLogs.length ? (priorLogs.reduce(
            (sum, log) => sum + (log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore]),
            0
        ) / priorLogs.length) : 0;

        const overallLength = currentLogs.length;

        const metricNames = _.uniqBy([...currentLogs, ...priorLogs], 'metricName').map(log => ({ metricName: log.metricName, displayName: log.displayName, isNumericMetric: log.isNumericMetric }));
        const logsByMetricName = {};
        for (const log of currentLogs) {
            logsByMetricName[log.metricName] = [...(logsByMetricName[log.metricName] || []), log];
        }

        const priorLogsByMetricName = {};
        for (const log of priorLogs) {
            priorLogsByMetricName[log.metricName] = [...(priorLogsByMetricName[log.metricName] || []), log];
        }

        const logsByUser: { [uid: string]: MetricScoreInCollection[] } = {};
        for (const log of currentLogs) {
            logsByUser[log.uid] = [...(logsByUser[log.uid] || []), log];
        }

        const metricList = metricNames.map(({ metricName, displayName, isNumericMetric }) => {

            const divisor = isNumericMetric ? 1 : 5;
            const currentScore = (logsByMetricName[metricName] || []).length ? _.mean(logsByMetricName[metricName].map(log => log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore])) : 0;
            const priorScore = (priorLogsByMetricName[metricName] || []).length ? _.mean(priorLogsByMetricName[metricName].map(log => log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore])) : 0;

            const metricInfo = {
                metricName,
                displayName,
                logs: (logsByMetricName[metricName] || []).length,
                currentScore,
                priorScore,
                change: priorScore ? ((currentScore - priorScore) / divisor) : (currentScore / divisor),
                trend: (logsByMetricName[metricName] || []).reduce((trendList, { metricScore, numericScore, date }) => ({
                    ...trendList, [date]: [...(trendList[date] || []), numericScore ?? METRIC_SCORE_MAPPING[metricScore]]
                }), {}),
            };

            return metricInfo;
        });

        let userList = Object.keys(logsByUser).map((uid) => ({
            name: `${currentUsers[uid]?.loginInfo?.firstName} ${_.take(currentUsers[uid]?.loginInfo?.lastName)}`,
            uid,
            score: logsByUser[uid].reduce((sum, log) => sum + (log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore]), 0) / logsByUser[uid].length,
        }));
        userList = _.sortBy(userList, (user) => user.score);
        const topUsers = _.take(_.reverse(_.sortBy(userList, 'score')), 5);
        const bottomUsers = _.takeRight(_.reverse(_.sortBy(userList, 'score').filter(user => !topUsers.includes(user))), 5);
        metricList.sort((log1, log2) => log1.metricName.localeCompare(log2.metricName));
        const sortedMetrics = this._applySorts(metricList);

        return {
            allMetrics,
            metricList: sortedMetrics,
            topUsers,
            bottomUsers,
            priorOverallMean,
            currentOverallMean,
            overallLength,
            userList,
            allUsers,
        };
    }

    _getCSVData(): string[][] {
        const { metricList } = this._getUserScoreCard();
        const header = ['Metric', 'Prior Period', 'Current Period', 'Change', 'Adherence', 'Logs'];
        const csvArray = metricList.map(({ displayName, currentScore, priorScore, change, logs }) => {
            return [displayName, priorScore, currentScore, change, logs];
        });

        return [header].concat(csvArray);
    }

    _toggleSort: (sortOrder: string) => void = (sortOrder) => {
        const currentSortOder = this.state[sortOrder];
        this.setState({
            priorSortOrder: '',
            currentSortOrder: '',
            changeSortOrder: '',
            logSortOrder: '',
        });

        let newSortValue = '';
        switch (currentSortOder) {
            case 'asc':
                newSortValue = 'desc';
                break;
            case 'desc':
                newSortValue = '';
                break;
            default:
                newSortValue = 'asc';
        }
        this.setState({ [sortOrder]: newSortValue });
    };
    _applySorts(usersList: any[]): any {
        const { logSortOrder, currentSortOrder, priorSortOrder, changeSortOrder } = this.state;
        const sortFunctions = [];
        const sortDirections = [];
        if (logSortOrder) {
            sortFunctions.push((user) => user.logs);
            sortDirections.push(logSortOrder);
        }
        if (currentSortOrder) {
            sortFunctions.push((user) => user.currentScore);
            sortDirections.push(currentSortOrder);
        }
        if (priorSortOrder) {
            sortFunctions.push((user) => user.priorScore);
            sortDirections.push(priorSortOrder);
        }
        if (changeSortOrder) {
            sortFunctions.push((user) => user.change);
            sortDirections.push(changeSortOrder);
        }

        if (priorSortOrder || changeSortOrder || currentSortOrder || logSortOrder) {
            usersList = _.orderBy(usersList, sortFunctions, sortDirections);
        }

        return usersList;
    }

    _applyMetricFilter(metricLogs: any[]): any {
        const { qualityFilter } = this.state;
        if (!qualityFilter.length) {
            return metricLogs.filter(log => !log.isNumericMetric);
        }
        const filteredMetrics = metricLogs.filter(({ metricName }) => qualityFilter.includes(metricName));
        return filteredMetrics;
    }

    _getProgressColor(score: number): any {
        switch (true) {
            case score <= 1:
                return '#DC2626';
            case score <= 2:
                return '#FBBF24';
            case score <= 3:
                return '#94A3B8';
            case score <= 4:
                return '#3460DC';
            default:
                return '#10B981';
        }
    }

    _renderUserList(nonNumericList: any[]): any {
        const { metricLogsInitialized, habitLogsInitialized } = this.props;
        const { numericQualityFilter } = this.state;
        if (!metricLogsInitialized || !habitLogsInitialized) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        <div className="mx-auto d-inline-block">
                            {getLoadingIndicator()}
                        </div>
                    </td>
                </tr>
            );
        }

        if (!nonNumericList.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        No data found
                    </td>
                </tr>
            );
        }
        return nonNumericList.map(({ displayName, currentScore, priorScore, change, logs }, index) => (
            <tr className="outcome-row" testid="scorecard-row" key={`user-${index}`}>
                <td className="ps-4" testid={`displayName-${index}`}>{displayName}</td>
                {!isMobile && <td className="text-center">
                    {numericQualityFilter
                        ? <span className="text-large">{priorScore.toFixed(2)}</span>
                        : <><span className="text-large">{`${parseInt((priorScore / 5) * 100)}%`}</span>
                            <span className="text-large-dull">{priorScore.toFixed(1)}</span></>}
                </td>}
                <td className="text-center">
                    {numericQualityFilter
                        ? <span className="text-large">{currentScore?.toFixed(2)}</span>
                        : <><span className="text-large">{`${parseInt((currentScore / 5) * 100)}%`}</span>
                            <span className="text-large-dull">{currentScore.toFixed(1)}</span></>}
                </td>
                {!isMobile && <>
                    <td className="text-center">
                        <div className="inline-content">
                            <img
                                className="image-icon ms-0"
                                src={Math.sign(change) === 1 ? arrowUp : arrowDown}
                                alt="up or down icon"
                            />
                            <div className={`disposition-value ${Math.sign(change) === 1 ? 'positive' : 'negative'}`}>
                                {numericQualityFilter ? change.toFixed(2) : `${parseInt(change * 100)}%`}
                            </div>
                        </div>
                    </td>
                    <td className="text-center"><b>{logs}</b></td>
                </>}
            </tr>
        ));
    }

    render(): any {
        const {
            currentSortOrder,
            priorSortOrder,
            logSortOrder,
            changeSortOrder,
            currentPeriod,
            chartType,
            memberFilter,
            qualityFilter,
            memberSearch,
            numericQualityFilter,
        } = this.state;
        const {
            topUsers,
            bottomUsers,
            priorOverallMean,
            currentOverallMean,
            overallLength,
            metricList,
            allMetrics,
            allUsers,
        } = this._getUserScoreCard();
        const meanOverall = currentOverallMean;
        const meanPrior = priorOverallMean;
        const meanChange = meanPrior ? (meanOverall - meanPrior) / meanPrior : 0;
        const overallPercentage = parseInt((meanOverall / 5) * 100);
        const priorPercentage = parseInt((meanPrior / 5) * 100);
        let renderedList = metricList;
        renderedList = _.filter(renderedList, 'logs');

        const filteredAVG = _.mean(renderedList.map(({ currentScore }) => currentScore));
        const filterdPriorAVG = _.mean(renderedList.map(({ priorScore }) => priorScore));
        const filterdChange = filterdPriorAVG ? (filteredAVG - filterdPriorAVG) / filterdPriorAVG : 0;
        const summaryLabel = qualityFilter.length
            ? qualityFilter.length === 1
                ? renderedList.find(({ metricName }) => metricName === qualityFilter[0])?.displayName
                : 'Average'
            : 'Overall';
        const searchedMembers = memberSearch
            ? allUsers.filter(
                ({ uid, name }) =>
                    uid.toLowerCase().indexOf(memberSearch.toLowerCase()) > -1 ||
                    name.toLowerCase().indexOf(memberSearch.toLowerCase()) > -1
            )
            : allUsers;
        const minQuality = _.minBy(renderedList, (quality) => quality.currentScore) || {};
        const maxQuality = _.maxBy(renderedList, (quality) => quality.currentScore) || {};
        const maxQualityChange = _.maxBy(renderedList, (quality) => quality.change) || {};
        const minQualityChange = _.minBy(renderedList, (quality) => quality.change) || {};
        const change = parseInt((qualityFilter.length ? filterdChange : meanChange) * 100);
        renderedList = _.take(renderedList, 10);
        const legendData = chartType === "trends"
            ? renderedList.map(({ displayName }, i) => ({ name: displayName, symbol: { fill: QUALITIES_INDIVIDUAL_SCORES_COLORS[i], type: 'square' } }))
            : [{ name: "prior period", symbol: { fill: "#CBD5E1", type: 'square' } }, { name: "current period", symbol: { fill: "#1D4ED8", type: 'square' } }];

        const legendRows = toInteger(legendData.length / 4);
        return (
            <div className="manual-container">
                <div className={!isMobile ? 'd-flex justify-content-between align-content-end' : ''}>
                    <div className={`align-self-end ${!isMobile ? '' : 'mb-2'}`}>
                        <button
                            testid='see-scores-button'
                            onClick={() => this.setState({ chartType: 'scores' })}
                            className={`btn  btn-sm toggle-button ${chartType === 'scores' ? 'active' : ''}`}
                            style={{ fontWeight: chartType === 'scores' ? 600 : 'normal' }}
                        >
                            Scores
                        </button>
                        <button
                            testid='see-trends-button'
                            onClick={() => this.setState({ chartType: 'trends' })}
                            className={`btn btn-sm ms-2 toggle-button ${chartType === 'trends' ? 'active' : ''}`}
                            style={{ fontWeight: chartType === 'trends' ? 600 : 'normal' }}
                        >
                            Trends
                        </button>
                    </div>
                    <div className={`d-flex ${!isMobile ? '' : 'mb-2'}`}>
                        <Dropdown drop="right" className=" " autoClose="outside">
                            <Dropdown.Toggle className={`btn btn-sm toggle-button`} style={{ color: '#475569' }}>
                                {`QUALITIES ${qualityFilter.length ? '*' : ''}`}
                                <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item testid='all-metrics-item' onClick={() => this.setState({ qualityFilter: [], numericQualityFilter: false })}>
                                    All Slider Qualities
                                </Dropdown.Item>
                                {_.sortBy(allMetrics, 'displayName').filter(metric => !metric.isNumericMetric).map(({ metricName, displayName }, i) => (
                                    <Dropdown.Item
                                        key={`action-items-${metricName}`}
                                        testid={`action-items-${metricName}`}
                                        onClick={() => this._toggleQualityFilter(metricName)}
                                    >
                                        <img
                                            className="image-icon me-4"
                                            src={qualityFilter.includes(metricName) ? checkboxCheck : checkbox}
                                            alt="check icon"
                                        />
                                        {displayName}
                                    </Dropdown.Item>
                                ))}
                                {Boolean(allMetrics.filter(metric => metric.isNumericMetric).length) && <Dropdown.Header className="dropdown-head">
                                    <span>Numeric Qualities</span>
                                </Dropdown.Header>}
                                {_.sortBy(allMetrics, 'displayName').filter(metric => metric.isNumericMetric).map(({ metricName, displayName }, i) => (
                                    <Dropdown.Item
                                        key={`actions-items-${metricName}`}
                                        testid={`action-items-${metricName}`}
                                        onClick={() => this._toggleNumericQualityFilter(metricName)}
                                    >
                                        <img
                                            className="image-icon me-4"
                                            src={qualityFilter.includes(metricName) ? checkboxCheck : checkbox}
                                            alt="check icon"
                                        />
                                        {displayName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown drop="right" className=" " autoClose="outside">
                            <Dropdown.Toggle className={`btn btn-sm ms-2 toggle-button`} style={{ color: '#475569' }}>
                                {memberFilter.length ? 'MEMBERS *' : 'MEMBERS'}
                                <img src={caretUpIcon} className="image-icon button-icon ms-2" alt="caret up" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Header>
                                    <div className="search-uid">
                                        <img className="image-icon " src={searchIcon} alt="search icon" />

                                        <input
                                            testid="search-member"
                                            onChange={(e) => this.setState({ memberSearch: e.target.value })}
                                            type="text"
                                            placeholder="Search"
                                            className="form-input"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </div>
                                </Dropdown.Header>
                                <Dropdown.Item testid='clear-member-search' onClick={() => this.setState({ memberFilter: [] })}>
                                    All members{' '}
                                </Dropdown.Item>
                                {searchedMembers.map(({ uid, name }, i) => (
                                    <Dropdown.Item key={`member-item-${uid}`} testid={`member-item-${uid}`} onClick={() => this._toggleMemberFilter(uid)}>
                                        <img
                                            className="image-icon me-4"
                                            src={memberFilter.includes(uid) ? checkboxCheck : checkbox}
                                            alt="check icon"
                                        />
                                        {name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="period-filter-container">
                            {this.dateFilter.map(({ number, period }, index) => (
                                <div
                                    testid={`period-filter-${index}`}
                                    key={`period-filter-${index}`}
                                    className={`period-filter ${number === currentPeriod.number && period === currentPeriod.period
                                        ? 'active'
                                        : ''
                                        }`}
                                    onClick={() => this.setState({ currentPeriod: { number, period } })}
                                >
                                    {`${number}${period.toUpperCase()}`}
                                </div>
                            ))}
                        </div>
                        {!isMobile && <CSVLink testid="download-csv" className="btn app-btn-outline btn-lg" data={this._getCSVData()}>
                            <img className="image-icon button-icon" src={exportIcon} alt="export icon" />
                            Export
                        </CSVLink>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6" style={{ marginTop: '1.75rem' }}>
                        {!_.isEmpty(maxQuality) && <div className="outcome-chart-container d-flex flex-column justify-content-center chart-section">
                            {Boolean(renderedList.length) && (
                                chartType === 'trends' ? (
                                    <VictoryChart padding={{ top: 55 + legendRows * 25, bottom: 50, right: 20, left: 40 }}>
                                        <VictoryLegend x={50} y={0}
                                            title="AVERAGE QUALITY SCORE OVER TIME"
                                            itemsPerRow={4}
                                            orientation="horizontal"
                                            gutter={25}
                                            style={{ title: { fontSize: 12, color: '#475569', fontWeight: 'bold', fontFamily: font }, labels: { fontSize: 12 } }}
                                            data={legendData}
                                        />
                                        <VictoryAxis
                                            testid={'y-axis'}
                                            scale={{ x: 'time' }}
                                            tickFormat={getShortCalendarFormatForDate}
                                            style={{
                                                axis: { stroke: '#dddddd', width: 1 },
                                                tickLabels: {
                                                    stroke: 'transparent',
                                                    fontFamily: font,
                                                    fontSize: 11,
                                                    angle: -45,
                                                    padding: 20,
                                                },
                                                ticks: { stroke: '#dddddd' },
                                            }}
                                        />
                                        <VictoryAxis
                                            dependentAxis
                                            testid={'real-y-axis'}
                                            scale={{ x: 'time' }}
                                            tickValues={numericQualityFilter ? undefined : [1, 2, 3, 4, 5]}
                                            domain={numericQualityFilter ? undefined : [0, 5.1]}
                                            style={{
                                                axis: { stroke: '#dddddd', width: 1 },
                                                tickLabels: { stroke: 'transparent', fontFamily: font, fontSize: 11 },
                                                ticks: { stroke: 'transparent' },
                                                grid: { stroke: '#dddddd', strokeDasharray: [4, 4] },
                                            }}
                                        />
                                        {renderedList.map(({ trend, displayName }, index) => {
                                            const lineData = [];
                                            Object.keys(trend).forEach((key) => {
                                                const data = {
                                                    x: new Date(key),
                                                    y: _.mean(trend[key]),
                                                };
                                                lineData.push(data);
                                            });
                                            return (
                                                <VictoryLine
                                                    animate={false}
                                                    key={`line-chart-${index}`}
                                                    testid={`victory-line-${displayName}`}
                                                    interpolation="basis"
                                                    style={{
                                                        data: {
                                                            stroke: QUALITIES_INDIVIDUAL_SCORES_COLORS[index],
                                                            strokeWidth: 3,
                                                        },
                                                    }}
                                                    data={getTimeAdjustedTrend(lineData, currentPeriod)}
                                                    scale={{ x: 'time' }}
                                                />
                                            );
                                        })}
                                    </VictoryChart>
                                ) : (
                                    <VictoryChart padding={{ top: 55, bottom: 60, right: 10, left: 40 }}>
                                        <VictoryLegend x={50} y={0}
                                            title="AVERAGE QUALITY SCORE BY PERIOD"
                                            itemsPerRow={4}
                                            orientation="horizontal"
                                            gutter={25}
                                            style={{ title: { fontSize: 12, color: '#475569', fontWeight: 'bold', fontFamily: font }, labels: { fontSize: 12 } }}
                                            data={legendData}
                                        />
                                        <VictoryAxis
                                            style={{
                                                axis: { stroke: '#756f6a', fontSize: 3 },
                                                axisLabel: { fontSize: 20, padding: 8 },
                                                ticks: { stroke: 'grey', size: 2 },
                                                tickLabels: { fontSize: 11, padding: 25, angle: -45 },
                                            }}
                                        />
                                        <VictoryAxis
                                            dependentAxis
                                            testid={'real-y-axis'}
                                            tickFormat={tick => toInteger(tick)}
                                            style={{
                                                axis: { stroke: '#dddddd', width: 1 },
                                                axisLabel: { fontSize: 20, padding: 2 },
                                                tickLabels: { stroke: 'transparent', fontFamily: font, fontSize: 12 },
                                                ticks: { stroke: 'transparent' },
                                                grid: { stroke: '#dddddd', strokeDasharray: [4, 4] },
                                            }}
                                            domain={numericQualityFilter ? undefined : [0, 5.1]}
                                        />
                                        <VictoryGroup
                                            animate={true}
                                            offset={108 / renderedList.length}
                                            colorScale="qualitative"
                                        >
                                            <VictoryBar
                                                data={renderedList.map(d => ({ ...d, y0: .03 }))}
                                                x="displayName"
                                                y="priorScore"
                                                style={{
                                                    data: {
                                                        fill: '#CBD5E1',
                                                        strokeWidth: 2,
                                                    },
                                                    labels: {
                                                        fontSize: 4,
                                                        fill: '#0F172A',
                                                    },
                                                }}
                                                cornerRadius={{
                                                    topLeft: 1,
                                                    topRight: 1,
                                                    bottomLeft: 1,
                                                    bottomRight: 1,
                                                }}
                                                labelComponent={<VictoryLabel angle={-45} style={{ fontSize: 5 }} />}
                                                width="100%"
                                            />

                                            <VictoryBar
                                                data={renderedList.map(d => ({ ...d, y0: .03 }))}
                                                x="displayName"
                                                y="currentScore"
                                                style={{
                                                    data: {
                                                        fill: '#1D4ED8',
                                                        strokeWidth: 2,
                                                    },
                                                    labels: {
                                                        fontSize: 4,
                                                        fill: '#0F172A',
                                                    },
                                                }}
                                                cornerRadius={{
                                                    topLeft: 1,
                                                    topRight: 1,
                                                    bottomLeft: 1,
                                                    bottomRight: 1,
                                                }}
                                                width="100%"
                                                labelComponent={<VictoryLabel angle={-45} style={{ fontSize: 5 }} />}
                                            />
                                            {/* renderedList.length <= 10 ? (
                                                <VictoryBar
                                                    data={renderedList}
                                                    x="displayName"
                                                    y="change"
                                                    width="100%"
                                                    barRatio={0.8}
                                                    style={{
                                                        data: {
                                                            fill: '#F7CE46',
                                                            strokeWidth: 2,
                                                        },
                                                        labels: {
                                                            fontSize: 4,
                                                            fill: '#0F172A',
                                                        },
                                                    }}
                                                    cornerRadius={{
                                                        topLeft: 1,
                                                        topRight: 1,
                                                        bottomLeft: 1,
                                                        bottomRight: 1,
                                                    }}
                                                    labelComponent={
                                                        <VictoryLabel angle={-45} style={{ fontSize: 5 }} />
                                                    }
                                                />
                                                ) : null */}
                                        </VictoryGroup>
                                    </VictoryChart>
                                )
                            )}
                        </div>}
                        {!numericQualityFilter && !_.isEmpty(maxQuality) && <div className="row">
                            <div className={`col-12 col-md-12 col-lg-6 ${!isMobile ? 'py-4' : 'pt-4'}`}>
                                {chartType === 'trends' ? (
                                    <div className="d-flex flex-column align-items-center justify-content-center h-100 chart-section">
                                        <p className="circle-chart-title">
                                            {`${summaryLabel.toUpperCase()} QUALITY SCORE`}{' '}
                                        </p>
                                        <div className="change-summary" style={{ color: Math.sign(change) === 1 ? '#173EAD' : '#8EA7EC' }}>
                                            <img
                                                className="image-icon"
                                                alt="arrow icon"
                                                src={Math.sign(change) === 1 ? arrowUp : arrowDown}
                                            />
                                            {`${Math.abs(change)}%`}
                                        </div>
                                        <div className="change d-flex justify-content-between align-self-stretch">
                                            <div>
                                                <p className="prior-title small">PREVIOUS PERIOD</p>
                                                <p className="prior-title small">{`${qualityFilter.length
                                                    ? filterdPriorAVG.toFixed(1)
                                                    : meanPrior.toFixed(1)
                                                    } ${getCalendarTooltip(
                                                        qualityFilter.length ? filterdPriorAVG : meanPrior
                                                    )}`}</p>
                                            </div>
                                            <div className="ms-auto">
                                                <p className="prior-title small">CURRENT PERIOD</p>
                                                <p className="prior-title small">{`${qualityFilter.length
                                                    ? filteredAVG.toFixed(1)
                                                    : meanOverall.toFixed(1)
                                                    } ${getCalendarTooltip(
                                                        qualityFilter.length ? filteredAVG : meanOverall
                                                    )}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column align-items-center justify-content-center h-100 chart-section">
                                        <p className="circle-chart-title">
                                            {`${summaryLabel.toUpperCase()} QUALITY SCORE`}{' '}
                                        </p>
                                        <div className="circle-chart-container">
                                            <CircularProgressbar
                                                styles={{
                                                    path: {
                                                        stroke: this._getProgressColor(
                                                            qualityFilter.length ? filteredAVG : meanOverall
                                                        ),
                                                        strokeLinecap: 'butt',
                                                    },
                                                }}
                                                strokeWidth={10}
                                                value={qualityFilter.length ? filteredAVG * 20 : overallPercentage}
                                            >
                                                <p className="overall-score">
                                                    {qualityFilter.length
                                                        ? filteredAVG.toFixed(1)
                                                        : meanOverall.toFixed(1)}
                                                </p>
                                                <p className="overall-name">
                                                    {getCalendarTooltip(
                                                        qualityFilter.length ? filteredAVG.toFixed(1) : meanOverall
                                                    )}
                                                </p>
                                            </CircularProgressbar>
                                        </div>
                                        <p className="prior-title">PREVIOUS PERIOD</p>
                                        <p className="prior-title">{`${qualityFilter.length ? filterdPriorAVG.toFixed(1) : meanPrior.toFixed(1)
                                            } ${getCalendarTooltip(
                                                qualityFilter.length ? filterdPriorAVG : meanPrior
                                            )}`}</p>
                                    </div>
                                )}
                            </div>
                            <div className={`col-12 col-md-12 col-lg-6 ${!isMobile ? 'py-4' : 'pt-4'}`} style={!isMobile ? { paddingLeft: 0 } : {}}>
                                <div className="d-flex flex-column align-items-center justify-content-center  h-100 chart-section overflow-scroll user-kpi">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="1" className="rank-score top-rank-score" style={{ border: 'none', paddingBottom: 30 }}>
                                                    {memberFilter.length
                                                        ? (chartType === "trends" ? "Most Improved Quality" : 'Highest Scoring Quality')
                                                        : 'Highest Scoring MEMBERS'}
                                                </td>
                                                <td colSpan="2" style={{ border: 'none' }}>
                                                    {memberFilter.length ? (
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                <p className="action-name">
                                                                    {chartType === 'trends'
                                                                        ? maxQualityChange?.displayName?.toUpperCase()
                                                                        : maxQuality?.displayName?.toUpperCase()}
                                                                </p>
                                                                {chartType === 'trends' ? (
                                                                    <p className="small-text">{`${maxQualityChange?.priorScore?.toFixed(1)} to ${maxQualityChange?.currentScore?.toFixed(1)}`}</p>
                                                                ) : (
                                                                    <p className="small-text">
                                                                        {getCalendarTooltip(maxQuality?.currentScore)}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {chartType === 'trends' ? (
                                                                <div className="d-flex action-change">
                                                                    <img
                                                                        alt="arrow-icon"
                                                                        className="image-icon"
                                                                        src={
                                                                            Math.sign(maxQualityChange?.change) === 1
                                                                                ? arrowUp
                                                                                : arrowDown
                                                                        }
                                                                    />
                                                                    <span className="trend-change" style={{ color: Math.sign(maxQualityChange?.change) === 1 ? '#173EAD' : '#8EA7EC' }}>
                                                                        {Math.abs(parseInt(maxQualityChange.change * 100))}%
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div style={{ width: '40px', height: '40px' }}>
                                                                    <CircleProgress
                                                                        styles={{
                                                                            path: {
                                                                                stroke: this._getProgressColor(
                                                                                    maxQuality.currentScore
                                                                                ),
                                                                                text: {
                                                                                    fill: 'black'
                                                                                }
                                                                            },
                                                                            text: {
                                                                                fill: 'black'
                                                                            }
                                                                        }}
                                                                        text={`${maxQuality.currentScore?.toFixed(1)}`}
                                                                        value={(maxQuality.currentScore / 5) * 100}
                                                                    />
                                                                </div>

                                                            )}
                                                        </div>
                                                    ) : (
                                                        _.reverse(_.sortBy(topUsers, 'score')).map(({ name, uid, score }) => (
                                                            <div testid={`top-user-${uid}`} className="rank-user" key={uid}>
                                                                <span className="uid">{_.take(uid, 5)}</span>
                                                                <span className="name">{name}</span>
                                                                <span className="score">{score.toFixed(1)}</span>
                                                            </div>
                                                        ))
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="1" className="rank-score bottom-rank-score" style={{ paddingTop: 30 }}>
                                                    {memberFilter.length
                                                        ? (chartType === "trends" ? "Least Improved Quality" : 'Lowest Scoring Quality')
                                                        : 'Lowest Scoring MEMBERS'}
                                                </td>
                                                <td colSpan="2">
                                                    {memberFilter.length ? (
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                <p className="action-name">
                                                                    {chartType === 'trends'
                                                                        ? minQualityChange.displayName?.toUpperCase()
                                                                        : minQuality.displayName?.toUpperCase()}
                                                                </p>
                                                                {chartType === 'trends' ? (
                                                                    <p className="small-text">{`${minQualityChange.priorScore?.toFixed(1)} to ${minQualityChange.currentScore?.toFixed(1)}`}</p>
                                                                ) : (
                                                                    <p className="small-text">
                                                                        {getCalendarTooltip(minQuality.currentScore)}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {chartType === 'trends' ? (
                                                                <div className="d-flex action-change">
                                                                    <img
                                                                        alt="arrow-icon"
                                                                        className="image-icon"
                                                                        src={
                                                                            Math.sign(minQualityChange.change) === 1
                                                                                ? arrowUp
                                                                                : arrowDown
                                                                        }
                                                                    />
                                                                    <span className="trend-change" style={{ color: Math.sign(minQualityChange?.change) === 1 ? '#173EAD' : '#8EA7EC' }}>
                                                                        {Math.abs(parseInt(minQualityChange.change * 100))}%
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div style={{ width: '40px', height: '40px' }}>
                                                                    <CircleProgress
                                                                        styles={{
                                                                            path: {
                                                                                stroke: this._getProgressColor(
                                                                                    minQuality.currentScore
                                                                                ),
                                                                                text: {
                                                                                    fill: 'black'
                                                                                }
                                                                            },
                                                                            text: {
                                                                                fill: 'black'
                                                                            }
                                                                        }}
                                                                        text={`${minQuality.currentScore?.toFixed(1)}`}
                                                                        value={(minQuality.currentScore / 5) * 100}
                                                                    />
                                                                </div>

                                                            )}
                                                        </div>
                                                    ) : (
                                                        _.reverse(_.sortBy(bottomUsers, 'score')).map(({ name, uid, score }) => (
                                                            <div testid={`bottom-user-${uid}`} className="rank-user" key={uid}>
                                                                <span className="uid">{_.take(uid, 5)}</span>
                                                                <span className="name">{name}</span>
                                                                <span className="score">{score.toFixed(1)}</span>
                                                            </div>
                                                        ))
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="col-md-6">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-center">
                                            Quality
                                        </th>
                                        {!isMobile && <th scope="col" className="text-center">
                                            Prior
                                            <div
                                                onClick={() => this._toggleSort('priorSortOrder')}
                                                testid="prior-sort"
                                                className={`sort-container ${priorSortOrder}`}
                                            >
                                                <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                <img className="desc" src={caretDownIcon} alt="down arrow" />
                                            </div>
                                        </th>}
                                        <th scope="col" className="text-center">
                                            {!isMobile ? 'Current' : 'Scores'}
                                            <div
                                                onClick={() => this._toggleSort('currentSortOrder')}
                                                testid="current-sort"
                                                className={`sort-container ${currentSortOrder}`}
                                            >
                                                <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                <img className="desc" src={caretDownIcon} alt="down arrow" />
                                            </div>
                                        </th>
                                        {!isMobile && <>
                                            <th scope="col" className="text-center">
                                                Change
                                                <div
                                                    onClick={() => this._toggleSort('changeSortOrder')}
                                                    testid="change-sort"
                                                    className={`sort-container ${changeSortOrder}`}
                                                >
                                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                                </div>
                                            </th>
                                            <th scope="col" className="text-center">
                                                Logs
                                                <div
                                                    onClick={() => this._toggleSort('logSortOrder')}
                                                    testid="log-sort"
                                                    className={`sort-container ${logSortOrder}`}
                                                >
                                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                                </div>
                                            </th>
                                        </>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this._renderUserList(renderedList)}
                                    {Boolean(renderedList.length && !numericQualityFilter) && <tr className="outcome-row-overall" testid="scorecard-row">
                                        <td className="ps-4">Overall</td>
                                        {!isMobile && <td className="text-center">
                                            <span className="text-large">{`${priorPercentage}%`}</span>
                                            <span className="text-large-dull">{meanPrior.toFixed(1)}</span>
                                        </td>}
                                        <td className="text-center">
                                            <span className="text-large">{`${overallPercentage}%`}</span>
                                            <span className="text-large-dull">{meanOverall.toFixed(1)}</span>
                                        </td>
                                        {!isMobile && <>
                                            <td className="text-center">
                                                <div className="inline-content">
                                                    <img
                                                        className="image-icon ms-0"
                                                        src={Math.sign(change) === 1 ? arrowUp : arrowDown}
                                                        alt="up or down icon"
                                                    />
                                                    <div
                                                        className={`disposition-value ${Math.sign(change) === 1 ? 'positive' : 'negative'
                                                            }`}
                                                    >
                                                        {`${parseInt(change)}%`}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center"><b>{overallLength}</b></td>
                                        </>}
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, content: { currentUsers, metricLogs, metricLogsInitialized, habitLogsInitialized, habitLogs, chosenProtocolsMapping } }) => ({
    auth,
    metricLogs,
    habitLogs,
    chosenProtocolsMapping,
    metricLogsInitialized,
    habitLogsInitialized,
    currentUsers,
});

const ConnectedOutcome: ComponentType<{}> = connect(mapStateToProps, {
    fetchMetricLogsForYear,
    fetchHabitLogsForYear,
    fetchUsers,
})(Outcomes);

export default ConnectedOutcome;
