// @flow
import React, { useState } from 'react';
import { getLoadingIndicator } from '../../UI Resources';
import { updateNetworkUser } from '../../../api/firebase';
import { updateUsers } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import editIcon from '../../../assets/svgs/edit-blue.svg';
import type { ComponentType } from 'react';
import type { CurrentUser } from '../../../flowTypes';
import _ from 'lodash';

type EditUserFormProps = {
    user: CurrentUser,
    networkKey: string,
    userId: string,
    onDone: () => void,
};

export const EditUserForm: ComponentType<EditUserFormProps> = ({ user = {}, networkKey = "", userId = "", onDone = _.noop }) => {
    const [firstName, setFirstName] = useState(user?.loginInfo?.firstName);
    const [lastName, setLastName] = useState(user?.loginInfo?.lastName);
    const [email, setEmail] = useState(user?.loginInfo?.email);
    const [phone, setPhone] = useState(user?.profile?.phoneNumber || '');
    const [updating, setUpdating] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const { currentUsers } = useSelector(({ content }) => content);
    const dispatch = useDispatch();

    const updateUserInfo = async () => {
        const loginInfo = { ...user.loginInfo, firstName, lastName, email };
        const personalStatsInfo = { ...user.personalStatsInfo };
        const profile = { ...user.profile, phoneNumber: phone };
        const uid = userId;
        const newUser = {
            loginInfo,
            personalStatsInfo,
            profile,
            uid,
        };
        currentUsers[userId] = newUser;
        try {
            setUpdating(true);
            await updateNetworkUser(networkKey, userId, newUser);
            dispatch(updateUsers(currentUsers));
            setUpdating(false);
            setUpdateSuccess(true);
        } catch (e) {
            setUpdating(false);
        }
    };

    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={editIcon} className="modal-icon" alt="modal icon" />
                </div>
                <p testid="modal-title" className="modal-title">{updateSuccess ? 'Member Info Updated' : 'Edit Member Info'}</p>
            </div>
            {updateSuccess ? (
                <div className="modal-bd d-flex flex-column justify-content-center">
                    <button testid="done-button" className="btn btn-block btn-lg app-btn-primary mt-3" onClick={onDone}>
                        Okay
                    </button>
                </div>
            ) : (
                <div className="modal-bd">
                    <div className="">
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                testid="first-name"
                                className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input
                                testid="last-name"
                                className="form-control"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                testid="email-input"
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input testid="phone-input" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="modal-bd d-flex flex-column justify-content-center">
                            <button testid="submit-button" className="btn btn-block btn-lg app-btn-primary mt-3" onClick={updateUserInfo}>
                                {updating ? getLoadingIndicator() : <>{'Update'}</>}
                            </button>
                            <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
