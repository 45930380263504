// @flow

import React, { useState } from 'react';
import { getLoadingIndicator } from '../../UI Resources';
import { sendBroadcast } from '../../../api/firebase';
import type { ComponentType } from 'react';


import broadcastIcon from '../../../assets/svgs/broadcastWhite.svg';
import sendIcon from '../../../assets/svgs/sendWhite.svg';
import _ from 'lodash';
type NotificationProps = {
    networkKey: string;
    onDone: ()=> void;
};

export const BroadcastForm: ComponentType<NotificationProps> = ({ networkKey = "", onDone = _.noop}) => {
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationDescription, setNotificationDescription] = useState('');
    const [sendingNotification, setSendingNotification] = useState(false);
    const [sendNotificationError, setSendNotificationError] = useState('');
    const [successfullySent, setSuccessfullySent] = useState(false);

    const sendNotification: () => Promise<void> = async () => {
        if(!notificationTitle || !notificationDescription) return;
        
        setSendingNotification(true)
        try {
            const response = await sendBroadcast(networkKey, notificationTitle, notificationDescription);
            if (response.data && response.data.success) {
                setSendingNotification(false);
                setSuccessfullySent(true);
                return
            }
            setSendNotificationError(response.data.message || 'Unexpected error try again');
            setSendingNotification(false);
        } catch (e) {
            setSendNotificationError(e?.error || 'Unexpected error try again');
            setSendingNotification(false);
        }
        setSendingNotification(false);
    }
    
    return (
        <div className="modal-container modal-md">
        <div className="modal-head">
            <div className="modal-icon-container">
                <img src={broadcastIcon} className="modal-icon" alt="modal icon" />
            </div>
            {successfullySent ? (
                <p testid="modal-title" className="modal-title">Broadcast Sent</p>
            ) : (
                <p testid="modal-title" className="modal-title">{networkKey}</p>
            )}
        </div>
        {successfullySent ? (
            <div className="modal-bd d-flex flex-column justify-content-center">
            <button testid="onbroadcast-sent" className="btn btn-block btn-lg app-btn-primary mt-3" onClick={onDone}>
                Okay
            </button>
            </div>
        ) : (
            <>
                <p  className="modal-description">
                    You are sending a notification to <strong>all</strong> members.
                </p>
                <div className="modal-bd">
                    <div className="">
                        {sendNotificationError ? (
                            <p testid="notification-error" className="form-error text-center">{sendNotificationError}</p>
                        ) : null}
                        <div className="form-group">
                            <label>Notification Title</label>
                            <input
                                testid="title-input"
                                className="form-control"
                                placeholder="Title"
                                value={notificationTitle}
                                onChange={(e) => setNotificationTitle(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Notification Content</label>
                            <textarea
                                testid="content-input"
                                rows={4}
                                className="form-control"
                                placeholder="Content"
                                value={notificationDescription}
                                onChange={(e) => setNotificationDescription(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                    <div className="modal-bd d-flex flex-column justify-content-center">
                    <button
                        testid="submit-button"
                        className="btn btn-block btn-lg app-btn-primary mt-3"
                        disabled={!notificationTitle || !notificationDescription}
                        onClick={sendNotification}
                    >
                        {sendingNotification ? (
                            getLoadingIndicator()
                        ) : (
                            <>
                                <img src={sendIcon} className="image-icon button-icon" alt="send Icon" />
                                {'Send Broadcast'}
                            </>
                        )}
                    </button>
                    <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                        Cancel
                    </button>
                    </div>
                    </div>
                   
                </div>
            </>
        )}
    </div>
    );
};
