// @flow

import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/sidebar.scss';
import * as React from 'react';

import { connect } from 'react-redux';
import { login, logout } from '../../actions';
import { withRouter, Link, matchPath } from 'react-router-dom';
import logo from '../../assets/images/aspenLogo.png';
import membersIcon from '../../assets/svgs/members.svg';
import protocolIcon from '../../assets/svgs/protocols.svg';
import insightsIcon from '../../assets/svgs/insights.svg';
import accountsIcon from '../../assets/svgs/account.svg';
import dataIcon from '../../assets/svgs/database.svg';
import qualityOutcomesIcon from '../../assets/svgs/outcomes.svg';
import qualityAdherenceIcon from '../../assets/svgs/adherence.svg';
import calendarHeatmapIcon from '../../assets/svgs/heatmap.svg';
import scoreCard from '../../assets/svgs/scorecard.svg';
import logoutIcon from '../../assets/svgs/logout.svg';
import { isMobile as isMobileDetect } from 'react-device-detect';
import Div100vh from 'react-div-100vh'
import caretUpIcon from '../../assets/svgs/caret-up-light.svg';




type Props = {
    history: any,
    login: Function,
    location: any,
    match: any,
    logout: Function,
    isDeactivated: boolean,
};

type State = {
    mobileOverride: boolean,
}

export class Header extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            mobileOverride: false,
        };
    }

    render(): any {
        const { location, history, isDeactivated = true } = this.props;
        const { mobileOverride } = this.state;

        const isMobile = mobileOverride || isMobileDetect;
        let { pathname } = location || {};

        const Container = ({ children }) => {
            return isMobileDetect ? <Div100vh>{children}</Div100vh> : <>{children}</>;
        }

        return (
            <Container>
                <div className={isMobile ? 'sidebar-container-mobile' : 'sidebar-container'}>
                    <div className={`${isMobile ? 'sidebar-mobile' : 'sidebar'} fixed-top`}>
                        <div className="branding">
                            <img alt="logo" className="brand-logo" src={logo} style={{ width: 50, height: 58, marginRight: isMobile ? 0 : 50 }} />
                        </div>

                        <ul className="nav-list">
                            {!isMobileDetect && <li testid='collapse' style={{display: 'flex', cursor: 'pointer', justifyContent: isMobile ? 'center' : 'flex-end', paddingBottom: 10}} onClick={() => this.setState({ mobileOverride: !mobileOverride })}>
                                <img className="asc" style={{ width: 20, height: 20, marginRight: isMobile ? 0 : 20, transform: isMobile ? "rotate(90deg)" : "rotate(270deg)" }} src={caretUpIcon} alt="up arrow" />
                            </li>}
                            <li>
                                <Link
                                    testid="members-button"
                                    className={`nav-item  ${!!matchPath(pathname, { path: "/members", exact: true }) ? 'active' : ''}`}
                                    to="/members"
                                >
                                    <img alt="members icon" className="nav-icon" src={membersIcon} />
                                    {!isMobile && 'Members'}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    testid="protocols-button"
                                    className={`nav-item  ${!!matchPath(pathname, { path: "/protocols", exact: true }) ? 'active' : ''}`}
                                    to="/protocols"
                                >
                                    <img alt="members icon" className="nav-icon" src={protocolIcon} />
                                    {!isMobile && 'Protocols'}
                                </Link>
                            </li>

                            <li className="sub-nav-item">
                                <Link
                                    testid="insights-button"
                                    to="/insights"
                                    className={`nav-item  ${!!matchPath(pathname, { path: '/insights' }) ? 'active' : ''}`}
                                >
                                    <img alt="insights icon" className="nav-icon" src={insightsIcon} />
                                    {!isMobile && 'Insights'}
                                </Link>
                                <ul className="sub-nav-list">
                                    <li>
                                        <Link testid="scorecard-button" className={`nav-item ${!!matchPath(pathname, { path: '/insights/scorecard', exact: true }) ? 'active' : ''}`} to="/insights/scorecard">
                                            <img alt="scorecard icon" className="nav-icon-sub" src={scoreCard} />
                                            {!isMobile && 'Scorecard'}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link testid="outcomes-button" className={`nav-item ${!!matchPath(pathname, { path: '/insights/outcomes', exact: true }) ? 'active' : ''}`} to="/insights/outcomes">
                                            <img alt="outcome icon" className="nav-icon-sub" src={qualityOutcomesIcon} />
                                            {!isMobile && 'Outcomes'}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link testid="adherence-button" className={`nav-item ${!!matchPath(pathname, { path: '/insights/adherence', exact: true }) ? 'active' : ''}`} to="/insights/adherence">
                                            <img alt="adherence icon" className="nav-icon-sub" src={qualityAdherenceIcon} />
                                            {!isMobile && 'Adherence'}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link testid="heatmap-button" className={`nav-item ${!!matchPath(pathname, { path: '/insights/heatmap', exact: true }) ? 'active' : ''}`} to="/insights/heatmap">
                                            <img alt="heatmap icon" className="nav-icon-sub" src={calendarHeatmapIcon} />
                                            {!isMobile && 'Heatmap'}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {!isMobileDetect && <li>
                                <Link testid="data-button" className={`nav-item ${!!matchPath(pathname, { path: '/data', exact: true }) ? 'active' : ''}`} to="/data">
                                    <img alt="data icon" className="nav-icon" src={dataIcon} />
                                    {!isMobile && 'Activity Log'}
                                </Link>
                            </li>}
                            {!isMobileDetect && <li>
                                <Link
                                    testid="account-button"
                                    className={`nav-item ${!!matchPath(pathname, { path: '/account', exact: true }) ? 'active' : ''}`}
                                    to="/account"
                                >
                                    <img alt="account Icon" className="nav-icon" src={accountsIcon} />
                                    {!isMobile && `Account (${isDeactivated ? 'inactive' : 'active'})`}
                                    {!isMobile && <span testid="active-indicator" className={`roundDot ${isDeactivated ? 'inactive' : 'active'}`}></span>}
                                </Link>
                            </li>}
                        </ul>
                        <div className="nav-bottom" testid="logout-button" onClick={() => { this.props.logout(); history.push("/") }} >
                            <img alt="logout Icon" className="nav-icon" src={logoutIcon} />
                            {!isMobile && 'Log out'}
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        username: state.auth.username,
        networkKey: state.auth.networkKey,
        profilePicId: state.auth.profilePicId,
        isDeactivated: state.auth.deactivated,
    };
};

const connected: any = connect(mapStateToProps, { login, logout })(withRouter(Header));
export default connected;
