// @flow

import {
    LOGIN,
    LOGOUT,
    UPDATE_TIME,
    UPDATE_USERS,
    UPDATE_THIS_MONTH_METRIC_LOGS,
    UPDATE_THIS_MONTH_HABIT_LOGS,
    UPDATE_USERS_CHOSEN_PROTOCOLS,
    UPDATE_CHOSEN_LEVEL_FOR_PROTOCOL,
    UPDATE_STATS_NUMERIC_FILTERS,
    UPDATE_STATS_OTHER_FILTERS,
    UPDATE_STATS_FILTERED_TIMEFRAME,
    UPDATE_USERS_LEVELS_INFO,
    UPDATE_PROTOCOLS,
    UPDATE_NETWORK_DEACTIVATED,
    UPDATE_SENT_NOTIFICATIONS,
    UPDATE_STATS_QUALITATIVE_METRIC_FILTERS,
    UPDATE_NETWORK_POSTS,
    UPDATE_CLOSED_NETWORK,
    UPDATE_SECRET_KEY,
    UPDATE_METRIC_LOGS,
    UPDATE_HABIT_LOGS,
    ASSIGN_PROTOCOL_AND_LEVEL_TO_USER,
    UPDATE_MODERATORS
} from './types';

import {
    getUsersForNetworkKey,
    getAllMetricLogsForMonthForNetworkKey,
    getAllHabitLogsForMonthForNetworkKey,
    getProtocolForUser,
    getLevelInfoForUser,
    getProtocolsWithNetworkKey,
    getAllSentNotifications,
    setProtocolsWithNetworkKey,
    setProtocolForUser,
    getPosts,
    getIsClosedNetwork,
    setIsClosedNetwork,
    getNetworkSecretKey,
    setNetworkSecretKey,
    getMetricLogsForYear,
    getHabitLogsForYear,
    setLevelForUser,
    getModeratorsForNetwork,
    setModeratorsForNetwork,
    setProtocolForIDWithNetworkKey,
    getProtocolsRefWithNetworkKey
} from '../api/firebase';


import type { Protocol, CurrentUsersObject, LoginInfo, ModeratorMap } from '../flowTypes';
import { getCalendarFormatForDate, getMediumRandomID } from '../components/UI Resources';
import _ from 'lodash';
import { DEFAULT_EASY_PROTOCOL, DEFAULT_EXTREME_PROTOCOL, DEFAULT_HARD_PROTOCOL, DEFAULT_NORMAL_PROTOCOL } from '../Constants';

type Dispatch = (param: any) => void;
type Payload = { type: string, payload?: any };


export const updateTime: () => Payload = () => {
    return {
        type: UPDATE_TIME,
        payload: Math.round(new Date() / 1000)
    }
}

export const login: (payload: LoginInfo) => Payload = (payload) => {

    const {
        username,
        name,
        networkKey,
        deactivated,
        password,
        uid,
        isModerator
    } = payload;

    window.localStorage.setItem("username", username);
    window.localStorage.setItem("name", name);
    window.localStorage.setItem("networkKey", networkKey);
    window.localStorage.setItem("deactivated", deactivated);
    window.localStorage.setItem("password", password);
    window.localStorage.setItem("uid", uid);
    window.localStorage.setItem("isModerator", isModerator);

    return {
        type: LOGIN,
        payload
    };
};

export const updateUsers: (newUsers: any) => Payload = (newUsers) => {
    return {
        type: UPDATE_USERS,
        payload: newUsers
    }
}

export const fetchIsClosedNetwork: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {
        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return; // something is wrong
        }

        let closedNet;
        try {
            closedNet = (await getIsClosedNetwork(networkKey)).val();
        } catch (e) {
            return;
        }

        dispatch({
            type: UPDATE_CLOSED_NETWORK,
            payload: closedNet
        });
    }
}

export const setClosedNetwork: (closed: boolean) => (dispatch: Dispatch) => Promise<void> = (closed) => {
    return async (dispatch) => {
        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return; // something is wrong
        }

        dispatch({
            type: UPDATE_CLOSED_NETWORK,
            payload: closed
        });

        try {
            await setIsClosedNetwork(networkKey, closed);
        } catch (e) { }
    }
}

export const fetchUsers: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;
        const uid = window.localStorage.uid;

        if (!networkKey || !uid) {
            return; // something is wrong
        }

        let networkKeyRef;
        try {
            networkKeyRef = await getUsersForNetworkKey(networkKey);
        } catch (e) { }

        if (networkKeyRef && networkKeyRef.val()) {
            let users: CurrentUsersObject = networkKeyRef.val();
            for (const key in users) {
                if (_.get(users[key], 'personalStatsInfo.dateOfBirth')) {
                    users[key].personalStatsInfo.dateOfBirth = getCalendarFormatForDate(users[key].personalStatsInfo.dateOfBirth);
                }
            }
            dispatch({
                type: UPDATE_USERS,
                payload: users
            });

            const usersMap: CurrentUsersObject = networkKeyRef.val();
            for (const userKey of Object.keys(usersMap)) {
                let protocolRef;
                try {
                    protocolRef = await getProtocolForUser(usersMap[userKey].uid);
                } catch (e) { }
                if (protocolRef && protocolRef.val()) {
                    dispatch({
                        type: UPDATE_USERS_CHOSEN_PROTOCOLS,
                        payload: { [usersMap[userKey].uid]: protocolRef.val() }
                    });
                }
            }
            for (const userKey of Object.keys(usersMap)) {
                let levelInfoRef;
                try {
                    levelInfoRef = await getLevelInfoForUser(usersMap[userKey].uid);
                } catch (e) { }
                if (levelInfoRef && levelInfoRef.val()) {
                    dispatch({
                        type: UPDATE_USERS_LEVELS_INFO,
                        payload: { [usersMap[userKey].uid]: levelInfoRef.val() }
                    });
                }
            }
        }
    };
};

export const setNewUserLevelMapping: (mapping: Object) => Payload = (mapping) => {
    return {
        type: UPDATE_USERS_LEVELS_INFO,
        payload: mapping
    };
}

export const setNewUserProtocol: (protocolId: string, uid: string, currentChosenProtocolsMapping: { [userkey: string]: string }) => (dispatch: Dispatch) => Promise<void> = (protocolId, uid, currentChosenProtocolsMapping) => {
    return async (dispatch) => {

        currentChosenProtocolsMapping[uid] = protocolId;
        dispatch({ type: UPDATE_USERS_CHOSEN_PROTOCOLS, payload: currentChosenProtocolsMapping });

        setProtocolForUser(uid, protocolId);
    }
};
export const assignUserProtocol: (
    protocolId: string,
    members: string[],
    level: number,
    currentChosenProtocolsMapping: { [userkey: string]: string }
) => (dispatch: Dispatch) => Promise<void> = (protocolId, members, level, currentChosenProtocolsMapping) => {
    return async (dispatch) => {
        const levelMapping = {};
        members.forEach((uid) => {
            currentChosenProtocolsMapping[uid] = protocolId;
            if (level) levelMapping[uid] = { [protocolId]: level };
        });
        const payload = {
            mapping: {
                ...levelMapping,
            },
            chosenProtocolsMapping: currentChosenProtocolsMapping,
        };
        await setProtocolForUser(members, protocolId);
        await setLevelForUser(members, protocolId, level);

        dispatch({ type: ASSIGN_PROTOCOL_AND_LEVEL_TO_USER, payload: payload });
    };
};

export const fetchProtocols: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {
        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        getProtocolsRefWithNetworkKey(networkKey).off('value');

        let protocolsSnapshot: { [id: string]: Protocol[] } = {};

        try {
            protocolsSnapshot = await getProtocolsWithNetworkKey(networkKey);
            protocolsSnapshot = (protocolsSnapshot && protocolsSnapshot.val()) || {};
        } catch (e) { }

        const mappedProtocols = Object.keys(protocolsSnapshot).reduce((list, key) => [...list, ...(protocolsSnapshot[key])], []);

        dispatch({ type: UPDATE_PROTOCOLS, payload: mappedProtocols });
    }
}

export const fetchProtocolsRealTime: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {
        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        let protocolsSnapshot: { [id: string]: Protocol[] } = {};

        getProtocolsRefWithNetworkKey(networkKey).on('value', snapshot => {
            try {
                protocolsSnapshot = snapshot.val() || {};
            } catch (e) { }

            const mappedProtocols = Object.keys(protocolsSnapshot).reduce((list, key) => [...list, ...(protocolsSnapshot[key])], []);

            dispatch({ type: UPDATE_PROTOCOLS, payload: mappedProtocols });
        });
    }
}

export const setProtocols: (newProtocols: Protocol[], locallyChangedProtocols: Protocol[], networkKey: string) => (dispatch: Dispatch) => Promise<void> = (newProtocols, locallyChangedProtocols, networkKey) => {
    return async (dispatch) => {

        dispatch({ type: UPDATE_PROTOCOLS, payload: newProtocols });

        const protocolsSnapshot = await getProtocolsWithNetworkKey(networkKey);
        const currentProtocols = (protocolsSnapshot && protocolsSnapshot.val()) || [];
        const finalProtocols = [];
        for (const protocol of newProtocols) {
            if (locallyChangedProtocols.includes(protocol)) {
                finalProtocols.push(protocol);
            } else {
                const existingProtocol = currentProtocols.find(prot => (prot.id === protocol.id && (prot.level ?? 0) === (protocol.level ?? 0)));
                if (existingProtocol) {
                    finalProtocols.push(existingProtocol);
                }
            }
        }
        for (const protocol of currentProtocols) {
            const protocolIsAccountedFor = finalProtocols.find(prot => (prot.id === protocol.id && (prot.level ?? 0) === (protocol.level ?? 0)))
                || locallyChangedProtocols.find(prot => (prot.id === protocol.id && (prot.level ?? 0) === (protocol.level ?? 0)))
            if (!protocolIsAccountedFor) {
                finalProtocols.unshift(protocol);
            }
        }
        setProtocolsWithNetworkKey(networkKey, finalProtocols);
        dispatch({ type: UPDATE_PROTOCOLS, payload: finalProtocols });
    };
}
export const setProtocolsForNetwork: (newProtocols: Protocol[], networkKey: string, editedProtocolId: string) => (dispatch: Dispatch) => Promise<void> = (newProtocols, networkKey, editedProtocolId) => {
    return async (dispatch) => {

        const editedProtocols = newProtocols.filter(p => p.id === editedProtocolId);
        setProtocolForIDWithNetworkKey(networkKey, editedProtocols, editedProtocolId);
        dispatch({ type: UPDATE_PROTOCOLS, payload: newProtocols });
    };
}



export const fetchMetricLogsForPastMonth: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        const logsSnapshot = await getAllMetricLogsForMonthForNetworkKey(networkKey);
        const protocols: Protocol[] = [...((await getProtocolsWithNetworkKey(networkKey)).val() || []), DEFAULT_EASY_PROTOCOL, DEFAULT_NORMAL_PROTOCOL, DEFAULT_HARD_PROTOCOL, DEFAULT_EXTREME_PROTOCOL];
        const metricNames = protocols.map(protocol => (protocol.metrics || []).map(metric => metric.name)).flat();
        const logs = (logsSnapshot?.docs || []).map(doc => doc.data());
        const uniqueLogs = _.uniqWith(_.reverse(logs), (log1, log2) => log1.metricName === log2.metricName && log1.date === log2.date && log1.uid === log2.uid);
        const currentlyUsedUniqueLogs = uniqueLogs.filter(log => metricNames.includes(log.metricName));
        dispatch({
            type: UPDATE_THIS_MONTH_METRIC_LOGS,
            payload: currentlyUsedUniqueLogs
        });
    };
}

export const fetchMetricLogsForYear: (networkKey: string) => (dispatch: Dispatch) => Promise<void> = (networkKey: string) => {
    return async (dispatch) => {
        const metricsLogsSnapshot = await getMetricLogsForYear(networkKey);
        const metricLogs = [];
        if (metricsLogsSnapshot) {
            metricsLogsSnapshot.forEach((docSnap) => {
                metricLogs.push(docSnap.data());
            })
        }

        const uniqueLogs = _.uniqWith(_.orderBy(metricLogs, ['timeStamp'], ['desc']), (log1, log2) => log1.metricName === log2.metricName && log1.date === log2.date && log1.uid === log2.uid);

        dispatch({
            type: UPDATE_METRIC_LOGS,
            payload: uniqueLogs
        });
    }
}
export const fetchHabitLogsForYear: (networkKey: string) => (dispatch: Dispatch) => Promise<void> = (networkKey: string) => {
    return async (dispatch) => {
        const habitsLogSnapshot = await getHabitLogsForYear(networkKey);
        const habitLogs = [];
        if (habitsLogSnapshot) {
            habitsLogSnapshot.forEach((docSnap) => {
                habitLogs.push(docSnap.data());
            })
        }

        const uniqueLogs = _.uniqWith(_.orderBy(habitLogs, ['timeStamp'], ['desc']), (log1, log2) => log1.habitName === log2.habitName && log1.date === log2.date && log1.uid === log2.uid);

        dispatch({
            type: UPDATE_HABIT_LOGS,
            payload: uniqueLogs
        })
    }
}



export const fetchAdherenceLogsForPastMonth: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        const logsSnapshot = await getAllHabitLogsForMonthForNetworkKey(networkKey);
        const protocols: Protocol[] = [...((await getProtocolsWithNetworkKey(networkKey)).val() || []), DEFAULT_EASY_PROTOCOL, DEFAULT_NORMAL_PROTOCOL, DEFAULT_HARD_PROTOCOL, DEFAULT_EXTREME_PROTOCOL];
        const habitNames = protocols.map(protocol => (protocol.habits || []).map(habit => habit.name)).flat();
        const logs = (logsSnapshot?.docs || []).map(doc => doc.data());
        const uniqueLogs = _.uniqWith(_.reverse(logs), (log1, log2) => log1.habitName === log2.habitName && log1.date === log2.date && log1.uid === log2.uid);
        const currentlyUsedUniqueLogs = uniqueLogs.filter(log => habitNames.includes(log.habitName));
        dispatch({
            type: UPDATE_THIS_MONTH_HABIT_LOGS,
            payload: currentlyUsedUniqueLogs
        });
    };
}

export const fetchSentNotifications: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        const logs = await getAllSentNotifications(networkKey);

        if (logs && logs.docs) {
            dispatch({
                type: UPDATE_SENT_NOTIFICATIONS,
                payload: logs.docs.map(doc => doc.data())
            });
        }
    };
}

export const fetchPosts: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        let logs;
        try {
            logs = await getPosts(networkKey);
        } catch (e) { }

        if (logs && logs.docs) {
            dispatch({
                type: UPDATE_NETWORK_POSTS,
                payload: logs.docs.map(doc => doc.data())
            });
        }
    };
}

export const fetchModerators: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        let moderators;
        try {
            moderators = ((await getModeratorsForNetwork(networkKey)).val() || {});
        } catch (e) {
            return;
        };

        dispatch({
            type: UPDATE_MODERATORS,
            payload: moderators,
        });
    };
}

export const removeModerator: (uid: string, moderators: ModeratorMap) => (dispatch: Dispatch) => Promise<void> = (uid, moderators) => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        delete moderators[uid];

        setModeratorsForNetwork(networkKey, moderators);
        dispatch({
            type: UPDATE_MODERATORS,
            payload: moderators,
        });
    };
}

export const addModerator: (uid: string, email: string, currentModerators: ModeratorMap) => Payload = (uid, email, currentModerators) => {

    return {
        type: UPDATE_MODERATORS,
        payload: { ...(currentModerators || {}), [uid]: { email, createdTime: Date.now() } }
    };
}

export const fetchSecretKey: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        let secretKey;
        try {
            secretKey = (await getNetworkSecretKey(networkKey)).val();
        } catch (e) {
            return;
        };

        dispatch({
            type: UPDATE_SECRET_KEY,
            payload: secretKey,
        });
    };
}

export const setNewSecretKey: () => (dispatch: Dispatch) => Promise<void> = () => {
    return async (dispatch) => {

        const networkKey = window.localStorage.networkKey;

        if (!networkKey) {
            return;
        }

        const newSecretKey = getMediumRandomID();

        dispatch({
            type: UPDATE_SECRET_KEY,
            payload: newSecretKey,
        });

        try {
            setNetworkSecretKey(networkKey, newSecretKey);
        } catch (e) { };
    };
}


export const updateSentNotifications: (newNotifications: any[]) => Payload = (newNotifications) => {
    return {
        type: UPDATE_SENT_NOTIFICATIONS,
        payload: newNotifications
    };
}

export const updatePosts: (newPosts: any[]) => Payload = (newPosts) => {
    return {
        type: UPDATE_NETWORK_POSTS,
        payload: newPosts
    };
}

export const udpateLevelForProtocol: (level: number, protocol: any) => Payload = (level, protocol) => {
    return {
        type: UPDATE_CHOSEN_LEVEL_FOR_PROTOCOL,
        payload: {
            protocol,
            level
        }
    };
}

export const logout: () => Payload = () => {


    window.localStorage.removeItem("username");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("networkKey");
    window.localStorage.removeItem("deactivated");
    window.localStorage.removeItem("isModerator");
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("uid");

    return {
        type: LOGOUT
    };
};

/**
 * 
 * LOCAL STORAGE 
 * 
 */

export const assignUserInfoFromLocalStorage: () => Payload = () => {

    let isModerator;
    let deactivated;
    try {
        deactivated = JSON.parse(window.localStorage.deactivated);
    } catch (e) {
        deactivated = true;
    }

    try {
        isModerator = JSON.parse(window.localStorage.isModerator);
    } catch (e) {
        isModerator = false;
    }

    const payload = {
        username: window.localStorage.username,
        name: window.localStorage.name,
        networkKey: window.localStorage.networkKey,
        password: window.localStorage.password,
        uid: window.localStorage.uid,
        deactivated,
        isModerator,
    };

    return {
        type: LOGIN,
        payload
    };
};

/**
 * 
 * LOCAL STORAGE 
 * 
 */

export const setNetworkDeactivated: (deactivated: boolean) => Payload = (deactivated) => {

    window.localStorage.setItem('deactivated', deactivated);

    return {
        type: UPDATE_NETWORK_DEACTIVATED,
        payload: deactivated
    };
};

export const setFilteredTimeFrame: (filteredTimeFrame: string) => Payload = (filteredTimeFrame) => {
    return {
        type: UPDATE_STATS_FILTERED_TIMEFRAME,
        payload: filteredTimeFrame
    };
};

export const setNumericFilters: (numericFilters: Array<any>) => Payload = (numericFilters) => {
    return {
        type: UPDATE_STATS_NUMERIC_FILTERS,
        payload: numericFilters
    };
};

export const setOtherFilters: (otherFilters: Array<any>) => Payload = (otherFilters) => {
    return {
        type: UPDATE_STATS_OTHER_FILTERS,
        payload: otherFilters
    };
};

export const setQualitativeMetricFilters: (metricFilters: Array<any>) => Payload = (metricFilters) => {
    return {
        type: UPDATE_STATS_QUALITATIVE_METRIC_FILTERS,
        payload: metricFilters
    };
};

