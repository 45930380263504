// @flow

import React, { useState } from 'react';
import { getLoadingIndicator } from '../../UI Resources';
import { sendNotificationToToken } from '../../../api/axios';
import { getNotificationTokenForUser } from '../../../api/firebase';
import type { CurrentUser } from '../../../flowTypes';
import sendIcon from '../../../assets/svgs/sendBlack.svg';
import bellIconBlue from '../../../assets/svgs/bellIconBlue.svg';
import type { ComponentType } from 'react';
import _ from 'lodash';


type NotificationProps = {
    user: CurrentUser,
    userId: string,
    networkKey: string,
    onDone: () => void
};

export const NotificationForm: ComponentType<NotificationProps> = ({ user ={}, userId = "", networkKey="", onDone = _.noop }) => {
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationDescription, setNotificationDescription] = useState('');
    const [sendingNotification, setSendingNotification] = useState('');
    const [sendNotificationError, setSendNotificationError] = useState('');
    const [successfullySent, setSuccessfullySent] = useState(false);

    const sendNotification: () => Promise<void> = async () => {
        if (!notificationTitle || !notificationDescription) return;
        setSendingNotification(true);
        const tokenResponse = await getNotificationTokenForUser(userId);
        if (!tokenResponse || !tokenResponse?.val) {
            setSendingNotification(false);
            return;
        }
        const token = tokenResponse.val();

        try {
            const response = await sendNotificationToToken(
                token,
                notificationTitle,
                notificationDescription,
                networkKey,
                user?.loginInfo?.email
            );
            if (response.data && response.data.success) {
                setSendingNotification(true);
                setSuccessfullySent(true);
            }
        } catch (e) {
            setSendNotificationError(e?.error || 'Unexpected error try again');
            setSendingNotification(false);
        }
        setSendingNotification(false);
    };
    
    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={bellIconBlue} className="modal-icon" alt="modal icon" />
                </div>
                {successfullySent ? (
                    <p testid="modal-title" className="modal-title">Ping Sent</p>
                ) : (
                    <p testid="modal-title" className="modal-title">{`${user.loginInfo?.firstName || ""} ${user.loginInfo?.lastName || ""}`}</p>
                )}
            </div>
            {successfullySent ? (
                <div className="modal-bd d-flex flex-column justify-content-center">
                <button testid="done-sent" className="btn btn-block btn-lg app-btn-secondary mt-3" onClick={onDone}>
                    Okay
                </button>
                </div>
            ) : (
                <>
                    <p className="modal-description">
                        You are sending a notification to <strong>one</strong> member.
                    </p>
                    <div className="modal-bd">
                        <div className="">
                            {sendNotificationError ? (
                                <p testid="notification-error" className="form-error text-center">{sendNotificationError}</p>
                            ) : null}
                            <div className="form-group">
                                <label>Notification Title</label>
                                <input
                                    testid="title-input"
                                    className="form-control"
                                    placeholder="Title"
                                    value={notificationTitle}
                                    onChange={(e) => setNotificationTitle(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Notification Content</label>
                                <textarea
                                    testid="content-input"
                                    rows={4}
                                    className="form-control"
                                    placeholder="Content"
                                    value={notificationDescription}
                                    onChange={(e) => setNotificationDescription(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="modal-bd d-flex flex-column justify-content-center">
                        <button
                            className="btn btn-block btn-lg app-btn-secondary mt-3"
                            disabled={!notificationTitle || !notificationDescription}
                            onClick={sendNotification}
                            testid = "submit-button"
                        >
                            {sendingNotification ? (
                                getLoadingIndicator()
                            ) : (
                                <>
                                    <img src={sendIcon} className="image-icon button-icon" alt="send Icon" />
                                    {'Send Ping'}
                                </>
                            )}
                        </button>
                        <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                            Cancel
                        </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
