// @flow

import axios from 'axios';
import {isAlpha} from '../../appConfig';


export const getInstance = (): any => {
    const instance = axios.create({
        baseURL: isAlpha() ? 'https://us-central1-pinecone-alpha.cloudfunctions.net/' : 'https://us-central1-pinecone-e4730.cloudfunctions.net/',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': '*',
          },
      });

      return instance;
}

export const getExpoNotificationInstance = (): any => {
    const instance = axios.create({
        baseURL: 'https://exp.host/--/api/v2/push/',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': '*',
          },
      });

      return instance;
}