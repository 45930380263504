// @flow
import {UPDATE_TIME} from '../actions/types';
import type {UtilitiesObject} from '../flowTypes';

const INITIAL_STATE: UtilitiesObject = {
    currentTime: Math.round(new Date() / 1000)
};

export default (state: UtilitiesObject = INITIAL_STATE, action: any): UtilitiesObject => {
    switch (action.type) {
        case UPDATE_TIME:
            return {...state, currentTime: action.payload};
        default:
            return state;
    }
};