import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import logo from '../../assets/images/nameLogoBlack.png';
import bottomImage from '../../assets/images/screenPreview.png';

import { withRouter } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import React from 'react';
import { MISMATCHED_PASSWORDS_ERROR } from '../../Constants';
import { getLoadingIndicator } from '../UI Resources';
// import { resetPassword } from '../../api/axios';
import { resetPassword } from '../../api/firebase';

export class PasswordRecovery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordConfirm: '',
            passwordResetSuccessfull: false,
            resetting: false,
            resetError: '',
        };
    }

    _onPasswordChanged = (e) => {
        this.setState({ password: e.target.value });
    };

    _detectEnterButton = ({ key }) => {
        if (key === 'Enter') {
            this._resetPassword();
        }
    };

    _onPasswordConfirmChanged = (e) => {
        this.setState({ passwordConfirm: e.target.value });
    };

    _goToLogin = () => {
        const { history } = this.props;
        history.push('/');
    };

    _resetPassword = async () => {
        const { password, passwordConfirm } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        if (!password || !passwordConfirm) return;

        if (password !== passwordConfirm) {
            this.setState({ resetError: MISMATCHED_PASSWORDS_ERROR });
            return;
        }

        try {
            const token = params.get('token');
            this.setState({ resetting: true });
            const res = await resetPassword({ token, password });
            const { data } = res;
            if (data?.error && data?.message) {
                this.setState({ resetting: false, resetError: data.message });
                return;
            }
            this.setState({ resetting: false, passwordResetSuccessfull: true });
        } catch (err) {
            this.setState({ resetting: false, resetError: err.message });
        }
    };

    render() {
        const { resetError, password, passwordConfirm, resetting, passwordResetSuccessfull } = this.state;
        return (
            <React.Fragment>
                <BrowserView>
                    <div style={{ paddingLeft: '10%', paddingRight: '10%', marginTop: '5rem' }}>
                        <div className="row">
                            {this._getSlogan()}
                            <div className="col-sm login-right-column" style={{ height: 307 }}>
                                {passwordResetSuccessfull ? (
                                    <div className="form-signin">
                                        <img className="mb-4 login-logo" src={logo} alt="" height="40" />
                                        <p>
                                            Password has been reset successfully, you may now login with your new password.
                                        </p>
                                        <button
                                            testid={'done-button'}
                                            className="btn btn-lg btn-primary btn-block black-button"
                                            onClick={this._goToLogin}
                                            style={{ width: '100%', borderRadius: '.25rem' }}
                                        >
                                            <b>Done</b>
                                        </button>
                                    </div>
                                ) : (
                                    <div className="form-signin">
                                        <img className="mb-4 login-logo" src={logo} alt="" height="40" />
                                        {resetError && <div testid={'recovery-error-text'} style={{ color: 'red' }}>Error: {resetError}</div>}
                                        <input
                                            testid={'new-password'}
                                            type="password"
                                            className="form-control"
                                            placeholder="New password"
                                            value={password}
                                            onChange={this._onPasswordChanged}
                                        ></input>
                                        <input
                                            testid={'confirm-new-password'}
                                            type="password"
                                            className="form-control flat-top"
                                            placeholder="Confirm new password"
                                            value={passwordConfirm}
                                            onChange={this._onPasswordConfirmChanged}
                                            onKeyDown={this._detectEnterButton}
                                        ></input>
                                        <button
                                            testid={'new-password-button'}
                                            className="btn btn-lg btn-primary btn-block black-button"
                                            onClick={this._resetPassword}
                                            disabled={resetting || !password || !passwordConfirm}
                                            style={{ width: '100%' }}
                                        >
                                            {resetting ? (
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {getLoadingIndicator('#ffffff', 1)}
                                                </div>
                                            ) : (
                                                <b>Set New Password</b>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <img alt="preview" src={bottomImage} style={{ width: '100%' }}></img>
                    </div>
                </BrowserView>
                <MobileView>
                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            className="mb-4 login-logo"
                            src={logo}
                            alt=""
                            height="40"
                            style={{ alignSelf: 'center', margin: 'auto' }}
                        />
                        <b>This app is only available on desktop</b>
                    </div>
                </MobileView>
            </React.Fragment>
        );
    }

    _getSlogan() {
        return (
            <div className="col-sm login-left-column">
                <br></br>
                <h1 style={{ fontSize: 50 }}><b>Information flow to help you</b></h1>
                <h1 style={{ fontSize: 50 }}><b>gain insights that drive</b></h1>
                <h1 style={{ fontSize: 50 }}><b>meaningful change.</b></h1>
            </div>
        );
    }
}

export default withRouter(PasswordRecovery);
