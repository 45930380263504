// @flow

import {isAlpha} from '../../appConfig';

export const config = (): Object => isAlpha()
    ? {
        apiKey: "AIzaSyDfdDAd6rh4SqRv_z3cp7ebZTuKKRI43-I",
        authDomain: "pinecone-alpha.firebaseapp.com",
        projectId: "pinecone-alpha",
        storageBucket: "pinecone-alpha.appspot.com",
        messagingSenderId: "7026433630",
        appId: "1:7026433630:web:2ee5b8c23f972c12cc1ecf",
        measurementId: "G-H1YLQYCKX2",
        databaseURL: "https://pinecone-alpha-default-rtdb.firebaseio.com",
        bundleId: 'com.whetstone.pinecone',
    } : {
        apiKey: "AIzaSyBJ7pnWYnAba18Ycv635hxPMvY9S5D5UpI",
        authDomain: "pinecone-e4730.firebaseapp.com",
        databaseURL: "https://pinecone-e4730-default-rtdb.firebaseio.com",
        projectId: "pinecone-e4730",
        storageBucket: "pinecone-e4730.appspot.com",
        messagingSenderId: "962448423102",
        appId: "1:962448423102:web:1725ce0cf484bbea6306ef",
        measurementId: "G-P5Y2RKRV54"
      };