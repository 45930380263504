// @flow

import React, { useState } from 'react';
import { getLoadingIndicator } from '../../UI Resources';
import type { CurrentUsersObject } from '../../../flowTypes';
import type { ComponentType } from 'react';


import checkIcon from '../../../assets/svgs/blue-check.svg';
import checkWhite from '../../../assets/svgs/check-white.svg';
import _ from 'lodash';
type ActivateFormProps = {
    networkKey?: string;
    onDone: () => void;
    currentUsers?: CurrentUsersObject,
    activateNetwork: (code: string) => Promise<void>
};

export const ActivateNetworkByCodeForm: ComponentType<ActivateFormProps> = ({ activateNetwork, onDone = _.noop }) => {
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [sendingNotification, setSendingNotification] = useState(false);
    const [sendNotificationError, setSendNotificationError] = useState('');

    const sendNotification: () => Promise<void> = async () => {
        if (!code) return;

        setSendingNotification(true)
        try {
            const response = await activateNetwork(code)
            if (response?.error) {
                setSendNotificationError(response?.error);
                setSendingNotification(false);
                return
            }
            setSendingNotification(false);
            setSuccess(true)
        } catch (e) {

            setSendNotificationError(e?.error || 'Unexpected error try again');
            setSendingNotification(false);
        }
        setSendingNotification(false);
    }

    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={checkIcon} className="modal-icon" alt="modal icon" />
                </div>
                {success ? (
                    <p testid="modal-title" className="modal-title">Account Activated</p>
                ) : (
                    <p testid="modal-title" className="modal-title">Activate Your Account</p>
                )}
            </div>
            {success ? (
                <div className="modal-bd d-flex flex-column justify-content-center">
                    <button testid="onbroadcast-sent" className="btn btn-block btn-lg app-btn-success mt-3" onClick={onDone}>
                        Okay
                    </button>
                </div>
            ) : (
                <>

                    <div className="modal-bd">
                        <div className="">
                            {sendNotificationError ? (
                                <p testid="notification-error" className="form-error text-center">{sendNotificationError}</p>
                            ) : null}
                            <div className="form-group">
                                <label>Code</label>
                                <input
                                    testid="title-input"
                                    className="form-control"
                                    placeholder="Title"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>

                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <div className="modal-bd d-flex flex-column justify-content-center">
                                <button
                                    testid="submit-button"
                                    className="btn btn-block btn-lg app-btn-success mt-3"
                                    disabled={!code}
                                    onClick={sendNotification}
                                >
                                    {sendingNotification ? (
                                        getLoadingIndicator()
                                    ) : (
                                        <>
                                            <img src={checkWhite} className="image-icon button-icon" alt="send Icon" />
                                            {'Activate'}
                                        </>
                                    )}
                                </button>
                                <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                    Cancel
                                </button>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </div>
    );
};
