// @flow
import React, { useState } from 'react';


import protocolIcon from '../../../assets/svgs/protocol-blue.svg';

import type { ComponentType } from 'react';
import _ from 'lodash';



type NameFormProps = {
    saveProtocol: (name: string) => void;
    onDone: () => void;
    defaultName: string

};

export const ProtocolNameForm: ComponentType<NameFormProps> = ({ onDone = _.noop, defaultName = "", saveProtocol = _.noop }) => {
    const [name, setName] = useState(defaultName || "");

    const onSaveName = () => {
        saveProtocol(name);
    }


    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={protocolIcon} className="modal-icon" alt="modal icon" />
                </div>
                <p testid="modal-title" className="modal-title">
                    Name Protocol
                </p>
            </div>
            <div className="">
                <>

                    <div className="modal-bd ">
                        <div className="d-flex flex-column justify-content-center">

                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    placeholder="Enter a name"
                                    testid="name-input"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ color: name ? 'black' : '#94A3B8' }}
                                />
                            </div>

                        </div>
                        <div className="d-flex flex-column justify-content-center mt-2">
                            <button
                                testid="submit-button"
                                className="btn btn-block btn-lg app-btn-primary mt-3"
                                onClick={onSaveName}
                                disabled={!!!name}
                            >
                                {'Save'}
                            </button>
                            <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
};
