// @flow

import { WaveLoading } from 'react-loadingg';
import React from 'react';

import type { Node } from 'react';

import type {
    NumericFilter,
    OtherFilter,
    QualitativeMetricFilter,
    CurrentUsersObject,
    CurrentUser,
    LogInCollection,
    MetricScoreInCollection,
    HabitScoreInCollection,
    ContentObject,
    Summary,
    Protocol,
    Metric,
} from '../../flowTypes';
import { MONTH_GROUPING_CUTOFF, YEAR_GROUPING_CUTOFF, METRIC_SCORE_MAPPING } from '../../Constants';
import _ from 'lodash';
import moment from 'moment';

export const filterIDString = (id: string): string => {
    let value = id;
    const regex = new RegExp(/[a-zA-Z0-9-]/g);
    value = _.trim(value);
    value = replaceAll(value, ' ', '-');
    value = (value.match(regex) || []).join('');

    return value.toLocaleLowerCase();
};

function replaceAll(string: string, search: string, replace: string) {
    return string.split(search).join(replace);
}

export const filterProtocolIDString = (id: string): string => {
    let value = id;
    const regex = new RegExp(/[a-zA-Z0-9- ]/g);
    value = _.trim(value);
    value = (value.match(regex) || []).join('');
    value = replaceAll(value, ' ', '-');

    return value.toLocaleLowerCase();
};

export const convertProtocolIDToName = (id: string): string => {
    let value = id || '';
    value = replaceAll(value || '', '-', ' ');
    value = replaceAll(value || '', '_', ' ');
    value = value.toLocaleLowerCase();
    return title(value);
};

function title(str) {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase();
    });
}

export const getRandomID = (): string => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 5);
};

export const getMediumRandomID = (): string => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
};

export const getLongRandomID = (): string => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 11);
};

export const filterNetworkKeyString = (id: string): string => {
    let value = id;
    const regex = new RegExp(/[a-zA-Z-0-9_-]/g);

    value = (value.match(regex) || []).join('');

    return value.substr(0, 20);
};

export const getTodayString = (): string => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    return today;
};

export const getMonthFromDateString = (dateString: string): string => {
    const preDate = new Date(dateString);
    const date = new Date(preDate.getFullYear(), preDate.getMonth(), 1);

    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
};

export const getMonday = (dateString: string): string => {
    let d = new Date(dateString);
    let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    const date = new Date(d.setDate(diff));

    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
};

export const encodeUserEmail = (userEmail: string): string => {
    if (!userEmail) {
        return '';
    }

    // eslint-disable-next-line
    return userEmail.replace(/\./g, ',');
};

export const decodeUserEmail = (userEmail: string): string => {
    // eslint-disable-next-line
    return userEmail.replace(/\,/g, '.');
};

export function epochToJsDate(ts: number): Date {
    // ts = epoch timestamp
    // returns date obj
    return new Date(ts * 1000);
}

export function getGroupingAdjustedLogDates(logDates: string[], logsToFilterWith?: { x: Date, y: any }[]): string[] {
    if (logDates.length > MONTH_GROUPING_CUTOFF && logDates.length <= YEAR_GROUPING_CUTOFF) {
        logDates = logDates.map((date) => getMonday(date));
    } else if (logDates.length > YEAR_GROUPING_CUTOFF) {
        logDates = logDates.map((date) => getMonthFromDateString(date));
    }

    logDates = _.uniq(logDates);

    if (logsToFilterWith && logsToFilterWith.length) {
        logDates = logDates.filter((date) => logsToFilterWith.find((log) => _.isEqual(log.x, new Date(date))));
    }

    return logDates;
}

export function getGroupingAdjustedLogs(logs: { date: string }[], logDatesLength: number): any[] {
    if (logDatesLength > MONTH_GROUPING_CUTOFF && logDatesLength <= YEAR_GROUPING_CUTOFF) {
        logs = logs.map((currentLog) => ({
            ...currentLog,
            date: getMonday(currentLog.date),
        }));
    } else if (logDatesLength > 105) {
        logs = logs.map((currentLog) => ({
            ...currentLog,
            date: getMonthFromDateString(currentLog.date),
        }));
    }

    return logs;
}

export const getLoadingIndicator = (color: string = '#000000', opacity: number = 0.5): Node => {
    return <WaveLoading color={color} style={{ opacity }} speed={1} />;
};

export const getCalendarFormatForDate = (dateString?: string): string => {
    const date = new Date(dateString || getTodayString());
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
};

export const getShortCalendarFormatForDate = (dateString?: string): string => {
    const date = new Date(dateString || getTodayString());
    const options = { month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
};

export const applyFiltersToLogs = (
    currentUsers: CurrentUsersObject,
    thisMonthMetricLogs: Array<LogInCollection>,
    filteredTimeFrame: string,
    numericFilters?: Array<NumericFilter> = [],
    otherFilters?: Array<OtherFilter> = [],
    qualitativeMetricFilters?: Array<QualitativeMetricFilter> = [],
    includeNumericMetrics?: boolean
): Array<any> => {
    let logsToFilter = populateInfoOntoLogs(thisMonthMetricLogs, currentUsers);

    let startDay = new Date();

    const filteringForNumericMetric = qualitativeMetricFilters.find((filter) => filter.key === 'metricName');

    logsToFilter =
        includeNumericMetrics || filteringForNumericMetric
            ? logsToFilter
            : logsToFilter.filter((log) => !log.isNumericMetric);
    if (filteredTimeFrame === 'week') {
        startDay.setDate(startDay.getDate() - 10);
        startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
        logsToFilter = logsToFilter.filter((log) => new Date(log.date).getTime() >= startDay.getTime());
    }

    if (filteredTimeFrame === 'month') {
        startDay.setDate(startDay.getDate() - 33);
        startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
        logsToFilter = logsToFilter.filter((log) => new Date(log.date).getTime() >= startDay.getTime());
    }

    if (filteredTimeFrame === 'year') {
        startDay.setDate(startDay.getDate() - 368);
        startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
        logsToFilter = logsToFilter.filter((log) => new Date(log.date).getTime() >= startDay.getTime());
    }

    for (const filter of otherFilters) {
        const { key, value, isPartialString } = filter;
        logsToFilter = isPartialString
            ? logsToFilter.filter((log) => log[key] && log[key].includes(value))
            : logsToFilter.filter((log) => log[key] === value);
    }

    for (const filter of numericFilters) {
        const { key, value, isMin } = filter;
        logsToFilter = isMin
            ? logsToFilter.filter((log) => log[key] >= value)
            : logsToFilter.filter((log) => log[key] <= value);
    }

    for (const filter of qualitativeMetricFilters) {
        const { key, value, isPartialString } = filter;
        logsToFilter = isPartialString
            ? logsToFilter.filter((log) => !log.metricName || (log[key] && log[key].includes(value)))
            : logsToFilter.filter((log) => !log.metricName || log[key] === value);
    }

    return logsToFilter;
};

export const getUserForLog = (log: LogInCollection, currentUsers: CurrentUsersObject): CurrentUser => {
    const userKeys = Object.keys(currentUsers);

    for (const key of userKeys) {
        if (currentUsers[key].loginInfo.username === log.user) {
            return currentUsers[key];
        }
        if (currentUsers[key].loginInfo.email === log.email) {
            return currentUsers[key];
        }

        if (currentUsers[key].uid && currentUsers[key].uid === log.uid) {
            return currentUsers[key];
        }
    }

    // $FlowFixMe - This is logic that will eventually get deprecated in favor of pure uids
    return { personalStatsInfo: {}, loginInfo: {}, uid: '' };
};

export const populateInfoOntoLogs = (rawLogs: any[], currentUsers: CurrentUsersObject): any[] => {
    let logs = [...rawLogs];
    let newLogs = [];

    for (let log of logs) {
        let newLog = _.cloneDeep(log);
        const user = getUserForLog(log, currentUsers);

        const { username, ...loginInfo } = user.loginInfo;
        if (username) {
            loginInfo.user = username;
        }

        newLog.uid = user.uid || log.uid;
        newLog = { ...newLog, ...loginInfo };
        newLog = { ...newLog, ...user.personalStatsInfo };

        newLogs.push(newLog);
    }

    return newLogs;
};

export const getTimeAdjustedTrend: (trend: { x: Date, y: any }[], currentPeriod: { period: string, number: number }) => any = (trend, currentPeriod) => {

    const groupAverage = (arr, n) => {
        const res = [];
        for (let i = 0; i < arr.length;) {
            let sum = 0;
            let numberOfItems = 0;
            for (let j = 0; j < n; j++) {
                if (i < arr.length) {
                    numberOfItems++;
                }
                sum += +arr[i++] || 0;
            };
            res.push(sum / numberOfItems);
        }
        return res
    };

    if ((trend?.length || 0) < 10 || currentPeriod.period === 'w') {
        return trend;
    } else if (trend?.length <= 30) {
        const xValues = trend.filter((item, index) => !(index % 5)).map(i => i.x);
        const yValues = groupAverage(trend.map(i => i.y), 5);
        return [...xValues.map((x, i) => ({ x, y: yValues[i] }))];
    } else if (trend?.length <= 60) {
        const xValues = trend.filter((item, index) => !(index % 10)).map(i => i.x);
        const yValues = groupAverage(trend.map(i => i.y), 10);
        return [...xValues.map((x, i) => ({ x, y: yValues[i] }))];
    } else if (trend?.length <= 120) {
        const xValues = trend.filter((item, index) => !(index % 20)).map(i => i.x);
        const yValues = groupAverage(trend.map(i => i.y), 20);
        return [...xValues.map((x, i) => ({ x, y: yValues[i] }))];
    } else if (trend?.length <= 240) {
        const xValues = trend.filter((item, index) => !(index % 40)).map(i => i.x);
        const yValues = groupAverage(trend.map(i => i.y), 40);
        return [...xValues.map((x, i) => ({ x, y: yValues[i] }))];
    } else {
        const xValues = trend.filter((item, index) => !(index % 80)).map(i => i.x);
        const yValues = groupAverage(trend.map(i => i.y), 80);
        return [...xValues.map((x, i) => ({ x, y: yValues[i] }))];
    }
}

export const categorizeLogsByDate = ({
    thisMonthMetricLogs = [],
    statsFilteredTimeFrame: filteredTimeFrame,
    statsNumericFilters: numericFilters,
    statsOtherFilters: otherFilters,
    statsQualitativeMetricFilters: qualitativeMetricFilters,
    currentUsers = {},
    thisMonthHabitLogs = [],
}: $Shape<ContentObject>): {
    metricDatesMap: { [dateString: string]: LogInCollection[] },
    dateScoreMap: { [dateString: string]: number },
    habitDatesMap: { [dateString: string]: HabitScoreInCollection },
    lastDate: string,
} => {
    thisMonthMetricLogs = applyFiltersToLogs(
        currentUsers,
        thisMonthMetricLogs,
        filteredTimeFrame,
        numericFilters,
        otherFilters,
        qualitativeMetricFilters,
        true
    );
    thisMonthHabitLogs = applyFiltersToLogs(
        currentUsers,
        thisMonthHabitLogs,
        filteredTimeFrame,
        numericFilters,
        otherFilters,
        qualitativeMetricFilters
    );

    const sortedMetricLogs = _.orderBy(thisMonthMetricLogs, ['dateTimeStamp'], ['desc']);
    const lastDate = _.last(sortedMetricLogs)?.date;
    const metricDatesMap = {};
    const habitDatesMap = _.groupBy(thisMonthHabitLogs, 'date');
    const dateScoreMap = {};
    for (let log of sortedMetricLogs) {
        const { date } = log;
        metricDatesMap[date] = [...(metricDatesMap[date] || []), log];
        if (!log.isNumericMetric) {
            const score = METRIC_SCORE_MAPPING[log.metricScore];
            const length = metricDatesMap[date].length;
            dateScoreMap[date] = dateScoreMap[date] ? (dateScoreMap[date] * (length - 1) + score) / length : score;
        }
    }

    return {
        metricDatesMap,
        dateScoreMap,
        lastDate,
        habitDatesMap,
    };
};

export const calculateOpacity = (score?: number): number => {
    if (!score) return 1;
    return _.round((0.7 * score + 1.5) / 5, 2);
};
export const getCalendarTooltip = (score?: number = Infinity): string => {
    switch (true) {
        case score <= 1:
            return 'terrible';
        case score <= 2:
            return 'bad';
        case score <= 3:
            return 'okay';
        case score <= 4:
            return 'good';
        case score <= 5:
            return 'excellent';
        default:
            return '';
    }
};

export const summarizeDateInfo = (
    dateData: { metrics: { [date: string]: MetricScoreInCollection[] }, actions: { [date: string]: HabitScoreInCollection[] } },
    dateString: string
): Summary => {
    const specificMetricDate = dateData.metrics[dateString];
    const specificHabitsDate = dateData.actions[dateString];
    const metrics = _.groupBy(specificMetricDate || [], 'displayName');
    const actions = _.groupBy(specificHabitsDate || [], 'displayName');
    const users = _.uniqBy([...(specificHabitsDate || []), ...(specificMetricDate || [])], 'username');
    return { metrics, actions, users };
};
export const calculateAge = (birthday: ?Date): number => { // birthday is a date

    let dateString = moment(birthday).format('MMM D, YYYY');
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const hexToRGB = (hexColor: string): [number, number, number] | null => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

export const RGBToHex = (rgb: [number, number, number]): string => {
    return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

export const interpolateColor = (maxColorHex: string, minColorHex: string, factor: number): string | null => {
    const maxColorRGB = hexToRGB(maxColorHex);
    const minColorRGB = hexToRGB(minColorHex);
    if (!maxColorRGB || !minColorRGB) return null
    var result = [...maxColorRGB];
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (minColorRGB[i] - maxColorRGB[i]));
    }

    return RGBToHex(result);
}

export const matchFilter = (log: MetricScoreInCollection | HabitScoreInCollection, currentUsers: any, startTimestamp: number, endTimestamp: number): boolean => {
    return !!(currentUsers[log.uid] && log.dateTimestamp >= startTimestamp && log.dateTimestamp <= endTimestamp)
}

export const periodToTimestamp = (period: { number: number, period: string }): { startTimestamp: number, endTimestamp: number } => {
    const today = moment();
    const endTimestamp = today.valueOf();
    const startMoment = today.subtract(period.number, period.period);
    const startTimestamp = startMoment.valueOf();

    return {
        startTimestamp,
        endTimestamp
    }
}
export const periodToPriorAndCurentTimestamp = (period: { number: number, period: string }): { current: { start: any, end: any }, prior: { start: any, end: any } } => {
    const today = moment();
    const current = { start: '', end: '' }
    current.end = today.valueOf();
    const startMoment = today.subtract(period.number, period.period);
    current.start = startMoment.valueOf();
    const priorStartMoment = startMoment.subtract(period.number, period.period)
    const prior = { start: '', end: '' }
    prior.end = current.start;
    prior.start = priorStartMoment.valueOf();

    return {
        current,
        prior
    }
}

export const getStreakEmoji = (streak: number): string => {
    if (streak >= 25) return '🐲';
    if (streak >= 10) return '🦁';
    if (streak >= 5) return '🐱';
    if (streak >= 1) return '🐣';
    return '🥚';
};

export function formatPhoneNumber(phoneNumberString: string = ''): any {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      // var intlCode = (match[1] ? '+1 ' : '');
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
}

export const categorizeProtocols = (protocols: Protocol[]): any => {
    const protocolsObject = {};
    protocols.forEach((protocol) => {
        const { id, level } = protocol;
        if (protocolsObject[id]) {
            protocolsObject[id].levels[level] = protocol;
        }
        else {
            const newProtocol = {
                isAPrivateNetworkProtocol: protocol.isAPrivateNetworkProtocol,
                name: protocol.name,
                id: protocol.id,
                usersWhitelist: protocol.usersWhitelist,
                dateTimestamp: protocol.dateTimestamp,
                levels: []
            }
            newProtocol.levels[level] = protocol;
            protocolsObject[id] = newProtocol;
        }
    });
    return protocolsObject;
}

export function getAllIndexes(arr: string[], val: string): any {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) !== -1) {
        indexes.push(i);
    }
    return indexes;
}




export const categorizeQualities = (metrics: Metric[]): any => {
    const numerics = [];
    const nonNumerics = [];
    metrics.forEach(({ isNumericMetric, displayName }, id) => {
        if (isNumericMetric) numerics.push({ displayName, id });
        else nonNumerics.push({ displayName, id });
    })

    return { numerics, nonNumerics }
}


