// @flow
import 'bootstrap/dist/css/bootstrap.css';
import '../../general.scss';
import 'react-circular-progressbar/dist/styles.css';
import React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { fetchMetricLogsForYear, fetchHabitLogsForYear, fetchUsers } from '../../actions';
import { getLoadingIndicator } from '../UI Resources';
import _ from 'lodash';

import exportIcon from '../../assets/svgs/export.svg';
import searchIcon from '../../assets/svgs/search.svg';



import type { HabitScoreInCollection, MetricScoreInCollection, AuthObject, CurrentUsersObject } from '../../flowTypes';
import type { ComponentType } from 'react';



type Props = {
    habitLogs: HabitScoreInCollection[],
    metricLogs: MetricScoreInCollection[],
    fetchHabitLogsForYear: (networkKey: string) => void,
    fetchMetricLogsForYear: (networkKey: string) => void,
    auth: AuthObject,
    currentUsers?: CurrentUsersObject,
    fetchUsers: () => void,
}

type State = {
    searchString: string,
    sortedLogs: any[],
    searchedLogs: any[],
    mounting: boolean,
}



export class Activity extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            searchString: '',
            sortedLogs: [],
            searchedLogs: [],
            mounting: false,
        };
    }
    componentDidMount() {

        const {
            metricLogs,
            habitLogs,
            auth = {},
            fetchMetricLogsForYear = _.noop,
            fetchHabitLogsForYear = _.noop,
            currentUsers = {},
            fetchUsers = _.noop,
        } = this.props;

        const { networkKey } = auth;
        if (!metricLogs?.length) {
            fetchMetricLogsForYear(networkKey);
        }
        if (!habitLogs?.length) {
            fetchHabitLogsForYear(networkKey);
        }
        if (!Object.keys(currentUsers).length) {
            fetchUsers();
        }

        if (!metricLogs?.length && !habitLogs?.length) {
            return;
        }

        this.setState({ mounting: true });

        setTimeout(this.completeAsyncTasks, 1000);
    }

    completeAsyncTasks: () => Promise<void> = async () => {

        const {
            metricLogs,
            habitLogs,
        } = this.props;
        const { sortedLogs } = this.state;

        if (sortedLogs.length) {
            this.setState({ mounting: false });
            return;
        }

        let logs = metricLogs.filter(({ isNumericMetric }) => !isNumericMetric).concat(habitLogs);
        logs = _.reverse(_.uniqWith(_.reverse(logs), (log1, log2) =>
            log1.metricName === log2.metricName
            && log1.habitName === log2.habitName
            && log1.date === log2.date
        ));

        const newSortedLogs = _.orderBy(logs, 'dateTimestamp', 'desc');
        this.setState({ sortedLogs: newSortedLogs, mounting: false });
    }

    async componentDidUpdate(prevProps: Props) {
        const {
            metricLogs,
            habitLogs,
        } = this.props;

        if ((this.props.metricLogs && !prevProps.metricLogs) || (this.props.habitLogs && !prevProps.habitLogs)) {
            let logs = (metricLogs || []).filter(({ isNumericMetric }) => !isNumericMetric).concat(habitLogs || []);
            logs = _.reverse(_.uniqWith(_.reverse(logs), (log1, log2) =>
                log1.metricName === log2.metricName
                && log1.habitName === log2.habitName
                && log1.date === log2.date
            ));

            const sortedLogs = _.orderBy(logs, 'dateTimestamp', 'desc');
            this.setState({ sortedLogs });
        }
    }

    updateSearchString(searchString: string) {
        const { sortedLogs } = this.state;

        const searchedLogs = searchString ? sortedLogs.filter(({ uid, protocolName, displayName }) => uid?.toLowerCase().indexOf(searchString) > -1 || protocolName?.toLowerCase().indexOf(searchString) > -1 || displayName?.toLowerCase().indexOf(searchString) > -1) : sortedLogs;

        if (searchString && !searchedLogs.length) {
            this.setState({ searchedLogs: ['none found'] });
            return;
        }

        this.setState({ searchedLogs });
    }

    _renderActivityList(): any {
        const { habitLogs, metricLogs } = this.props;
        const { sortedLogs, searchedLogs, mounting } = this.state
        if (!habitLogs?.length || !metricLogs?.length || mounting) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        <div className="mx-auto d-inline-block">
                            {getLoadingIndicator()}
                        </div>
                    </td>
                </tr>
            )
        }
        if (!sortedLogs.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        No data found
                    </td>
                </tr>
            )
        }

        const sortedSearchLogs = searchedLogs.length ? searchedLogs : sortedLogs;
        if (sortedSearchLogs[0] === 'none found') {
            return [];
        }
        return sortedSearchLogs.map(({ date, displayName, protocolName, metricName, metricScore, habitScore, uid, }, index) => (
            <tr testid="scorecard-row" key={`user-${index}`}>
                <td className="text-center">
                    {date}
                </td>
                <td className="pl-4 scorecard-uid">
                    {_.take(uid, 6)}
                </td>
                <td className="pl-4">
                    <b>{protocolName}</b>
                </td>
                <td className="pl-4">
                    {displayName}
                </td>
                <td className="pl-4 text-center">
                    <button className={`btn btn-sm btn-activity ${metricName ? metricScore : (habitScore === 1 ? "done" : "miss")}`}>
                        {metricName ? metricScore : (habitScore === 1 ? "done" : "miss")}
                    </button>
                </td>
            </tr>
        ))
    }

    _getCSVData(): string[][] {

        const { sortedLogs, searchedLogs } = this.state;
        const header = ['User Id', 'Protocol', "Event", 'Score']

        const sortedSearchLogs = searchedLogs.length ? searchedLogs : sortedLogs;
        const csvArray = sortedSearchLogs.map(({ displayName, uid, protocolName, metricScore, habitScore }) => [uid, protocolName, displayName, metricScore ? metricScore : (habitScore === 1 ? "done" : 'miss')])
        return [header].concat(csvArray);
    }

    render(): any {

        return (
            <div className="manual-container px-5" style={{ position: 'relative' }}>
                <div className="text-center activity-table-container">
                    <h4 className="activity-header"><b>Activity Log</b></h4>
                    <p>
                        The activity log shows all variables recorded by members in your network. You can export the data
                        table for analysis and discover new insights.
                    </p>
                </div>
                <CSVLink testid="download-csv" className="btn app-btn-outline btn-lg" style={{ position: 'absolute', top: '50px', right: '50px' }} data={this._getCSVData()}>
                    <img className="image-icon button-icon" src={exportIcon} alt="export icon" />
                    Export
                </CSVLink>
                <div className="activity-table-container">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">
                                        <div className="search-uid">
                                            <img className="image-icon " src={searchIcon} alt="search icon" />
                                            <input
                                                testid="search-user"
                                                onBlur={(e) => this.updateSearchString(e.target.value.toLocaleLowerCase())}
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        this.updateSearchString(this.state.searchString.toLocaleLowerCase());
                                                    }
                                                }}
                                                onChange={(e) => this.setState({ searchString: e.target.value })}
                                                type="text"
                                                placeholder="Search"
                                                className="form-input"
                                                aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                            />
                                        </div>
                                    </th>
                                    <th scope="col">User ID</th>
                                    <th scope="col">
                                        Protocol
                                    </th>
                                    <th scope="col">
                                        Event
                                    </th>
                                    <th scope="col" className="text-center">
                                        Score
                                    </th>
                                </tr>
                            </thead>
                            {<tbody>{this._renderActivityList()}</tbody>}
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}



const mapStateToProps = ({ auth, content: { currentUsers, metricLogs, habitLogs, chosenProtocolsMapping } = {} }) => ({
    auth,
    metricLogs,
    habitLogs,
    chosenProtocolsMapping,
    currentUsers,
});

const ConnectedActivity: ComponentType<{}> = connect(mapStateToProps, {
    fetchMetricLogsForYear,
    fetchHabitLogsForYear,
    fetchUsers,
})(Activity);

export default ConnectedActivity;