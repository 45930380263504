// @flow

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";

import { config } from './config';

import type { NetworkPost, CurrentUser, CurrentUsersObject } from '../../flowTypes';

// import type {NetworkPost} from '../../flowTypes';


export function intializeFirebase(host: string = window.location.host): void {
    firebase.initializeApp(config());
    // if(host === 'localhost:3000') firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const networkKey = window.localStorage.networkKey;
    const password = window.localStorage.password;
    if (!networkKey) {
        // not signed in
        firebase.auth().signInAnonymously();
    } else {
        // initialize network auth
        loginToFirebase(networkKey, password);
    }
}

export const signupForFirebase = async (networkKey: string, password: string): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(networkKey + '@admins.pineconeapp.com', password);
}

export const updatePassword = async (newPassword: string): Promise<void> => {
    return firebase.auth().currentUser.updatePassword(newPassword);
}

export const setIsClosedNetwork = async (networkKey: string, closed: boolean): Promise<any> => {
    const database = firebase.database();
    return database.ref('networkRestriction/' + networkKey).set(closed);
}

export const getIsClosedNetwork = (networkKey: string): Promise<any> => {
    return firebase.database().ref('networkRestriction/' + networkKey).once('value');
}

export const loginToFirebase = async (networkKey: string, password: string): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(networkKey + '@admins.pineconeapp.com', password);
}

export const loginToFirebaseForModerator = async (networkKey: string, email: string, password: string): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(`WILLOW-MODERATOR-${networkKey}-${email}`, password);
}


export const setUIDInfo = async (uid: string, networkKey: string, email: string): Promise<any> => {
    const database = firebase.database();
    return database.ref('adminUidInfo/' + uid).set({ networkKey, email });
}

export const setUIDInfoOnFireStore = async (uid: string, networkKey: string, email: string): Promise<void> => {
    return firebase.firestore().collection('adminAuth').doc(uid).set({ networkKey, email });
}

export function getProtocolsWithNetworkKey(networkKey: string): Promise<any> {

    return firebase.database().ref('networkProtocolsV2/' + networkKey).once('value');
}

export function getProtocolsRefWithNetworkKey(networkKey: string): any {

    return firebase.database().ref('networkProtocolsV2/' + networkKey);
}

export function setProtocolsWithNetworkKey(networkKey: string, protocols: any): Promise<any> {

    return firebase.database().ref('networkProtocolsV2/' + networkKey).set(protocols);
}

export function setProtocolForIDWithNetworkKey(networkKey: string, protocols: any, protocolId: string): Promise<any> {

    return firebase.database().ref('networkProtocolsV2/' + networkKey + '/' + protocolId).set(protocols);
}

export function setProtocolEditor(networkKey: string, protocolId: string, email: string): Promise<any> {
    return firebase.database().ref('networkProtocolEditors/' + networkKey + '/' + protocolId).set(email);
}

export function getProtocolEditorRef(networkKey: string): any {
    return firebase.database().ref('networkProtocolEditors/' + networkKey);
}

export function getProtocolEditorForProtocolRef(networkKey: string, protocolId: string): any {
    return firebase.database().ref('networkProtocolEditors/' + networkKey + '/' + protocolId);
}

export function getUsersForNetworkKey(networkKey: string): Promise<any> {

    return firebase.database().ref('networks/' + networkKey + '/users').once('value');
}

export function removeUserFromNetwork(uid: string): void {
    const networkKey = window.localStorage.networkKey;

    firebase.database().ref('networkKeys/' + uid).set('NO_NETWORK');
    firebase.database().ref('networks/' + networkKey + '/users/' + uid).set(null);
    firebase.firestore().collection('userAuth').doc(uid).update({ networkKey: 'NO_NETWORK' });
}

export function getProtocolForUser(uid: string): Promise<any> {

    return firebase.database().ref('chosenProtocols/' + uid).once('value');
}

export function setProtocolForUser(uid: string | string[], protocolId: string): Promise<any> {
    const protocolRef = firebase.database().ref('chosenProtocols');
    if (typeof uid !== 'string') {
        const updates = {};
        uid.forEach((userid) => {
            updates[userid] = protocolId;
        })
        return protocolRef.update(updates);
    }

    return firebase.database().ref(`chosenProtocols/${uid}`).set(protocolId);
}


export function getLevelInfoForUser(user: string): Promise<any> {

    return firebase.database().ref('levels/' + user).once('value');
}

export function getNotificationTokenForUser(user: string): Promise<any> {

    return firebase.database().ref('notificationTokens/' + user).once('value');
}

export async function getNotificationTokensForNewWork(currentUsers: CurrentUsersObject): Promise<any> {
    const promises = []
    for (const userId in currentUsers) {
        promises.push(firebase.database().ref(`notificationTokens/${userId}`).once('value'))
    }

    return await Promise.all(promises);
}

export function setLevelForUser(uid: string | string[], protocolID: string, level: number): Promise<any> {
    const levelsRef = firebase.database().ref('levels');
    if (typeof uid !== 'string') {
        const updates = {};
        uid.forEach((userid) => {
            updates[`${userid}/${protocolID}`] = level;
        })

        return levelsRef.update(updates)
    }
    return firebase.database().ref(`levels/${uid}/${protocolID}`).set(level);
}

export function getAllSentNotifications(networkKey: string): Promise<any> {
    const query = firebase.firestore().collection('networkNotifications')
        .where('networkKey', '==', networkKey)
        .orderBy('timestamp', 'desc')
        .limit(100);
    return query.get();
}

export function getPosts(networkKey: string): Promise<any> {
    const query = firebase.firestore().collection('networkFeed')
        .where('networkKey', '==', networkKey)
        .orderBy('timestamp', 'desc')
        .limit(100);
    return query.get();
}

export function uploadPost(post: NetworkPost): Promise<any> {
    return firebase.firestore().collection('networkFeed').add(post);
}

export function getAllMetricLogsForMonthForNetworkKey(networkKey: string): Promise<any> {

    let startDay = new Date();
    startDay.setDate(startDay.getDate() - 365);
    startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
    let endDay = new Date();
    endDay.setDate(endDay.getDate() - 2);
    endDay = new Date(endDay.getFullYear(), endDay.getMonth(), endDay.getDate(), 0, 0, 0);

    const query = firebase.firestore().collection('metricScores')
        .where('networkKey', '==', networkKey)
        .where('dateTimestamp', '>=', startDay.getTime())
    return query.get();
}


export function getMetricLogsForYear(networkKey: string): Promise<any> {
    let startDay = new Date();
    startDay.setDate(startDay.getDate() - 365);
    startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
    const query = firebase.firestore().collection('metricScores')
        .where('networkKey', '==', networkKey)
        .where('dateTimestamp', '>=', startDay.getTime())
    return query.get();
}
export function getHabitLogsForYear(networkKey: string): Promise<any> {
    let startDay = new Date();
    startDay.setDate(startDay.getDate() - 365);
    startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
    const query = firebase.firestore().collection('habitScores')
        .where('networkKey', '==', networkKey)
        .where('dateTimestamp', '>=', startDay.getTime())
    return query.get();
}

export function getAllHabitLogsForMonthForNetworkKey(networkKey: string): Promise<any> {

    let startDay = new Date();
    startDay.setDate(startDay.getDate() - 365);
    startDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0);
    let endDay = new Date();
    endDay.setDate(endDay.getDate() - 2);
    endDay = new Date(endDay.getFullYear(), endDay.getMonth(), endDay.getDate(), 0, 0, 0);

    const query = firebase.firestore().collection('habitScores')
        .where('networkKey', '==', networkKey)
        .where('dateTimestamp', '>=', startDay.getTime())
    return query.get();
}

export function getAdminForNetworkKey(networkKey: string): Promise<any> {
    return firebase.database().ref('networks/' + networkKey + '/admin').once('value');
}

export function getAdminEmailForNetworkKey(networkKey: string): Promise<any> {
    return firebase.database().ref('networks/' + networkKey + '/admin/email').once('value');
}

export function addModeratorForNetworkKey(networkKey: string, email: string): Promise<any> {
    const addModeratorFunc = firebase.functions().httpsCallable('admin-createNetworkModerator');
    return addModeratorFunc({ networkKey, email });
}

export function getModeratorsForNetwork(networkKey: string): Promise<any> {
    return firebase.database().ref('networkModerators/' + networkKey).once('value');
}

export function setModeratorsForNetwork(networkKey: string, moderators: any): Promise<any> {
    return firebase.database().ref('networkModerators/' + networkKey).set(moderators);
}

export function addModeratorEntryForAdmin(networkKey: string, email: string, uid: string): Promise<any> {
    return firebase.database().ref('networkModerators/' + networkKey + '/' + uid).set({
        createdTime: Date.now(),
        email,
        uid,
        isAdmin: true
    });
}

export function createNetwork(adminAccountPayload: any, networkKey: string): Promise<any> {

    return firebase.database().ref('networks/' + networkKey + '/admin').set(adminAccountPayload);
}

export function createNetworkNameListing(networkKey: string): Promise<any> {

    return firebase.database().ref('networkNames/' + networkKey).set(true);
}

export function setNetworkSecretKey(networkKey: string, secretKey: string): Promise<any> {

    return firebase.database().ref('networkSecretKeys/' + networkKey).set(secretKey);
}

export function getNetworkSecretKey(networkKey: string): Promise<any> {

    return firebase.database().ref('networkSecretKeys/' + networkKey).once('value');
}

export function getNetworkNameListing(networkKey: string): Promise<any> {
    return firebase.database().ref('networkNames/' + networkKey).once('value');
}

export function updateNetworkUser(networkKey: string, userId: string, user: CurrentUser): Promise<any> {
    return firebase.database().ref(`networks/${networkKey}/users/${userId}`).set(user);
}




export function resetPassword({ token, password }: { token: string, password: string }): Promise<any> {
    const resetPasswordFunc = firebase.functions().httpsCallable('admin-resetPassword');
    return resetPasswordFunc({ token, password });
}

export function sendRecoveryEmail(networkKey: string, email: string,): Promise<any> {
    const sendRecoveryEmailFunc = firebase.functions().httpsCallable('admin-sendRecoveryMail');
    return sendRecoveryEmailFunc({ networkKey, email })
}

export function sendBroadcast(networkKey: string, title: string, body: string): Promise<any> {
    const sendBroadcastFunc = firebase.functions().httpsCallable('notifications-broadcastToNetworkUsers');
    return sendBroadcastFunc({ networkKey, title, body })
}



