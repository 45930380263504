// @flow

import React from 'react';
import Login from './Login';
import PasswordRecovery from './Login/PasswordRecovery';
import Protocol from './Content/Protocol/index';
import { connect, Connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../history';
import {
    assignUserInfoFromLocalStorage,
} from '../actions';
import Header from './Header';
import TopNav from './Header/TopNav';
import Users from './Content/Users';
// import Feed from './Content/Feed';
import Insights from './Content/Insights';
import Account from './Content/Account';
import Calendar from './Content/Calendar';
import Activity from './Content/Activity';
import { getStripeComponent } from './stripeComponent';
import { Elements } from '@stripe/react-stripe-js';
import 'bootstrap/dist/css/bootstrap.css';
import '../custom.scss';
import Div100vh from 'react-div-100vh'

import type { Node } from 'react';
import { initializeSentry } from './sentry';
import { isMobile } from 'react-device-detect';

initializeSentry();

const stripeComponent = getStripeComponent();

type Props = {
    assignUserInfoFromLocalStorage: () => void,
    isLoggedIn: boolean,
}

export const Container: (props: any) => any = ({ children }) => {
    return isMobile ? <Div100vh className='main-section-container-mobile'>{children}</Div100vh> : <div className="main-section-container">{children}</div>;
}
export class App extends React.Component<Props> {

    componentDidMount(): void {

        this.assignLocalStorageToState();
    }

    assignLocalStorageToState(): void {
        this.props.assignUserInfoFromLocalStorage();
    }

    render(): Node {

        return (
            <div className="">
                <Elements stripe={stripeComponent}>
                    <Router history={history}>
                        {!this.props.isLoggedIn && (
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <Route path="/recovery" component={PasswordRecovery} />
                            </Switch>
                        )}
                        {this.props.isLoggedIn && (
                            <div className="d-flex flex-row" style={{overflow: 'scroll', paddingTop: 0}}>
                                <Header />
                                <Container>
                                    <TopNav />
                                    <Route path="/" exact  ><Redirect to="/members" /></Route>
                                    <Route path="/protocols" exact component={Protocol} />
                                    <Route path="/members" exact component={Users} />
                                    {/* <Route path="/feed" exact component={Feed} /> */}
                                    <Route path="/insights" component={Insights} />
                                    <Route path="/data" component={Activity} />
                                    <Route path="/account" exact component={Account} />
                                    <Route path="/calendar" exact component={Calendar} />
                                </Container>
                            </div>
                        )}
                    </Router>
                </Elements>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        username: state.auth.username,
        networkKey: state?.auth?.networkKey,
        currentTime: state.utilities.currentTime,
    };
}

const connected: Connect<*> = connect(
    mapStateToProps,
    {
        assignUserInfoFromLocalStorage,
    }
)(App);

export default connected;