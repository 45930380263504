import 'bootstrap/dist/css/bootstrap.css';
import '../../custom.scss';
import logo from '../../assets/images/nameLogoBlack.png';
import bottomImage from '../../assets/images/screenPreview.png';

import { connect } from 'react-redux';
import { login } from '../../actions';
import {
    getAdminForNetworkKey,
    createNetwork,
    createNetworkNameListing,
    getNetworkNameListing,
    signupForFirebase,
    setUIDInfo,
    loginToFirebase,
    setUIDInfoOnFireStore,
    setNetworkSecretKey,
    getAdminEmailForNetworkKey,
    loginToFirebaseForModerator,
    addModeratorEntryForAdmin,
} from '../../api/firebase';
import { LOGIN_GENERIC_ERROR, MISMATCHED_PASSWORDS_ERROR, EMAIL_REGEX, EMAIL_INVALID, NETWORK_IN_USE, NETWORK_DOES_NOT_EXIST, INCORRECT_PASSWORD, WEAK_PASSWORD_ERROR, SIGNUP_GENERIC_ERROR } from '../../Constants';
import {
    isMobile,
} from "react-device-detect";

import React from 'react';

import { ElementsConsumer } from '@stripe/react-stripe-js';
import { filterNetworkKeyString, getLoadingIndicator, getMediumRandomID } from '../UI Resources';
// import { sendRecoveryEmail } from '../../api/axios';
import { sendRecoveryEmail } from '../../api/firebase';


export class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            passwordConfirm: '',
            firstName: '',
            lastName: '',
            networkKey: '',
            recoveryNetworkKey: '',
            sendingRecovery: false,
            recoveryError: '',
            recoverySent: false,
            signingUp: false,
            files: [],
            onSignupPage: false,
            currentPage: 'login',
            loggingIn: false,
            cardInformationIsComplete: false,
            signupError: null,
            loginError: null,
        };
    }

    goToSignUp = () => {
        this.setState({ currentPage: 'signup' });
    }

    goToLogin = () => {
        this.setState({ currentPage: 'login' });
    }
    goToRecovery = () => {
        this.setState({ currentPage: 'recovery' })
    }
    resetRecovery = () => {
        this.setState({ currentPage: 'login', recoveryError: '', recoverySent: false, sendingRecovery: false, recoveryNetworkKey: '' });
    }

    _onUsernameChanged = (e) => {
        this.setState({ username: e.target.value });
    }

    _onFirstNameChanged = (e) => {
        this.setState({ firstName: e.target.value });
    }

    _onNetworkKeyChanged = (e) => {
        this.setState({ networkKey: filterNetworkKeyString(e.target.value) });
    }

    _onPasswordChanged = (e) => {
        this.setState({ password: e.target.value });
    }

    _detectEnterButton = ({ key }) => {
        if (key === "Enter" && this.state.networkKey && this.state.password && !this.state.onSignupPage) {
            this._loginUser();
        }
    }

    _onPasswordConfirmChanged = (e) => {
        this.setState({ passwordConfirm: e.target.value });
    }
    _onRecoveryNetworkKeyChange = (e) => {
        this.setState({ recoveryNetworkKey: e.target.value });
    }


    _signUpUser = async () => {
        const {
            username: uppercaseUsername,
            password,
            passwordConfirm,
            firstName,
            networkKey,
            signingUp,
        } = this.state;

        const username = uppercaseUsername.toLocaleLowerCase();

        if (signingUp) return;

        this.setState({ signingUp: true });

        if (password !== passwordConfirm) {
            this.setState({ signupError: MISMATCHED_PASSWORDS_ERROR, signingUp: false });
            return;
        }

        if (password.length < 6) {
            this.setState({ signupError: WEAK_PASSWORD_ERROR, signingUp: false });
            return;
        }

        if (!EMAIL_REGEX.test(username)) {
            this.setState({ signupError: EMAIL_INVALID, signingUp: false });
            return;
        }

        const adminRef = await getNetworkNameListing(networkKey);

        let listing = adminRef.val();
        if (listing) {
            this.setState({ signupError: NETWORK_IN_USE, signingUp: false });
            return;
        }

        let credential;

        try {
            credential = await signupForFirebase(networkKey, password);
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                this.setState({ signupError: NETWORK_IN_USE, signingUp: false });
                return;
            } else if (error.code === 'auth/weak-password') {
                this.setState({ signupError: WEAK_PASSWORD_ERROR, signingUp: false });
                return;
            }
            // unknown error
            this.setState({ signupError: SIGNUP_GENERIC_ERROR, signingUp: false });
            return;
        }

        const { uid } = credential.user || {};

        if (!uid) {
            // unknown error
            this.setState({ signupError: SIGNUP_GENERIC_ERROR, signingUp: false });
            return;
        }

        await setUIDInfo(uid, networkKey, username);
        await addModeratorEntryForAdmin(networkKey, username, uid);
        await setUIDInfoOnFireStore(uid, networkKey, username);
        createNetworkNameListing(networkKey);

        await createNetwork({
            username,
            email: username,
            name: firstName,
            networkKey,
            deactivated: true,
            uid,
        }, networkKey);

        const secretKey = getMediumRandomID();
        await setNetworkSecretKey(networkKey, secretKey);

        this.setState({ signingUp: false });
        this.props.login({ username, networkKey, password, uid, name: firstName, deactivated: true });
    }

    _loginUser = async () => {
        const {
            loggingIn,
            password,
            username: uppercaseUsername,
            networkKey
        } = this.state;

        const username = uppercaseUsername.toLocaleLowerCase();

        if (loggingIn) return;

        this.setState({ loggingIn: true });

        const adminEmail = await getAdminEmailForNetworkKey(networkKey);
        const isModerator = adminEmail.val() !== username;
        let credential;
        try {
            if (isModerator) {
                credential = await loginToFirebaseForModerator(networkKey, username, password);
            } else {
                credential = await loginToFirebase(networkKey, password);
            }
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                this.setState({ loginError: INCORRECT_PASSWORD, loggingIn: false });
                return
            } else if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
                this.setState({ loginError: NETWORK_DOES_NOT_EXIST, loggingIn: false });
                return
            } else if (error.code === 'auth/too-many-requests') {
                this.setState({ loginError: 'too many login attempts. Try again later.', loggingIn: false });
                return;
            }
            this.setState({ loginError: LOGIN_GENERIC_ERROR, loggingIn: false });
            return;
        }

        const { uid } = credential.user || {};

        if (!uid) {
            this.setState({ loginError: LOGIN_GENERIC_ERROR, loggingIn: false });
            return;
        }

        const snapshot = await getAdminForNetworkKey(networkKey);
        let account = snapshot.val();
        if (!account) {
            this.setState({ loginError: NETWORK_DOES_NOT_EXIST, loggingIn: false });
            return;
        }

        setUIDInfoOnFireStore(uid, networkKey, username);

        this.props.login({ ...account, networkKey, password, uid, username, isModerator });
        this.setState({ loggingIn: false });
    }

    _recoverPassword = async () => {
        const { recoveryNetworkKey, username: uppercaseUsername } = this.state;
        const username = uppercaseUsername.toLocaleLowerCase();

        if (!recoveryNetworkKey) return;
        try {
            this.setState({ sendingRecovery: true })
            const res = await sendRecoveryEmail(recoveryNetworkKey, username);
            const { data } = res;
            if (!data || (data?.error && data?.message)) {
                this.setState({ sendingRecovery: false, recoveryError: data.message })
                return;
            }
            this.setState({ sendingRecovery: false, recoverySent: true })
        }
        catch (err) {
            this.setState({ sendingRecovery: false, recoveryError: err.message })
        }

    }

    render() {

        const {
            currentPage
        } = this.state;

        return (
            <React.Fragment>
                {currentPage === 'signup' && this._getSignupPage()}
                {currentPage === 'login' && this._getLoginPage()}
                {currentPage === 'recovery' && this._getRecoveryPage()}
            </React.Fragment>
        );
    }

    _getSignupPage() {

        const {
            username,
            password,
            passwordConfirm,
            firstName,
            signingUp,
            signupError,
            networkKey
        } = this.state;

        const buttonDisabled = !(firstName && networkKey && username && password && passwordConfirm);

        return (
            <div style={{ paddingLeft: '10%', paddingRight: '10%', marginTop: '5rem' }}>

                <div className="row">
                    {this._getSlogan()}
                    <div className="col-sm login-right-column">
                        <div className="form-signin">
                            <img className="mb-4 login-logo" src={logo} alt="" height="40" />
                            {signupError && <div style={{ color: 'red' }}>Error: {signupError}</div>}
                            <input testid={'signup-name'} className="form-control" placeholder="Your name" value={firstName} autoFocus onChange={this._onFirstNameChanged}></input>
                            <input testid={'signup-network-name'} className="form-control flat-top" pattern="[a-z]{3}" placeholder="Network name" value={networkKey} onChange={this._onNetworkKeyChanged}></input>
                            <input testid={'signup-email'} className="form-control flat-top" placeholder="Email" value={username} onChange={this._onUsernameChanged}></input>
                            <input testid={'signup-password'} type="password" className="form-control flat-top" placeholder="Password" value={password} onChange={this._onPasswordChanged}></input>
                            <input testid={'signup-confirm-password'} type="password" className="form-control flat-top" placeholder="Confirm password" value={passwordConfirm} onChange={this._onPasswordConfirmChanged}></input>
                            <button style={{ width: '100%' }} testid={'signup-button'} className="btn btn-lg btn-primary btn-block black-button" onClick={this._signUpUser} disabled={buttonDisabled}>
                                {signingUp ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>{getLoadingIndicator('#ffffff', 1)}</div>
                                    : (buttonDisabled ? 'Sign up' : <b>Sign up</b>)
                                }
                            </button>
                            <div testid={'go-to-login-button'} onClick={this.goToLogin} style={{ cursor: 'pointer', marginTop: 10, fontSize: 14 }}>Have an account already? <u>Log in.</u></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    _getRecoveryPage() {

        const { recoveryNetworkKey, sendingRecovery, recoveryError, recoverySent, username } = this.state;
        return (
            <div style={{ paddingLeft: '10%', paddingRight: '10%', marginTop: '5rem' }}>
                <div className="row">
                    {this._getSlogan()}
                    <div className="col-sm login-right-column" style={{ height: 307 }}>
                        {recoverySent ? (
                            <div className="form-signin">
                                <img className="mb-4 login-logo" src={logo} alt="" height="40" />
                                <p>A recovery email has been sent, please check your inbox and follow the prompt</p>
                                <button
                                    testid={'recovery-done'}
                                    className="btn btn-lg btn-primary btn-block black-button"
                                    onClick={this.resetRecovery}
                                    disabled={sendingRecovery}
                                    style={{ width: '100%', borderRadius: '.25rem' }}
                                >
                                    <b>Done</b>
                                </button>

                            </div>
                        ) : (
                            <div className="form-signin">
                                <img className="mb-4 login-logo" src={logo} alt="" height="40" />
                                {recoveryError && <div testid={'recovery-error'} style={{ color: 'red' }}>Error: {recoveryError}</div>}
                                <input
                                    testid={'recovery-name'}
                                    className="form-control"
                                    placeholder="Network Name"
                                    value={recoveryNetworkKey}
                                    autoFocus
                                    onChange={this._onRecoveryNetworkKeyChange}
                                />
                                <input testid={'recovery-email'} className="form-control flat-top" placeholder="Email" value={username} onChange={this._onUsernameChanged}></input>
                                <button
                                    testid={'recovery-button'}
                                    className="btn btn-lg btn-primary btn-block black-button"
                                    onClick={this._recoverPassword}
                                    disabled={sendingRecovery || !recoveryNetworkKey || !username}
                                    style={{ width: '100%' }}
                                >
                                    {sendingRecovery ? (
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {getLoadingIndicator('#ffffff', 1)}
                                        </div>
                                    ) : (
                                        <b>Send Recovery Email</b>
                                    )}
                                </button>
                                <div
                                    testid={'go-to-login-button'}
                                    onClick={this.goToLogin}
                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                >
                                    <u>Back to login.</u>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {!isMobile && <img alt="preview" src={bottomImage} style={{ width: '100%' }}></img>}
            </div>
        );
    }


    _getLoginPage() {

        const {
            loggingIn,
            networkKey,
            password,
            loginError,
            username
        } = this.state;

        const canLogIn = username && networkKey && password && !loggingIn;
        return (
            <div style={{ paddingLeft: '10%', paddingRight: '10%', marginTop: '5rem' }}>
                <div className="row">
                    {this._getSlogan()}
                    <div className="col-sm login-right-column">
                        <div className="form-signin">
                            <img className="mb-4 login-logo" src={logo} alt="" height="40" />
                            {loginError && <div style={{ color: 'red' }}>Error: {loginError}</div>}
                            <input testid={'login-network-name'} className="form-control" pattern="[A-Za-z0-9]" placeholder="Network name" value={networkKey} autoFocus onChange={this._onNetworkKeyChanged}></input>
                            <input testid={'login-email'} className="form-control flat-top" placeholder="Email" value={username} onChange={this._onUsernameChanged}></input>
                            <input testid={'login-password'} type="password" className="form-control flat-top" placeholder="Password" value={password} onKeyDown={this._detectEnterButton} onChange={this._onPasswordChanged}></input>
                            <button style={{ width: '100%' }} testid={'login-button'} className="btn btn-lg btn-primary btn-block black-button" onClick={this._loginUser} disabled={!canLogIn}>
                                {loggingIn ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>{getLoadingIndicator('#ffffff', 1)}</div>
                                    : (!canLogIn ? 'Log in' : <b>Log in</b>)
                                }
                            </button>
                            <div testid={'go-to-signup-button'} onClick={this.goToSignUp} style={{ cursor: 'pointer', fontSize: 14 }}>Don't have an account yet? <u>Sign up.</u></div>
                        </div>
                        <div className="text-center" testid={'forgot-password-button'} onClick={this.goToRecovery} style={{ cursor: 'pointer', fontSize: 12, marginTop: 15 }}>Forgot your credentials? <u>Click here</u></div>
                    </div>
                </div>
                {!isMobile && <img alt="preview" src={bottomImage} style={{ width: '100%' }}></img>}
            </div>
        );
    }

    _getSlogan() {
        return !isMobile && (
            <div className="col-sm login-left-column">
                <br></br>
                <h1 style={{ fontSize: 50 }}><b>Information flow to help you</b></h1>
                <h1 style={{ fontSize: 50 }}><b>gain insights that drive</b></h1>
                <h1 style={{ fontSize: 50 }}><b>meaningful change.</b></h1>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export const SubscribeFormContainerWithStripe = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <Login
                stripe={stripe}
                elements={elements}
                {...props}
            />
        )}
    </ElementsConsumer>
);

export default connect(mapStateToProps, { login })(SubscribeFormContainerWithStripe);