// @flow
import 'bootstrap/dist/css/bootstrap.css';
import '../../../general.scss';
import '../../../styles/protocol.scss';
import React from 'react';
import { connect } from 'react-redux';
import { fetchUsers, fetchProtocolsRealTime } from '../../../actions';
import { ProtocolCard } from './ProtocolCard';
import { ProtocolTable } from './ProtocolTable';
import { AssignProtocolForm } from './AssignProtocolForm';
import { RemoveUserModal } from '../Users/RemoveUserModal';
import ProtocolPreview from './ProtocolPreview';
import ProtocolForm from './ProtocolForm';
import Modal from '@material-ui/core/Modal';
import type { ComponentType } from 'react';
import { withRouter } from 'react-router-dom';
import { getProtocolEditorForProtocolRef, getProtocolEditorRef, getProtocolsRefWithNetworkKey, setProtocolEditor } from '../../../api/firebase';

import plusIcon from '../../../assets/svgs/plus-white.svg';
import removeIcon from '../../../assets/svgs/circle-cancel-blue.svg';

import { getLoadingIndicator, categorizeProtocols, getAllIndexes } from '../../UI Resources';
import { setProtocolsForNetwork } from '../../../actions';

import type { ContentObject, AuthObject, Protocol as ProtocolType } from '../../../flowTypes';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';

type Props = {
    fetchUsers: () => void,
    fetchProtocolsRealTime: () => void,
    setProtocolsForNetwork: (newprotocols: any[], networkKey: string, editedProtocolId: string) => void,
    content: ContentObject,
    auth: AuthObject,
};

type State = {
    selectedProtocolId: string,
    selectedProtocol: ProtocolType,
    showAssignProtocol: boolean,
    showCreateProtocolModal: boolean,
    showDeleteProtocolModal: boolean,
    showPreviewProtocolModal: boolean,
    selectedMemberId: string,
    deletedProtocolId: string,
    protcolInfo: {
        selectedProtocolLevels: any[],
        selectedProtocolId: string,
        mode: string,
        protcolName: string,
    },
    protocolEditors: {
        [protocolId: string]: string
    },
    localProtocolEditorId: string,
};

export class Protocol extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedProtocolId: '',
            selectedProtocol: {},
            showAssignProtocol: false,
            showPreviewProtocolModal: false,
            selectedMemberId: '',
            showCreateProtocolModal: false,
            deletedProtocolId: '',
            showDeleteProtocolModal: false,
            protcolInfo: {
                selectedProtocolLevels: [],
                selectedProtocolId: '',
                mode: 'add',
                protcolName: '',
            },
            protocolEditors: {},
            localProtocolEditorId: '',
        };
    }

    componentDidMount() {
        const { fetchUsers, content, auth, fetchProtocolsRealTime } = this.props;

        const { networkKey } = auth || {};
        const { currentUsers = {}, protocols = [] } = content || {};

        if (!Object.keys(currentUsers).length && fetchUsers) {
            fetchUsers();
        }

        if (!protocols.length && fetchProtocolsRealTime) {
            fetchProtocolsRealTime();
        }

        getProtocolEditorRef(networkKey).on('value', snapshot => {
            const protocolEditors = snapshot.val() || {};
            this.setState({ protocolEditors });
        })
    }

    componentWillUnmount() {
        const { auth } = this.props;

        const { networkKey } = auth || {};

        getProtocolsRefWithNetworkKey(networkKey).off('value');
    }

    changeMemberProtocolPrompt: (memberId: string) => void = (memberId) => {
        this.setState({ selectedMemberId: memberId, showAssignProtocol: true });
    };
    assignProtocolPrompt: (protocol: any) => void = (protocol) => {
        this.setState({
            selectedMemberId: '',
            selectedProtocolId: protocol.id,
            selectedProtocol: protocol,
            showAssignProtocol: true,
        });
    };

    createProtocolPrompt: () => void = () => {
        this.setState({
            protcolInfo: {
                selectedProtocolLevels: [{ habits: [], metrics: [] }],
                selectedProtocolId: '',
                mode: 'add',
                protcolName: '',
            },
            showCreateProtocolModal: true,
        });
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { auth } = this.props;
        const { username, networkKey } = auth || {};

        const { showCreateProtocolModal } = this.state;
        const protocolId = this.state?.protcolInfo?.selectedProtocolId;
        
        if ((prevState.showCreateProtocolModal !== showCreateProtocolModal) && Boolean(protocolId)) {
            setProtocolEditor(
                networkKey,
                protocolId,
                showCreateProtocolModal ? username : ''
            );

            getProtocolEditorForProtocolRef(networkKey, protocolId).onDisconnect().set(null);
            this.setState({localProtocolEditorId: showCreateProtocolModal ? protocolId: ''});
        }
    }

    editProtocolPrompt: (protocolId: string, levels: any, name: string) => void = (protocolId, levels, name) => {
        this.setState({
            protcolInfo: {
                selectedProtocolLevels: levels,
                selectedProtocolId: protocolId,
                mode: 'edit',
                protcolName: name,
            },
            showCreateProtocolModal: true,
        });
    };
    previewProtocolPrompt: (protocolId: string, levels: any, name: string) => void = (protocolId, levels, name) => {
        this.setState({
            protcolInfo: {
                selectedProtocolLevels: levels,
                selectedProtocolId: protocolId,
                mode: 'edit',
                protcolName: name,
            },
            showPreviewProtocolModal: true,
        });
    };

    deleteProtocolPrompt: (protocolId: string) => void = (protocolId) => {
        this.setState({ deletedProtocolId: protocolId, showDeleteProtocolModal: true });
    };

    duplicateProtocol: (protocolId: string, name: string) => void = (protocolId, name) => {
        const { content, setProtocolsForNetwork, auth } = this.props;
        const { protocols } = content;
        const { networkKey } = auth;
        const protocolNames = protocols.map(({ name }) => name);
        const dup = `${name} (copy)`;
        const dupName = protocolNames.includes(dup) ? `${dup} (copy)` : dup;
        const dupId = dupName.split(' ').join('-');
        const selectedProtocols = protocols
            .filter(({ id }) => id === protocolId)
            .map((protocol) => ({ ...protocol, name: dupName, id: dupId }));
        const newProtocols = selectedProtocols.concat(protocols);
        setProtocolsForNetwork(newProtocols, networkKey, dupId);
    };

    removeProtocol: () => void = () => {
        const { deletedProtocolId } = this.state;
        const { content, setProtocolsForNetwork, auth } = this.props;
        const { protocols } = content;
        const { networkKey } = auth;
        const protocolIds = protocols.map(({ id }) => id);
        const indexes = getAllIndexes(protocolIds, deletedProtocolId);
        indexes.forEach((index, i) => {
            protocols.splice(index - i, 1);
        });

        setProtocolsForNetwork(protocols, networkKey, deletedProtocolId);
        this.setState({ showDeleteProtocolModal: false, deletedProtocolId: '' });
    };
    protocolPrivacy: (protocolId: string, val: boolean) => void = (protocolId, val) => {
        const { content, setProtocolsForNetwork, auth } = this.props;
        const { protocols } = content;
        const { networkKey } = auth;
        const protocolIds = protocols.map(({ id }) => id);
        const indexes = getAllIndexes(protocolIds, protocolId);
        indexes.forEach((index, i) => {
            protocols[index] = { ...protocols[index], isAPrivateNetworkProtocol: val };
        });

        setProtocolsForNetwork(protocols, networkKey, protocolId);
    };
    updateWhiteList: (uid: string) => void = (uid) => {
        const { content, setProtocolsForNetwork, auth } = this.props;
        const { protocols } = content;
        const { networkKey } = auth;
        const { selectedProtocol, selectedProtocolId } = this.state;
        let { usersWhitelist = [] } = selectedProtocol;
        const userIndex = usersWhitelist.indexOf(uid);
        if (userIndex > -1) {
            usersWhitelist.splice(userIndex, 1);
        } else {
            usersWhitelist.push(uid);
        }
        const protocolIds = protocols.map(({ id }) => id);
        const indexes = getAllIndexes(protocolIds, selectedProtocolId);
        indexes.forEach((index, i) => {
            protocols[index] = { ...protocols[index], usersWhitelist };
        });
        this.setState({ selectedProtocol: { ...selectedProtocol, usersWhitelist } });
        setProtocolsForNetwork(protocols, networkKey, selectedProtocolId);
    };

    render(): any {
        const { content, auth } = this.props;
        const { networkKey } = auth || {};
        const { protocols = [], protocolsInitialized, currentUsers = {}, chosenProtocolsMapping = {} } = content || {};
        const {
            selectedProtocolId,
            showPreviewProtocolModal,
            showAssignProtocol,
            showCreateProtocolModal,
            showDeleteProtocolModal,
            selectedMemberId,
            protocolEditors,
            localProtocolEditorId,
        } = this.state;

        const emptyProtocol: ProtocolType = {};
        const selectedProtocol = protocols.find(p => p.id === selectedProtocolId) || emptyProtocol;
        const defaultMembers = Object.keys(currentUsers)
            .filter((key) => chosenProtocolsMapping[currentUsers[key].uid] === selectedProtocol.id)
            .map((key) => currentUsers[key].uid);
        const protocolsObject = categorizeProtocols(protocols);
        const protocolKeys = Object.keys(protocolsObject);
        const uniqueProtocols = _.uniqBy(protocols, 'name');
        return (
            <div className="manual-container">
                <button testid="create-button" onClick={() => this.createProtocolPrompt()} className="btn btn-lg app-btn-primary">
                    <img className="image-icon" alt="plus icon" src={plusIcon} />
                    Create Protocol
                </button>

                <div className="row" style={{ marginTop: '1.75rem' }}>
                    <div className="col-md-6">
                        {(protocolKeys.length || protocolsInitialized)
                            ? protocolKeys.map((protocolId) => (
                                <div key={protocolId}>
                                    <ProtocolCard
                                        makeProtocolPrivate={this.protocolPrivacy}
                                        onProtocolPreview={this.previewProtocolPrompt}
                                        onProtocolDelete={this.deleteProtocolPrompt}
                                        onProtocolDuplicate={this.duplicateProtocol}
                                        onProtocolEdit={this.editProtocolPrompt}
                                        onProtocolAssign={() => this.assignProtocolPrompt(protocolsObject[protocolId])}
                                        protocol={protocolsObject[protocolId]}
                                        isSelected={selectedProtocolId === protocolId}
                                        protocolEditor={protocolId === localProtocolEditorId ? '' : protocolEditors[protocolId]}
                                        onSelect={() => {
                                            this.setState({
                                                selectedProtocolId: protocolId,
                                                selectedProtocol: protocolsObject[protocolId],
                                            });
                                        }}
                                    />
                                </div>
                            ))
                            :
                            <div className="text-center">
                                <div className="d-inline-block">{getLoadingIndicator()}</div>
                            </div>
                        }
                    </div>
                    {(Boolean(protocolKeys.length) && !isMobile) && <div className="col-md-6">
                        <ProtocolTable
                            updateWhiteList={this.updateWhiteList}
                            isPrivateNetworkProtocol={selectedProtocol.isAPrivateNetworkProtocol}
                            changeMemberProtocol={this.changeMemberProtocolPrompt}
                            currentUsers={currentUsers}
                            selectedProtocolId={selectedProtocolId}
                            selectedProtocol={selectedProtocol}
                            chosenProtocolMapping={chosenProtocolsMapping}
                            usersWhitelist={selectedProtocol.usersWhitelist || []}
                        />
                    </div>}
                </div>
                {showAssignProtocol ? (
                    <Modal
                        testid="assign-protocol-modal"
                        open={showAssignProtocol}
                        onClose={() => this.setState({ showAssignProtocol: false })}
                        style={{ display: 'flex' }}
                    >
                        <>
                            <AssignProtocolForm
                                defaultMembers={defaultMembers}
                                chosenProtocolMapping={chosenProtocolsMapping}
                                protocols={uniqueProtocols}
                                onDone={() => this.setState({ showAssignProtocol: false })}
                                users={currentUsers}
                                selectedProtocol={selectedProtocol}
                                selectedMemberId={selectedMemberId}
                                protocolsObject={protocolsObject}
                                networkKey={networkKey}
                            />
                        </>
                    </Modal>
                ) : null}
                {showCreateProtocolModal ? (
                    <Modal
                        testid="create-protocol-modal"
                        open={showCreateProtocolModal}
                        onClose={() => this.setState({ showCreateProtocolModal: false })}
                        style={{ display: 'flex' }}
                        hideBackdrop={showPreviewProtocolModal}
                    >
                        <>
                            <ProtocolForm
                                mode={this.state.protcolInfo.mode}
                                protocolName={this.state.protcolInfo.protcolName}
                                protocolId={this.state.protcolInfo.selectedProtocolId}
                                protocolLevels={this.state.protcolInfo.selectedProtocolLevels}
                                onDone={() => this.setState({ showCreateProtocolModal: false })}
                            />
                        </>
                    </Modal>
                ) : null}
                {showDeleteProtocolModal ? (
                    <Modal
                        testid="delete-protocol-modal"
                        open={showDeleteProtocolModal}
                        onClose={() => this.setState({ showDeleteProtocolModal: false })}
                        style={{ display: 'flex' }}
                    >
                        <>
                            <RemoveUserModal
                                title="Delete Protocol"
                                description="You are about to delete a Protocol. If you do, it can’t be recovered. Are you sure?"
                                positive={false}
                                removeIcon={removeIcon}
                                onDone={() => this.setState({ showDeleteProtocolModal: false })}
                                removeUser={() => this.removeProtocol()}
                            />
                        </>
                    </Modal>
                ) : null}
                {showPreviewProtocolModal ? (
                    <Modal
                        testid="preview-protocol-modal"
                        open={showPreviewProtocolModal}
                        onClose={() => this.setState({ showPreviewProtocolModal: false })}
                        style={{ display: 'flex' }}
                    >
                        <>
                            <ProtocolPreview
                                protocolName={this.state.protcolInfo.protcolName}
                                protocolId={this.state.protcolInfo.selectedProtocolId}
                                protocolLevels={this.state.protcolInfo.selectedProtocolLevels}
                                onEditProtocol={this.editProtocolPrompt}
                                onAssignProtocol={this.assignProtocolPrompt}
                                onDone={() => this.setState({ showPreviewProtocolModal: false })}
                            />
                        </>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
export const mapStateToProps: ({ auth: any, content: any }) => any = ({ auth, content }) => {
    return { auth, content }
};

const connectedComponent: ComponentType<Props> = connect(mapStateToProps, {
    fetchProtocolsRealTime,
    fetchUsers,
    setProtocolsForNetwork,
})(withRouter(Protocol));

export default connectedComponent;
