// @flow

import 'bootstrap/dist/css/bootstrap.css';
import '../../../custom.scss';
import { connect } from 'react-redux';
import { login, setNetworkDeactivated, setProtocols, fetchSecretKey, setNewSecretKey, fetchModerators, removeModerator, addModerator } from '../../../actions';
import { addModeratorForNetworkKey, setProtocolsWithNetworkKey } from '../../../api/firebase';
import { INCORRECT_ACTIVATION_CODE } from '../../../Constants';
import { OverlayTrigger, Popover } from 'react-bootstrap';


import { activateNetworkWithCode } from '../../../api/axios';
import React from 'react';

import { ElementsConsumer } from '@stripe/react-stripe-js';
import { ResetKeyModal } from './ResetKeyModal';
import { ActivateNetworkByCodeForm } from './ActivateNetworkByCodeForm';
import type { ComponentType } from 'react';
import type { AuthObject, ContentObject, ModeratorMap } from '../../../flowTypes';

import removeUserIcon from '../../../assets/svgs/user-red.svg';
import questionIcon from '../../../assets/svgs/question.svg';
import infoIcon from '../../../assets/svgs/info.svg';
import ReplacePassword from './ReplacePassword';
import _ from 'lodash';
import checkIcon from '../../../assets/svgs/check-white.svg';
import keyIcon from '../../../assets/svgs/key.svg';
import lockIcon from '../../../assets/svgs/lock-white.svg';
import Modal from '@material-ui/core/Modal';
import { getLoadingIndicator } from '../../UI Resources';


type Props = {
    auth: AuthObject,
    content: ContentObject,
    setProtocols: (protocols: any[], locallyChanged: any[], networkKey: string) => void,
    setProtocolsWithNetworkKey: (networkKey: string, protocols: any) => void,
    setNetworkDeactivated: (deactivated: boolean) => void,
    stripe: any,
    elements: any,
    fetchSecretKey: () => void,
    setNewSecretKey: () => void,
    fetchModerators: () => void,
    addModerator: (uid: string, email: string, currentModerators: ModeratorMap) => void,
    removeModerator: (uid: string, currentModerators: ModeratorMap) => void,
};

type State = {
    activating: boolean,
    activatingByCode: boolean,
    activationError: string,
    activationByCodeError: string,
    cardInformationIsComplete: boolean,
    activationCode: string,
    onCardActivationPage: boolean,
    showWarningForResetSecretKey: boolean,
    showResetModal: boolean,
    showActivateCodeModal: boolean,
    showActivateCardModal: boolean,
    showChangePasswordModal: boolean,
    activationSuccess: boolean,
    potentialModeratorEmail: string,
    addingModerator: boolean,
};

const getPopover = ({ title, description }: { title: string, description: string }): any => {
    return (
        <Popover>
            <Popover.Body className="tooltip-body">
                <div className="tooltip-head">
                    <img className="image-icon" src={infoIcon} alt="info icon" />
                    <p className="tooltip-title">{title}</p>
                </div>
                <p className="tooltip-description">{description}</p>
            </Popover.Body>
        </Popover>
    );
};

export class Activate extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            activating: false,
            activationError: '',
            activationSuccess: false,
            activationByCodeError: '',
            cardInformationIsComplete: false,
            activationCode: '',
            onCardActivationPage: true,
            activatingByCode: false,
            showWarningForResetSecretKey: false,
            showResetModal: false,
            showActivateCardModal: false,
            showActivateCodeModal: false,
            showChangePasswordModal: false,
            potentialModeratorEmail: '',
            addingModerator: false,
        };
    }

    componentDidMount() {
        const { fetchSecretKey = _.noop, fetchModerators = _.noop, auth } = this.props;
        const { moderatorsInitialized } = auth || {};

        fetchSecretKey();

        if (!moderatorsInitialized) {
            fetchModerators();
        }
    }

    addModerator: (email: string) => Promise<any> = async (email) => {
        const { auth, addModerator = _.noop } = this.props;
        const { moderators = {}, networkKey } = auth || {};

        this.setState({ addingModerator: true });

        const result = (await addModeratorForNetworkKey(networkKey, email)).data;

        if (result?.error) {
            this.setState({ addingModerator: false });
            return;
        }

        addModerator(result.uid, email, moderators);
        this.setState({ potentialModeratorEmail: '', addingModerator: false });
    }

    activateUserByCode: (code: string) => Promise<any> = async (code: string) => {

        const { auth, content } = this.props;
        const {
            protocols = []
        } = content || {};

        const {
            networkKey,
        } = auth;


        let activationInformation;
        try {
            activationInformation = await activateNetworkWithCode(networkKey, code);
        } catch (e) {
            return { error: INCORRECT_ACTIVATION_CODE, message: INCORRECT_ACTIVATION_CODE }
        }

        const {
            success
        } = activationInformation.data || {};

        if (!success) {
            return { error: INCORRECT_ACTIVATION_CODE, message: INCORRECT_ACTIVATION_CODE }
        }

        this.props.setNetworkDeactivated(false);
        for (let protocol of protocols) {
            protocol.isADeactivatedNetworkProtocol = false;
        }
        setProtocolsWithNetworkKey(networkKey, protocols);
        this.props.setProtocols(protocols, protocols, networkKey);
    }

    render(): any {

        const { auth, setNewSecretKey } = this.props;
        const { deactivated, networkKey, secretKey, isModerator } = auth || {};
        const { showChangePasswordModal, showResetModal, showActivateCodeModal } = this.state;
        return (
            <div className="manual-container">
                <div className="mx-auto d-flex flex-column align-items-center">
                    {!isModerator && <><p className={`account-status ${deactivated ? "inactive" : "active"} no-margin-bottom`}><b>{deactivated ? "Your account is inactive." : "Your account is active."}</b></p>
                        <p className={`account-status description no-margin-bottom ${deactivated ? "inactive" : "active"}`}>{deactivated ? "Members can't use network protocols until you activate your account." : "Members can use network protocols as long as your account is active."}</p>
                        {!deactivated && <p className={`account-status description active`}>To deactivate, remove all members from your account.</p>}
                        {
                            deactivated ?
                                (
                                    <div className="d-flex flex-column my-1">
                                        {/*<button className="btn btn-lg  app-btn-success" onClick={() => this.setState({ showActivateCardModal: true })}>
                                        <img className="image-icon" alt="check icon" src={checkIcon} />
                                        Activate with Card </button>
                                    <span className="align-self-center">or</span>*/}
                                        <button testid='activate-button' className="btn btn-lg  app-btn-success" onClick={() => this.setState({ showActivateCodeModal: true })}>
                                            <img className="image-icon" alt="check icon" src={checkIcon} />
                                            Activate with Code </button>
                                    </div>
                                ) :
                                null
                        }</>}
                    <div>
                        <h4 style={{ paddingLeft: '.5rem' }} className='no-margin-bottom mt-5 mb-1'><b>Network Details</b></h4>
                        <div className="table-responsive no-margin-top">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="">Network Name</th>
                                        <th scope="col" className="text-center">
                                            {'Secret Key'}
                                            <OverlayTrigger
                                                overlay={getPopover({
                                                    title: 'Secret Key',
                                                    description: "Users can join your network by providing the network key and this secret key. Make sure to only share the secret key with those you want in your network.",
                                                })}
                                                testid="activity-tooltip"
                                            >
                                                <div className="table-head-icon" style={{ marginLeft: 5, marginBottom: 2 }}>
                                                    <img
                                                        className="extra-small-icon icon-left"
                                                        src={questionIcon}
                                                        alt="tooltip icon"
                                                        style={{ width: 12, marginRight: 0 }}
                                                    />
                                                </div>
                                            </OverlayTrigger>
                                        </th>
                                        <th scope="col">
                                        </th>
                                        <th scope="col" className="text-center">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr testid="scorecard-row">
                                        <td className="">
                                            <p className='no-margin-bottom' style={{ paddingLeft: '.5rem' }}>{networkKey}</p>
                                        </td>
                                        <td className="text-center">
                                            {secretKey}
                                        </td>
                                        <td className="text-center">
                                            {!isModerator && <button testid='reset-secret-key-button' className="btn btn-lg app-btn-primary" onClick={() => this.setState({ showResetModal: true })}>
                                                <img className="image-icon" alt="key icon" src={keyIcon} />
                                                Reset Secret Key
                                            </button>}
                                        </td>
                                        <td className="text-center">
                                            <button testid='change-password-button' className="btn btn-lg app-btn-success" onClick={() => this.setState({ showChangePasswordModal: true })}>
                                                <img className="image-icon" alt="lock icon" src={lockIcon} />
                                                Change Login Password
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {!isModerator &&
                            <> <h4 style={{ paddingLeft: '.5rem' }} className='no-margin-bottom mt-5 mb-1'><b>Moderators</b></h4>
                                {this.getMods()}
                            </>}
                    </div>
                </div>
                {/* <div className="card-columns" style={{ columnCount: '1' }}>
                    <div className={deactivated ? 'row' : ''} style={{ justifyContent: 'center' }}>
                        <ReplacePassword />
                        {deactivated ? this._getActivationPage() : this._getAuthenticatedPage()}
                    </div>
                    {this._getSecretKeySection()}
                </div> */}
                {
                    showChangePasswordModal ?
                        (
                            <Modal
                                id="change-password-modal"
                                open={showChangePasswordModal}
                                onClose={() => this.setState({ showChangePasswordModal: false })}
                                style={{ display: 'flex' }}
                            >
                                <><ReplacePassword onDone={() => this.setState({ showChangePasswordModal: false })} /></>
                            </Modal>
                        ) :
                        null
                }
                {
                    showResetModal ?
                        (
                            <Modal
                                id="change-password-modal"
                                open={showResetModal}
                                onClose={() => this.setState({ showResetModal: false })}
                                style={{ display: 'flex' }}
                            >
                                <><ResetKeyModal resetKey={setNewSecretKey} onDone={() => this.setState({ showResetModal: false })} /></>
                            </Modal>
                        ) :
                        null
                }
                {
                    showActivateCodeModal ?
                        (
                            <Modal
                                id="change-password-modal"
                                open={showActivateCodeModal}
                                onClose={() => this.setState({ showActivateCodeModal: false })}
                                style={{ display: 'flex' }}
                            >
                                <><ActivateNetworkByCodeForm activateNetwork={this.activateUserByCode} onDone={() => this.setState({ showActivateCodeModal: false })} /></>
                            </Modal>
                        ) :
                        null
                }
                {
                    /* showActivateCardModal ?
                        (
                            <Modal
                                id="change-password-modal"
                                open={showActivateCardModal}
                                onClose={() => this.setState({ showActivateCardModal: false })}
                                style={{ display: 'flex' }}
                            >
                                {this.getActivationByCardPage()}
                            </Modal>
                        ) :
                        null */
                }
            </div >
        );
    };


    getMods(): any {

        const { auth, removeModerator } = this.props;
        const { moderators = {} } = auth || {};
        const { potentialModeratorEmail, addingModerator } = this.state;

        return (
            <div className="table-responsive no-margin-top">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="">
                                {'Moderator Email'}
                                <OverlayTrigger
                                    overlay={getPopover({
                                        title: 'Add Moderators',
                                        description: "You can invite as many moderators as you want to your network. They can do everything you can, except view/edit other moderators or modify the secret key.",
                                    })}
                                    testid="activity-tooltip"
                                >
                                    <div className="table-head-icon" style={{ marginLeft: 5, marginBottom: 2 }}>
                                        <img
                                            className="extra-small-icon icon-left"
                                            src={questionIcon}
                                            alt="tooltip icon"
                                            style={{ width: 12, marginRight: 0 }}
                                        />
                                    </div>
                                </OverlayTrigger>
                            </th>
                            <th scope="col" className="" />
                            <th scope="col" />
                            <th scope="col" className="text-center">
                                {'Actions'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr testid="scorecard-row">
                            <td className="">
                                <input
                                    placeholder="email of the moderator to add..."
                                    testid="email-input"
                                    className="form-control"
                                    value={potentialModeratorEmail}
                                    onChange={(e) => this.setState({ potentialModeratorEmail: e.target.value })}
                                    style={{ color: potentialModeratorEmail ? 'black' : '#94A3B8', fontSize: '14px', paddingLeft: '.5rem' }}
                                />
                            </td>
                            <td className="text-center" />
                            <td className="text-center" />
                            <td className="text-center">
                                <button style={{ width: 140 }} testid='add-moderator-button' disabled={!potentialModeratorEmail} className="btn btn-lg app-btn-success" onClick={() => this.addModerator(potentialModeratorEmail)}>
                                    {addingModerator ? (
                                        getLoadingIndicator()
                                    ) : (
                                        <>
                                            {'Add Moderator'}
                                        </>
                                    )}
                                </button>
                            </td>
                        </tr>
                        {Object.keys(moderators).sort((a, b) => moderators[b].createdTime - moderators[a].createdTime).filter(key => !moderators[key].isAdmin).map((key, index) => (
                            <tr testid="scorecard-row" key={`scorecard-row-${key}`} >
                                <td className="">
                                    <p style={{ paddingLeft: '.5rem' }}>{moderators[key].email}</p>
                                </td>
                                <td className="text-center" />
                                <td className="text-center" />
                                <td className="text-center">
                                    <button style={{ width: 140 }} testid={`remove-mod-button-${index}`} className="btn app-btn-outline-danger" onClick={() => {
                                        removeModerator(key, moderators);
                                    }}>
                                        <img src={removeUserIcon} className="image-icon button-icon" alt="remove icon" />
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        content,
        auth
    } = state;

    return {
        content,
        auth
    };
}

export const SubscribeFormContainerWithStripe: ComponentType<Props> = (props: any) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <Activate
                stripe={stripe}
                elements={elements}
                {...props}
            />
        )}
    </ElementsConsumer>
);

const connectedComponent: ComponentType<Props> = connect(mapStateToProps, { login, setNetworkDeactivated, setProtocols, fetchSecretKey, setNewSecretKey, fetchModerators, removeModerator, addModerator })(SubscribeFormContainerWithStripe);
export default connectedComponent;