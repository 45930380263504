// @flow
import 'bootstrap/dist/css/bootstrap.css';
import '../../../general.scss';
import 'react-circular-progressbar/dist/styles.css';
import React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { fetchMetricLogsForYear, fetchHabitLogsForYear, fetchUsers } from '../../../actions';
import { getLoadingIndicator, getStreakEmoji, matchFilter, periodToPriorAndCurentTimestamp, } from '../../UI Resources';
import Modal from '@material-ui/core/Modal';
import _ from 'lodash';
import {
    ACTIVITY_TOOL_TIP,
    ADHERENCE_TOOLTIP,
    STREAK_TOOLTIP,
    DAYS_ON_TOOLTIP,
    DISPOSITON_TOOLTIP,
    METRIC_SCORE_MAPPING
} from '../../../Constants';
import broadcastIcon from '../../../assets/svgs/broadcast.svg';
import exportIcon from '../../../assets/svgs/export.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { NotificationForm } from '../Users/NotificationForm';
import { BroadcastForm } from '../Users/BroadcastForm';
import searchIcon from '../../../assets/svgs/search.svg';
import caretDownIcon from '../../../assets/svgs/caret-down.svg';
import caretUpIcon from '../../../assets/svgs/caret-up.svg';
import questionIcon from '../../../assets/svgs/question.svg';
import filterIcon from '../../../assets/svgs/filter.svg';
import infoIcon from '../../../assets/svgs/info.svg';
import bellIcon from '../../../assets/svgs/bell.svg';
import arrowUp from '../../../assets/svgs/up-arrow.svg';
import arrowDown from '../../../assets/svgs/down-arrow.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


import type { AuthObject, MetricScoreInCollection, HabitScoreInCollection, CurrentUser, CurrentUsersObject } from '../../../flowTypes';
import type { ComponentType } from 'react';
import { Dropdown, ProgressBar } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';


type Props = {
    location: any,
    auth: AuthObject,
    metricLogs: Array<MetricScoreInCollection>,
    habitLogs: Array<HabitScoreInCollection>,
    path: any,
    fetchMetricLogsForYear: (networkKey: string) => void,
    fetchHabitLogsForYear: (networkKey: string) => void,
    fetchUsers: () => void,
    chosenProtocolsMapping: { [key: string]: string },
    showNotificationModal: boolean,
    currentUsers: CurrentUsersObject
};
type sortOrder = '' | 'asc' | 'desc';
type Period = { number: number, period: string };
type State = {
    protocolSortOrder: sortOrder,
    daysOnSortOrder: sortOrder,
    activitySortOrder: sortOrder,
    streakSortOrder: sortOrder,
    dispositionSortOrder: sortOrder,
    selectedUserId: string,
    adherenceSortOrder: sortOrder,
    currentPeriod: Period,
    dispositionFilter: string,
    numericDispositionFilter: boolean,
    selectedUser: CurrentUser,
    adherenceFilter: string,
    showNotificationModal: boolean,
    showBroadcastModal: boolean,
    searchString: string,
    potentialSearchString: string,
};

export class ScoreCard extends React.Component<Props, State> {
    dateFilter: Period[] = [
        { number: 1, period: 'y' },
        { number: 6, period: 'M' },
        { number: 3, period: 'M' },
        { number: 1, period: 'M' },
        { number: 2, period: 'w' },
        { number: 1, period: 'w' },
    ]
    constructor(props: Props) {
        super(props);
        this.state = {
            daysOnSortOrder: '',
            protocolSortOrder: '',
            activitySortOrder: '',
            streakSortOrder: '',
            dispositionSortOrder: '',
            adherenceSortOrder: '',
            currentPeriod: { number: 2, period: 'w' },
            selectedUser: {},
            selectedUserId: '',
            dispositionFilter: '',
            numericDispositionFilter: false,
            adherenceFilter: '',
            showNotificationModal: false,
            searchString: '',
            potentialSearchString: '',
            showBroadcastModal: false
        };
    }

    componentDidMount() {
        const { metricLogs = [], habitLogs = [], auth = {}, fetchMetricLogsForYear, fetchHabitLogsForYear, currentUsers = {}, fetchUsers } = this.props;
        const { networkKey } = auth;
        if (!metricLogs?.length) {
            fetchMetricLogsForYear(networkKey);
        }
        if (!habitLogs?.length) {
            fetchHabitLogsForYear(networkKey);
        }
        if (!Object.keys(currentUsers).length) {
            fetchUsers();
        }

    }

    _getPopover({ title, description }: { title: string, description: string }): any {
        return (
            <Popover>
                <Popover.Body className="tooltip-body">
                    <div className="tooltip-head">
                        <img className="image-icon" src={infoIcon} alt="info icon" />
                        <p className="tooltip-title">{title}</p>
                    </div>
                    <p className="tooltip-description">{description}</p>
                </Popover.Body>
            </Popover>
        );
    }

    _getUserScoreCard(): any {
        const { currentPeriod, numericDispositionFilter, dispositionFilter, adherenceFilter, searchString } = this.state;
        const { metricLogs = [], habitLogs = [], chosenProtocolsMapping = {}, currentUsers = {} } = this.props;
        const { current, prior } = periodToPriorAndCurentTimestamp(currentPeriod);

        const currentUnfilteredMetricLogs = metricLogs.filter(log => matchFilter(log, currentUsers, current.start, current.end));
        const priorUnfilteredMetricLogs = metricLogs.filter(log => matchFilter(log, currentUsers, prior.start, prior.end));
        const currentUnfilteredHabitLogs = habitLogs.filter(log => matchFilter(log, currentUsers, current.start, current.end));

        const currentMetricLogs = dispositionFilter ? currentUnfilteredMetricLogs.filter(log => log.metricName === dispositionFilter) : currentUnfilteredMetricLogs;
        const priorMetricLogs = dispositionFilter ? priorUnfilteredMetricLogs.filter(log => log.metricName === dispositionFilter) : priorUnfilteredMetricLogs;
        const currentHabitLogs = adherenceFilter ? currentUnfilteredHabitLogs.filter(log => log.habitName === adherenceFilter) : currentUnfilteredHabitLogs;

        const metricNames = _.uniqBy(currentUnfilteredMetricLogs, 'metricName').map(log => ({ metricName: log.metricName, displayName: log.displayName, isNumericMetric: log.isNumericMetric }));
        const habitNames = _.uniqBy(currentUnfilteredHabitLogs, 'habitName').map(log => ({ habitName: log.habitName, displayName: log.displayName }));

        const metricLogsByUser: { [uid: string]: MetricScoreInCollection[] } = {};
        for (const log of currentMetricLogs) {
            metricLogsByUser[log.uid] = [...(metricLogsByUser[log.uid] || []), log];
        }

        const priorMetricLogsByUser: { [uid: string]: MetricScoreInCollection[] } = {};
        for (const log of priorMetricLogs) {
            priorMetricLogsByUser[log.uid] = [...(priorMetricLogsByUser[log.uid] || []), log];
        }

        const habitLogsByUser: { [uid: string]: HabitScoreInCollection[] } = {};
        for (const log of currentHabitLogs) {
            habitLogsByUser[log.uid] = [...(habitLogsByUser[log.uid] || []), log];
        }

        let usersList = Object.keys(currentUsers).map(uid => {

            const multiplier = numericDispositionFilter ? 1 : 20;
            const currentMetricLogsByThisUser = (metricLogsByUser[uid] || []);
            const priorMetricLogsByThisUser = (priorMetricLogsByUser[uid] || []);
            const currentScore = currentMetricLogsByThisUser.length ? _.mean(currentMetricLogsByThisUser.filter(log => Boolean(log.isNumericMetric) === Boolean(numericDispositionFilter)).map(log => log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore])) : 0;
            const priorScore = priorMetricLogsByThisUser.length ? _.mean(priorMetricLogsByThisUser.filter(log => Boolean(log.isNumericMetric) === Boolean(numericDispositionFilter)).map(log => log.numericScore ?? METRIC_SCORE_MAPPING[log.metricScore])) : 0;

            const disposition = currentMetricLogsByThisUser.length ? (currentScore * multiplier) : 0;
            const change = priorScore
                ? ((currentScore - priorScore) * multiplier)
                : disposition;
            const adherences = (habitLogsByUser[uid] || []).filter(log => log.habitScore).length;
            const actions = (habitLogsByUser[uid] || []).length;
            return {
                chosenProtocolId: chosenProtocolsMapping[uid],
                daysOn: currentMetricLogsByThisUser,
                dispositionValue: { disposition: disposition.toFixed(2), change },
                adherenceValue: { adherences, actions },
                streak: currentUsers[uid].profile?.streak ?? 0,
                uid,
            };
        });

        usersList = usersList.filter(item => !searchString || item.chosenProtocolId.includes(searchString) || item.uid.includes(searchString));
        usersList.sort((a, b) => a.uid.localeCompare(b.uid));
        return {
            usersList: this._applySorts(usersList), habitNames, metricNames
        };

    }

    _getCSVData(): string[][] {
        const { usersList } = this._getUserScoreCard();
        const header = ['User Id', 'Protocol', "Days on", 'Disposition', 'Adherence', 'Activity', 'Streak']
        const csvArray = usersList.map(({ chosenProtocolId, dispositionValue, adherenceValue, uid, daysOn, streak }) => {
            const adherence = adherenceValue.actions ? adherenceValue.adherences / adherenceValue.actions : 0;
            return [
                uid,
                chosenProtocolId,
                _.uniqBy(daysOn, 'date').length,
                dispositionValue.disposition,
                ((adherence) * 100).toFixed(2),
                adherenceValue.actions,
                streak
            ]
        })

        return [header].concat(csvArray)

    }

    _toggleSort: any = (sortOrder) => {
        let newSortValue = '';
        switch (this.state[sortOrder]) {
            case "asc":
                newSortValue = "desc";
                break;
            case "desc":
                newSortValue = "";
                break;
            default:
                newSortValue = "asc"
        }
        this.setState({ [sortOrder]: newSortValue })
    }
    _applySorts(usersList: any[]): any {
        const { streakSortOrder, daysOnSortOrder, adherenceSortOrder, activitySortOrder, dispositionSortOrder, protocolSortOrder } = this.state;
        const sortFunctions = [];
        const sortDirections = [];
        if (protocolSortOrder) {
            sortFunctions.push((user) => user.chosenProtocolId);
            sortDirections.push(protocolSortOrder);
        };
        if (daysOnSortOrder) {
            sortFunctions.push((user) => user.daysOn);
            sortDirections.push(daysOnSortOrder);
        }
        if (dispositionSortOrder) {
            sortFunctions.push((user) => user.dispositionValue.disposition);
            sortDirections.push(dispositionSortOrder);
        }
        if (adherenceSortOrder) {
            sortFunctions.push((user) => user.adherenceValue.actions ? user.adherenceValue.adherences / user.adherenceValue.actions : 0);
            sortDirections.push(adherenceSortOrder);
        }
        if (activitySortOrder) {
            sortFunctions.push((user) => user.adherenceValue.actions);
            sortDirections.push(activitySortOrder);
        }
        if (streakSortOrder) {
            sortFunctions.push((user) => user.streak);
            sortDirections.push(streakSortOrder);
        }
        if (protocolSortOrder || daysOnSortOrder || dispositionSortOrder || adherenceSortOrder || activitySortOrder || streakSortOrder) {
            usersList = _.orderBy(usersList, sortFunctions, sortDirections)

        }

        return usersList;
    }

    _notificationPrompt(userId: string): any {
        const { currentUsers } = this.props
        const user = currentUsers[userId];
        this.setState({ selectedUser: user, selectedUserId: userId, showNotificationModal: true });
    }

    _renderUserList(usersList: any[]): any {

        const { metricLogs, habitLogs } = this.props;
        const { numericDispositionFilter } = this.state;

        if (!metricLogs?.length || !habitLogs?.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        <div className="mx-auto">
                            {getLoadingIndicator()}
                        </div>
                    </td>
                </tr>
            )
        }

        if (!usersList.length) {
            return (
                <tr>
                    <td testid="no-data-found" className="text-center" colSpan="8">
                        No data found
                    </td>
                </tr>
            )
        }
        return usersList.map(({ uid, daysOn, chosenProtocolId, dispositionValue, adherenceValue, streak }, index) => (
            <tr testid="scorecard-row" key={`user-${index}`}>
                {!isMobile && <td className="text-center">
                    <button onClick={() => this._notificationPrompt(uid)} testid={`ping-button-${index}`} className="btn app-btn-secondary">
                        <img className="image-icon button-icon" src={bellIcon} alt="bell icon" />
                        Ping
                    </button>
                </td>}
                <td className="pl-4 scorecard-uid text-center">{_.take(uid, 6)}</td>
                {!isMobile && <td className="pl-4 text-center">{_.truncate(chosenProtocolId || "", { length: 15 })}</td>}
                {!isMobile && <td className="pl-4 bold-text text-center">{`${_.uniqBy(daysOn, 'date').length} days`}</td>}
                <td className="pl-4 bold-text text-center">
                    <div className={`disposition-container ${Math.sign(dispositionValue.change) === 1 ? 'positive' : 'negative'}`}>
                        {!numericDispositionFilter && <div style={{ width: 24, height: 24 }}>
                            <CircularProgressbar styles={buildStyles({
                                pathColor: `${Math.sign(dispositionValue.change) === 1 ? '#173EAD' : '#8EA7EC'}`
                            })} strokeWidth={15} value={dispositionValue.disposition} />
                        </div>}
                        <div className={`disposition-value ${Math.sign(dispositionValue.change) === 1 ? 'positive' : 'negative'}`}>
                            {`${dispositionValue.disposition}${numericDispositionFilter ? '' : '%'}`}
                        </div>
                        <img className="image-icon" src={Math.sign(dispositionValue.change) === 1 ? arrowUp : arrowDown} alt="up or down icon" />
                        <div className={`disposition-value ${Math.sign(dispositionValue.change) === 1 ? 'positive' : 'negative'}`}>
                            {numericDispositionFilter ? `${dispositionValue.change.toFixed(2)}` : `${parseInt(dispositionValue.change)}%`}
                        </div>
                    </div>
                </td>
                {!isMobile && <><td className="text-center">
                    <div className="row activity-container justify-content-center">
                        <div className="col-3 p-0 pr-1 justify-content-center" style={{ marginRight: 30 }}>
                            <ProgressBar style={{ width: 60 }} className="progress" now={adherenceValue.actions ? (adherenceValue.adherences / adherenceValue.actions) * 100 : 0} />
                        </div>
                        <div className="col-3 align-items-center justify-content-center">
                            <span className="activity-value">{`${((_.isFinite((adherenceValue.adherences / adherenceValue.actions)) ? (adherenceValue.adherences / adherenceValue.actions) : 0) * 100).toFixed(0)}%`}</span>
                        </div>
                    </div>
                </td>
                    <td className="text-center bold-text">
                        {adherenceValue.actions}
                    </td>
                    <td className="text-center bold-text">
                        {
                            streak ?
                                <div className="streak-container">
                                    {`${getStreakEmoji(streak)} ${streak}`}
                                </div> :
                                ""
                        }
                    </td>
                </>}
            </tr>
        ))

    }


    render(): any {
        const {
            daysOnSortOrder,
            protocolSortOrder,
            activitySortOrder,
            streakSortOrder,
            currentPeriod,
            dispositionFilter,
            adherenceFilter,
            dispositionSortOrder,
            adherenceSortOrder,
            showNotificationModal,
            selectedUser,
            selectedUserId,
            showBroadcastModal,
            potentialSearchString,
        } = this.state;
        const { auth = {}, currentUsers } = this.props;
        const { usersList, habitNames, metricNames } = this._getUserScoreCard();
        const { networkKey = "" } = auth;

        return (
            <div className="manual-container">
                <div className="d-flex justify-content-between">
                    {!isMobile && <button testid="broadcast-button" className="btn app-btn-primary btn-lg" onClick={() => this.setState({ showBroadcastModal: true })}>
                        <img className="image-icon button-icon" src={broadcastIcon} alt="broadcast icon" />
                        Broadcast
                    </button>}
                    <div className="d-flex align-items-center">
                        <div className="period-filter-container">
                            {this.dateFilter.map(({ number, period }, index) => (
                                <div
                                    testid={`period-filter-${index}`}
                                    key={`period-filter-${index}`}
                                    className={`period-filter ${number === currentPeriod.number && period === currentPeriod.period
                                        ? 'active'
                                        : ''
                                        }`}
                                    onClick={() => this.setState({ currentPeriod: { number, period } })}
                                >
                                    {`${number}${period.toUpperCase()}`}
                                </div>
                            ))}
                        </div>
                        {!isMobile && <CSVLink testid="download-csv" className="btn app-btn-outline btn-lg" data={this._getCSVData()}>
                            <img className="image-icon button-icon" src={exportIcon} alt="export icon" />
                            Export
                        </CSVLink>}
                    </div>
                </div>
                <div className="table-responsive" style={{ minWidth: 900, overflow: 'visible' }}>
                    <table className="table">
                        <thead>
                            <tr>
                                {!isMobile && <th scope="col" className="text-center">
                                    <div className="search-uid">
                                        <img className="image-icon " src={searchIcon} alt="search icon" />
                                        <input
                                            testid="search-user"
                                            onChange={(e) => this.setState({ potentialSearchString: e.target.value })}
                                            onBlur={() => this.setState({ searchString: potentialSearchString })}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    this.setState({ searchString: potentialSearchString });
                                                }
                                            }}
                                            type="text"
                                            placeholder="Search"
                                            className="form-input"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </div>
                                </th>}
                                <th scope="col" className="text-center">User ID</th>
                                {!isMobile && <>
                                    <th scope="col" className="text-center">
                                        Protocol
                                        <div onClick={() => this._toggleSort('protocolSortOrder')} testid="protocol-sort" className={`sort-container ${protocolSortOrder}`}>
                                            <img className="asc" src={caretUpIcon} alt="up arrow" />
                                            <img className="desc" src={caretDownIcon} alt="down arrow" />
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center">
                                        <OverlayTrigger
                                            overlay={this._getPopover({ title: 'Days On', description: DAYS_ON_TOOLTIP })}
                                            testid="daysOn-tooltip"
                                        >
                                            <div className="table-head-icon">
                                                <img
                                                    className="extra-small-icon icon-left"
                                                    src={questionIcon}
                                                    alt="tooltip icon"
                                                />
                                            </div>
                                        </OverlayTrigger>
                                        Days on
                                        <div testid="daysOn-sort" onClick={() => this._toggleSort('daysOnSortOrder')} className={`sort-container ${daysOnSortOrder}`}>
                                            <img className="asc" src={caretUpIcon} alt="up arrow" />
                                            <img className="desc" src={caretDownIcon} alt="down arrow" />
                                        </div>
                                    </th>
                                </>}
                                <th scope="col" className="text-center">
                                    <OverlayTrigger
                                        overlay={this._getPopover({
                                            title: 'Disposition',
                                            description: DISPOSITON_TOOLTIP,
                                        })}
                                        testid="disposition-tooltip"
                                    >
                                        <div className="table-head-icon">
                                            <img
                                                className="extra-small-icon icon-left"
                                                src={questionIcon}
                                                alt="tooltip icon"
                                            />
                                        </div>
                                    </OverlayTrigger>
                                    {metricNames.find(m => m.metricName === dispositionFilter)?.displayName || "Disposition"}
                                    <Dropdown drop="right" className="dropdown filter-dropdown">
                                        <Dropdown.Menu>
                                            <Dropdown.Header testid="disposition-sort" className="dropdown-head" onClick={() => this.setState({ dispositionSortOrder: "" })} active={dispositionSortOrder === ""}>
                                                <span>Sort</span>
                                                <div
                                                    className={`sort-container ${daysOnSortOrder}`}
                                                >
                                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                                </div>
                                            </Dropdown.Header>
                                            <Dropdown.Item testid={'disposition-sort-asc'} onClick={() => this.setState({ dispositionSortOrder: "asc" })} active={dispositionSortOrder === "asc"}>Ascending Value</Dropdown.Item>
                                            <Dropdown.Item testid="disposition-sort-desc" onClick={() => this.setState({ dispositionSortOrder: "desc" })} active={dispositionSortOrder === "desc"}>Descending Value</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Header className="dropdown-head">
                                                <span>Filter</span>
                                                <img className="extra-small-icon asc" src={filterIcon} alt="up arrow" />
                                            </Dropdown.Header>
                                            <Dropdown.Item
                                                testid="all-disposition"
                                                active={dispositionFilter === ""}
                                                onClick={() => this.setState({ dispositionFilter: "", numericDispositionFilter: false })}
                                            >
                                                All Slider Qualities
                                            </Dropdown.Item>
                                            {metricNames.filter(log => !log.isNumericMetric).sort((a, b) => a.metricName.localeCompare(b.metricName)).map(({ metricName, displayName }, i) => (
                                                <Dropdown.Item key={`quality-item-${i}`} testid={`disposition-filter-${i}`} active={dispositionFilter === metricName} onClick={() => this.setState({ dispositionFilter: metricName, numericDispositionFilter: false })}>{displayName}</Dropdown.Item>
                                            ))}
                                            {Boolean(metricNames.sort((a, b) => a.metricName.localeCompare(b.metricName)).filter(log => log.isNumericMetric).length) && <>
                                                <Dropdown.Header className="dropdown-head">
                                                    <span>Numeric Qualities</span>
                                                </Dropdown.Header>
                                                {metricNames.filter(log => log.isNumericMetric).map(({ metricName, displayName }, i) => (
                                                    <Dropdown.Item key={`quality-item-${i}`} testid={`numeric-disposition-filter-${i}`} active={dispositionFilter === metricName} onClick={() => this.setState({ dispositionFilter: metricName, numericDispositionFilter: true })}>{displayName}</Dropdown.Item>
                                                ))}
                                            </>}
                                        </Dropdown.Menu>
                                        <div testid="disposition-dropdown" className="table-head-icon margin-left">
                                            <Dropdown.Toggle>
                                                <img className="extra-small-icon asc" src={filterIcon} alt="up arrow" />
                                            </Dropdown.Toggle>
                                        </div>
                                    </Dropdown>
                                </th>

                                {!isMobile && <> <th scope="col" className="text-center">
                                    <OverlayTrigger
                                        overlay={this._getPopover({
                                            title: 'Adherence',
                                            description: ADHERENCE_TOOLTIP,
                                        })}
                                        testid="adherence-tooltip"
                                    >
                                        <div className="table-head-icon">
                                            <img
                                                className="extra-small-icon icon-left"
                                                src={questionIcon}
                                                alt="tooltip icon"
                                            />
                                        </div>
                                    </OverlayTrigger>
                                    {adherenceFilter || "Adherence"}
                                    <Dropdown drop="right" className="dropdown filter-dropdown">
                                        <Dropdown.Menu>
                                            <Dropdown.Header testid="adherence-sort" active={adherenceSortOrder === ""} onClick={() => this.setState({ adherenceSortOrder: '' })} className="dropdown-head">
                                                <span>Sort</span>
                                                <div
                                                    className={`sort-container ${daysOnSortOrder}`}
                                                >
                                                    <img className="asc" src={caretUpIcon} alt="up arrow" />
                                                    <img className="desc" src={caretDownIcon} alt="down arrow" />
                                                </div>
                                            </Dropdown.Header>
                                            <Dropdown.Item testid="adherence-sort-asc" active={adherenceSortOrder === "asc"} onClick={() => this.setState({ adherenceSortOrder: 'asc' })}>Ascending Value</Dropdown.Item>
                                            <Dropdown.Item testid="adherence-sort-desc" active={adherenceSortOrder === "desc"} onClick={() => this.setState({ adherenceSortOrder: 'desc' })}>Descending Value</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Header className="dropdown-head">
                                                <span>Filter</span>
                                                <img className="extra-small-icon asc" src={filterIcon} alt="up arrow" />
                                            </Dropdown.Header>
                                            <Dropdown.Item
                                                testid="all-adherence"
                                                active={adherenceFilter === ""}
                                                onClick={() => this.setState({ adherenceFilter: "" })}
                                            >
                                                All Actions
                                            </Dropdown.Item>
                                            {habitNames.sort((a, b) => a.habitName.localeCompare(b.habitName)).map(({ habitName, displayName }, i) => (
                                                <Dropdown.Item key={`actions-items-${i}`} testid={`adherence-filter-${i}`} active={adherenceFilter === habitName} onClick={() => this.setState({ adherenceFilter: habitName })}>{displayName}</Dropdown.Item>
                                            ))}

                                        </Dropdown.Menu>
                                        <div className="table-head-icon margin-left">
                                            <Dropdown.Toggle testid="adherence-dropdown">
                                                <img className="extra-small-icon asc" src={filterIcon} alt="up arrow" />
                                            </Dropdown.Toggle>
                                        </div>
                                    </Dropdown>
                                </th>
                                    <th scope="col" className="text-center">
                                        <OverlayTrigger
                                            overlay={this._getPopover({
                                                title: 'Activity',
                                                description: ACTIVITY_TOOL_TIP,
                                            })}
                                            testid="activity-tooltip"
                                        >
                                            <div className="table-head-icon">
                                                <img
                                                    className="extra-small-icon icon-left"
                                                    src={questionIcon}
                                                    alt="tooltip icon"
                                                />
                                            </div>
                                        </OverlayTrigger>
                                        Activity
                                        <div testid="activity-sort" onClick={() => this._toggleSort('activitySortOrder')} className={`sort-container ${activitySortOrder}`}>
                                            <img className="asc" src={caretUpIcon} alt="up arrow" />
                                            <img className="desc" src={caretDownIcon} alt="down arrow" />
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center">
                                        <OverlayTrigger
                                            overlay={this._getPopover({ title: 'Streak', description: STREAK_TOOLTIP })}
                                            testid="streak-tooltip"
                                        >
                                            <div className="table-head-icon">
                                                <img
                                                    className="extra-small-icon icon-left"
                                                    src={questionIcon}
                                                    alt="tooltip icon"
                                                />
                                            </div>
                                        </OverlayTrigger>
                                        Streak
                                        <div testid="streak-sort" onClick={() => this._toggleSort('streakSortOrder')} className={`sort-container ${streakSortOrder}`}>
                                            <img className="asc" src={caretUpIcon} alt="up arrow" />
                                            <img className="desc" src={caretDownIcon} alt="down arrow" />
                                        </div>
                                    </th>
                                </>}
                            </tr>
                        </thead>
                        <tbody>{this._renderUserList(usersList)}</tbody>
                    </table>
                </div>
                {showNotificationModal ? (
                    <Modal
                        testid="notification-modal"
                        onClose={() => this.setState({ showNotificationModal: false })}
                        open={showNotificationModal}
                        style={{ display: 'flex' }}

                    >
                        <>
                            <NotificationForm
                                onDone={() => this.setState({ showNotificationModal: false })}
                                user={selectedUser}
                                userId={selectedUserId}
                                networkKey={networkKey}
                            />
                        </>
                    </Modal>
                ) : null}
                {showBroadcastModal ? (
                    <Modal
                        testid="broadcast-modal"
                        style={{ display: 'flex' }}
                        open={showBroadcastModal}
                        onClose={() => this.setState({ showBroadcastModal: false })}
                    >
                        <>
                            <BroadcastForm currentUsers={currentUsers} networkKey={networkKey} onDone={() => this.setState({ showBroadcastModal: false })} />
                        </>
                    </Modal>
                ) : null}
            </div>
        );
    }



}

const mapStateToProps = ({ auth, content: { currentUsers, metricLogs, habitLogs, chosenProtocolsMapping } }) => ({
    auth,
    metricLogs,
    habitLogs,
    chosenProtocolsMapping,
    currentUsers,
});

const ConnectedScoreCard: ComponentType<{}> = connect(mapStateToProps, { fetchMetricLogsForYear, fetchHabitLogsForYear, fetchUsers })(ScoreCard);

export default ConnectedScoreCard;
