// @flow

import React, { useState } from 'react';

import keyIcon from '../../../assets/svgs/key-blue.svg';
import type { ComponentType } from 'react';
import _ from 'lodash';

type ResetKeyModalProps = {
    onDone: ()=> void;
    resetKey: () => void;
};

export const ResetKeyModal: ComponentType<ResetKeyModalProps> = ({ onDone = _.noop, resetKey = _.noop}) => {
    
    const [success, setSuccess] = useState(false);
    
    const onResetKey:any = () =>{
        resetKey();
        setSuccess(true);
    }
    
    return (
        <div className="modal-container modal-md">
            <div className="modal-head">
                <div className="modal-icon-container">
                    <img src={keyIcon} className="modal-icon" alt="modal icon" />
                </div>
                {success ? (
                    <p testid="modal-title" className="modal-title">
                        Secret Key Changed
                    </p>
                ) : (
                    <p testid="modal-title" className="modal-title">
                        {'Reset Secret Key'}
                    </p>
                )}
            </div>
            {success ? (
                <div className="modal-bd d-flex flex-column justify-content-center">
                    <button testid="done-remove" className="btn btn-block btn-lg app-btn-primary mt-3" onClick={onDone}>
                        Okay
                    </button>
                </div>
            ) : (
                <>
                    <p className="modal-description">{'Your old secret key will no longer work. New members will need to know the new key.'}</p>
                    
                    <div className="modal-bd d-flex flex-column justify-content-center">
                        <button
                            className={`btn btn-block btn-lg app-btn-primary mt-3`}
                            testid="confirm-remove"
                            onClick={onResetKey}
                        >
                            Confirm
                        </button>
                        <button className="btn btn-lg btn-block app-btn-ghost mt-3" onClick={onDone}>
                            Cancel
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
